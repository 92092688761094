import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { PrimengUiModule } from '@ui/primeng/primeng-ui.module';
import { AngularCdkModule } from '@ui/cdk/angular-cdk.module';
import { UiFormsModule } from '@ui/forms/ui-forms.module';
import { UiComponentsModule } from '@ui/components/ui-components.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, CoreModule, PrimengUiModule],
  exports: [
    PrimengUiModule,
    AngularCdkModule,
    UiFormsModule,
    UiComponentsModule,
  ],
  providers: [],
})
export class UiModule {}
