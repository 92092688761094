import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class ForwarderGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let forwardTo: string | null =
      (route.data && route.data['forwardTo']) || null;
    if (!forwardTo) {
      return true;
    }
    if (forwardTo.match(/:\w+/)) {
      forwardTo = forwardTo.replace(/(:\w+)/g, (_, group) => {
        const arr = Object.entries(route.paramMap);
        return (arr && arr[group] && arr[group][1]) || '';
      });
    }
    this.router.navigate([forwardTo]);
    return false;
  }
}
