import { Component, OnInit } from '@angular/core';
import { TableRowData } from '@ui/components/data-table/interfaces';
import { BaseCellRendererComponent } from '@ui/components/data-table/components/renderers/base-cell-renderer.component';

@Component({
  selector: 'app-file-size-cell-renderer',
  templateUrl: './file-size-cell-renderer.component.html',
  styleUrls: ['./file-size-cell-renderer.component.scss'],
})
export class FileSizeCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<number, R>
  implements OnInit
{
  fontStyle: { weight: string | 'normal' | 'bold'; color: string } | undefined =
    { weight: 'normal', color: 'default' };

  ngOnInit(): void {
    if (this.getOptions()['font']) {
      this.fontStyle = this.getOptions()['font'] as any;
    }
  }

  override render(): string {
    return '';
  }
  getFontStyle(): any {
    return {
      fontWeight: this.fontStyle?.weight || 'normal',
    };
  }

  getFontColor(): string {
    return 'color-' + (this.fontStyle?.color || 'default');
  }
}
