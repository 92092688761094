import { environment } from '@env/environment';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggerModule } from 'ngx-logger';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SafeHtmlPipe } from '@core/pipes/safe-html.pipe';
import { ProgressPipe } from '@core/pipes/progress.pipe';
import { FileSizePipe } from '@core/pipes/file-size.pipe';
import { FileNamePipe } from '@core/pipes/file-name.pipe';
import { HumanizePipe } from '@core/pipes/humanize.pipe';
import { FormatCellPipe } from '@core/pipes/format-cell.pipe';
import { FileExtensionPipe } from '@core/pipes/file-extension.pipe';
import { MimeTypeToExtensionPipe } from '@core/pipes/mime-type-to-extension.pipe';

import { ScrollToTopDirective } from './directives/scroll-to-top.directive';

import { AuthGuard } from '@core/guards/auth.guard';
import { HttpClientModule } from '@angular/common/http';
import { DateFormatPipe } from '@core/pipes/date-format.pipe';
import { SafeImagePipe } from '@core/pipes/safe-image.pipe';
import { FilterByFieldPipe } from '@core/pipes/filter-by-field.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { LowerCasePipe } from './pipes/lower-case.pipe';
import { DefaultPipe } from './pipes/default.pipe';
import { CapitalLettersPipe } from './pipes/capital-letters.pipe';
import { TemplateNameDirective } from './directives/template-name.directive';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    ProgressPipe,
    FileSizePipe,
    FileNamePipe,
    HumanizePipe,
    FormatCellPipe,
    FileExtensionPipe,
    MimeTypeToExtensionPipe,
    ScrollToTopDirective,
    DateFormatPipe,
    SafeImagePipe,
    FilterByFieldPipe,
    PluralizePipe,
    LowerCasePipe,
    DefaultPipe,
    CapitalLettersPipe,
    TemplateNameDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    LoggerModule.forRoot({
      level: environment.LOG_LEVEL,
    }),
    NgxPermissionsModule.forChild(),
  ],
  exports: [
    FormsModule,
    LoggerModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    SafeHtmlPipe,
    ProgressPipe,
    FileSizePipe,
    FileNamePipe,
    HumanizePipe,
    FormatCellPipe,
    FileExtensionPipe,
    MimeTypeToExtensionPipe,
    ScrollToTopDirective,
    DateFormatPipe,
    SafeImagePipe,
    FilterByFieldPipe,
    PluralizePipe,
    DefaultPipe,
    LowerCasePipe,
    CapitalLettersPipe,
  ],
  providers: [
    SafeHtmlPipe,
    ProgressPipe,
    FileSizePipe,
    FileNamePipe,
    HumanizePipe,
    FormatCellPipe,
    FileExtensionPipe,
    MimeTypeToExtensionPipe,
    AuthGuard,
    SafeImagePipe,
    FilterByFieldPipe,
    PluralizePipe,
  ],
})
export class CoreModule {}
