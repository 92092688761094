import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'progress',
})
export class ProgressPipe implements PipeTransform {
  transform(value: number, total: number): unknown {
    if (!total) {
      return 0;
    }
    return Math.round((value * 100) / total);
  }
}
