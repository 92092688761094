import { Nullable } from '@core/interfaces/nullable';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PaginatedResponse, SharedDocument } from '@core/models';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import * as sha256 from 'sha256';
import { DocumentsService } from '@core/services/api/documents.service';
import {
  CanBeShared,
  ClearSharedDocumentsErrors,
  DocumentAccessRevoked,
  DocumentShared,
  GetSharedByClients,
  GetSharedByCompany,
  GetSharedByDocument,
  RevokeDocumentAccess,
  ShareDocument,
} from '@store/shared-documents/shared-documents.actions';
import { DocumentsStateModel } from '@store/documents/documents.state';

export interface SharedDocumentsStateModel {
  sharedByCompany: Record<string, Nullable<PaginatedResponse<SharedDocument>>>;
  sharedByClients: Record<string, Nullable<PaginatedResponse<SharedDocument>>>;
  revoked: Nullable<SharedDocument>;
  shared: Nullable<boolean>;
  canBeShared: Map<string, Record<string, boolean>>;
  error: any;
}

@State<SharedDocumentsStateModel>({
  name: 'shared_documents',
  defaults: {
    sharedByCompany: {},
    sharedByClients: {},
    shared: null,
    revoked: null,
    canBeShared: new Map(),
    error: null,
  },
})
@Injectable()
export class SharedDocumentsState {
  constructor(private documentsService: DocumentsService) {}

  @Selector()
  static getState(state: SharedDocumentsStateModel): SharedDocumentsStateModel {
    return state;
  }

  @Selector()
  static selectSharedByCompany(where: Nullable<Record<string, string>>) {
    return createSelector([SharedDocumentsState], (state) => {
      const key = where ? sha256(`${JSON.stringify(where)}`) : 'default';
      return state.shared_documents.sharedByCompany[key];
    });
  }

  @Selector()
  static selectSharedByClients(where: Nullable<Record<string, string>>) {
    return createSelector([SharedDocumentsState], (state) => {
      const key = where ? sha256(`${JSON.stringify(where)}`) : 'default';
      return state.shared_documents.sharedByClients[key];
    });
  }

  @Selector()
  static canBeShared(documentId: string, email: string) {
    return createSelector([SharedDocumentsState], (state) => {
      return state.shared_documents.canBeShared.has(documentId)
        ? state.shared_documents.canBeShared.get(documentId)[email]
        : null;
    });
  }

  @Selector()
  static getShared(state: SharedDocumentsStateModel): Nullable<boolean> {
    return state.shared;
  }

  @Selector()
  static getRevoked(
    state: SharedDocumentsStateModel,
  ): Nullable<SharedDocument> {
    return state.revoked;
  }

  @Selector()
  static getError(state: SharedDocumentsStateModel): Nullable<any> {
    return state.error;
  }

  @Action(ShareDocument)
  share(
    ctx: StateContext<SharedDocumentsStateModel>,
    action: ShareDocument,
  ): Observable<void> {
    ctx.patchState({
      shared: false,
    });
    return this.documentsService
      .shareDocument(action.companyId, action.payload)
      .pipe(
        take(1),
        switchMap((resp) => ctx.dispatch(new DocumentShared(action.companyId))),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(DocumentShared)
  shared(
    ctx: StateContext<SharedDocumentsStateModel>,
    action: DocumentShared,
  ): void {
    const canBeShared = ctx.getState().canBeShared;
    ctx.patchState({
      shared: true,
      canBeShared,
    });
  }

  @Action(GetSharedByCompany)
  getSharedByCompany(
    ctx: StateContext<SharedDocumentsStateModel>,
    action: GetSharedByCompany,
  ): Observable<any> {
    const key = action.payload.where
      ? sha256(`${JSON.stringify(action.payload.where)}`)
      : 'default';
    return this.documentsService
      .getSharedByCompany(
        action.payload.companyId,
        action.payload.page || 1,
        action.payload.pageSize || 30,
        action.payload.sortBy || 'sharedAt',
        action.payload.order || 'desc',
        action.payload.where || {},
      )
      .pipe(
        tap((data) => {
          ctx.patchState({
            sharedByCompany: {
              ...ctx.getState().sharedByCompany,
              [key]: { ...data },
            },
          });
        }),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(GetSharedByClients)
  getSharedByClients(
    ctx: StateContext<SharedDocumentsStateModel>,
    action: GetSharedByCompany,
  ): Observable<any> {
    const key = action.payload.where
      ? sha256(`${JSON.stringify(action.payload.where)}`)
      : 'default';
    return this.documentsService
      .getSharedByClients(
        action.payload.companyId,
        action.payload.page || 1,
        action.payload.pageSize || 30,
        action.payload.sortBy || 'sharedAt',
        action.payload.order || 'desc',
        action.payload.where || {},
      )
      .pipe(
        tap((data) => {
          ctx.patchState({
            sharedByClients: {
              ...ctx.getState().sharedByClients,
              [key]: { ...data },
            },
          });
        }),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(GetSharedByDocument)
  getSharedByDocument(
    ctx: StateContext<SharedDocumentsStateModel>,
    action: GetSharedByDocument,
  ): Observable<any> {
    const where = {
      ...(action.payload.where || {}),
      'doc.id': action.payload.documentId,
    };
    const key = sha256(`${JSON.stringify(where)}`);
    return this.documentsService
      .getSharedByDocument(
        action.payload.companyId,
        action.payload.documentId,
        action.payload.page || 1,
        action.payload.pageSize || 30,
        action.payload.sortBy || 'sharedAt',
        action.payload.order || 'desc',
        action.payload.where || {},
      )
      .pipe(
        tap((data) => {
          ctx.patchState({
            sharedByCompany: {
              ...ctx.getState().sharedByCompany,
              [key]: { ...data },
            },
          });
        }),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(RevokeDocumentAccess)
  revoke(
    ctx: StateContext<SharedDocumentsStateModel>,
    action: RevokeDocumentAccess,
  ): Observable<any> {
    return this.documentsService
      .revokeCompanyShare(action.id, action.companyId)
      .pipe(
        switchMap((revoked) => {
          return ctx.dispatch(
            new DocumentAccessRevoked(action.companyId, revoked),
          );
        }),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(CanBeShared)
  canBeShared(
    ctx: StateContext<SharedDocumentsStateModel>,
    action: CanBeShared,
  ): void {
    this.documentsService
      .canBeShared(action.documentId, action.email, action.companyId)
      .pipe(
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      )
      .subscribe((state) => {
        const canBeShared = ctx.getState().canBeShared;
        canBeShared.set(action.documentId, {
          ...(canBeShared.get(action.documentId) || {}),
          [action.email]: state,
        });
        ctx.patchState({
          canBeShared: new Map(canBeShared.entries()),
        });
      });
  }

  @Action(DocumentAccessRevoked)
  accessRevoked(
    ctx: StateContext<SharedDocumentsStateModel>,
    action: DocumentAccessRevoked,
  ): void {
    ctx.patchState({
      revoked: action.payload,
    });
  }

  @Action(ClearSharedDocumentsErrors)
  clearErrors(ctx: StateContext<SharedDocumentsStateModel>): void {
    ctx.patchState({
      error: null,
      shared: null,
      revoked: null,
    });
  }
}
