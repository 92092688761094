import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'default',
})
export class DefaultPipe implements PipeTransform {
  transform(value: any, accepted: any[], defaultValue = null): unknown {
    if (!value && defaultValue) {
      return defaultValue;
    }
    if (!accepted || !accepted.includes(value)) {
      return defaultValue;
    }
    return value;
  }
}
