import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TableRowData } from '../../../interfaces';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@Component({
  selector: 'app-boolean-cell-renderer',
  templateUrl: '../base-cell-renderer.component.html',
  styleUrls: ['./boolean-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BooleanCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<boolean, R>
  implements OnInit
{
  private condition!: (value: any) => boolean;

  ngOnInit(): void {
    if (this.getOptions()['condition']) {
      this.condition = this.getOptions()['condition'] as (
        value: any,
      ) => boolean;
    }
  }

  override render(): string {
    let cond = !!this.getRawValue();
    if (typeof this.condition === 'function') {
      cond = this.condition.call(this, this.getRawValue());
    }
    return cond ? 'Yes' : 'No';
  }
}
