import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { Category } from '@core/models/category.model';
import { TreeNode } from 'primeng/api';
import { TreePathPipe } from '@common/shared/pipeps/tree-path.pipe';
import { takeUntil } from 'rxjs/operators';
import { findInTree } from '@common/shared/utils/find-in-tree';
import { Nullable } from '@core/interfaces/nullable';

@Component({
  selector: 'app-categories-dropdown',
  templateUrl: './categories-dropdown.component.html',
  styleUrls: ['./categories-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CategoriesDropdownComponent),
    },
  ],
})
export class CategoriesDropdownComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @HostBinding('style.width')
  @Input()
  width = 'auto';

  @Input() placeholder = 'Category';

  @Input() set multi(value: boolean | undefined) {
    if (value) {
      this.selected = new SelectionModel(true);
      this.selectedValue = [];
    } else {
      this.selected = new SelectionModel(false);
      this.selectedValue = '';
    }
  }

  @Input() set categories(categories: Category[]) {
    this._categories = categories;
    if (categories) {
      this.categoriesReady$.next(categories);
    }
  }

  get categories(): Category[] {
    return this._categories;
  }

  @Input() set value(value: string | string[]) {
    this._value = value;
    this.applied.clear();
    if (value !== undefined && value !== null && value !== '') {
      this.valueReady$.next(value);
    } else {
      this.selected.clear();
      this.selectedValue = this.selected.isMultipleSelection() ? [] : '';
    }
  }

  get value(): string | string[] {
    return this._value;
  }

  isOpened!: boolean;
  isDisabled!: boolean;
  selected = new SelectionModel<Category>(false);
  applied = new SelectionModel<string>(true);
  selectedValue: any;

  private onChange: any;
  private onTouch: any;
  private _value!: string | string[];
  private _categories!: Category[];
  private categoriesReady$ = new ReplaySubject<Category[]>(1);
  private valueReady$ = new ReplaySubject<string | string[]>(1);
  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, private treePath: TreePathPipe) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    combineLatest([this.valueReady$, this.categoriesReady$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([value, categories]) => {
        const values: string[] = value instanceof Array ? value : [value];
        this.applied.select(...values.filter((v) => !!v));
        this.selected.clear();
        this.selected.select(
          ...values
            .map((v) => <Category>findInTree(v, categories))
            .filter((v) => !!v),
        );
        if (!this.selected.isEmpty()) {
          this.selectedValue = this.selected.isMultipleSelection()
            ? this.selected.selected.map((s) => s.id)
            : this.selected.selected[0].id;
          this.onTouch();
          this.onChange(this.selectedValue);
        }
      });
  }

  get selectedCount(): number {
    return this.selected.selected.length;
  }

  get selectedText(): string {
    if (this.applied.isEmpty()) {
      return this.placeholder;
    }
    if (this.selected.isMultipleSelection()) {
      return 'Categories (' + this.applied.selected.length + ')';
    } else {
      return this.treePath.transform(this.selected.selected[0]);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: string | string[]): void {
    if (this.value !== value) {
      this.value = value;
      this.cdr.markForCheck();
    }
  }

  onTreeItemSelected(event: TreeNode): void {
    this.selected.toggle(event.data);
    const value = this.selected.selected.map((s) => s.id) as string[];
    if (this.selected.isMultipleSelection()) {
      this.selectedValue = [...value];
    } else {
      this.selectedValue = value.length > 0 ? value[0] : undefined;
    }
    if (!this.selected.isMultipleSelection()) {
      this.writeValue(this.selectedValue);
      this.onChange(this.selectedValue);
      this.onTouch();
    }

    this.cdr.markForCheck();
  }

  onApply(event: MouseEvent): void {
    const value = this.selected.selected.map((s) => s.id) as string[];
    if (this.selected.isMultipleSelection()) {
      this.selectedValue = [...value];
    } else {
      this.selectedValue = value.length > 0 ? value[0] : undefined;
    }
    this.writeValue(this.selectedValue);
    this.onChange(this.selectedValue);
    this.onTouch();
  }

  onCancel(): void {
    this.selected.clear();
    this.selected.select(
      ...this.applied.selected
        .map((id) => <Category>findInTree(id, this.categories))
        .filter((v) => !!v),
    );
    this.selectedValue = this.selected.selected.map((s) => s.id);
    this.cdr.markForCheck();
  }
}
