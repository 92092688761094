import { Nullable } from '@core/interfaces/nullable';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Contact } from '@core/models/contact.model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
  AddContact,
  ClearContactsErrors,
  ClearExternalContactsSearch,
  ContactCreated,
  ContactUpdated,
  DeleteContact,
  GetContacts,
  SearchFyioExternalContact,
  GetContactsByGroup,
  UpdateContact,
} from '@store/contacts/contacts.actions';
import { ContactsService } from '@core/services/api/contacts.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Company } from '@core/models/company.model';

export interface ContactsStateModel {
  list: Nullable<Contact[]>;
  groups: Record<string, Contact[]>;
  created: Nullable<Contact>;
  updated: Nullable<Contact>;
  fyioExternalContact: Nullable<Contact>;
  error: any;
}

@State<ContactsStateModel>({
  name: 'contacts',
  defaults: {
    list: null,
    groups: {},
    created: null,
    updated: null,
    fyioExternalContact: null,
    error: null,
  },
})
@Injectable()
export class ContactsState {
  constructor(private contactsService: ContactsService) {}

  @Selector()
  static getState(state: ContactsStateModel): ContactsStateModel {
    return state;
  }

  @Selector()
  static getCreated(state: ContactsStateModel): Nullable<Contact> {
    return state.created;
  }

  @Selector()
  static getUpdated(state: ContactsStateModel): Nullable<Contact> {
    return state.updated;
  }

  @Selector()
  static getError(state: ContactsStateModel): Nullable<any> {
    return state.error;
  }

  @Selector()
  static getContacts(state: ContactsStateModel): Nullable<Contact[]> {
    return state.list;
  }

  @Selector()
  static getFyioExternalContact(state: ContactsStateModel): Nullable<Contact> {
    return state.fyioExternalContact;
  }

  @Selector()
  static getContactsByGroup(id: string): any {
    return createSelector(
      [ContactsState],
      (state: any): Nullable<Contact[]> => {
        return state.contacts?.groups[id] || null;
      },
    );
  }

  @Action(GetContacts)
  getContacts(
    ctx: StateContext<ContactsStateModel>,
    action: GetContacts,
  ): Observable<any> {
    return this.contactsService.getContacts(action.companyId).pipe(
      tap((data) => {
        ctx.patchState({
          list: [...data],
        });
      }),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(GetContactsByGroup)
  getContactsByGroup(
    ctx: StateContext<ContactsStateModel>,
    action: GetContactsByGroup,
  ): Observable<any> {
    return this.contactsService
      .getContactsByGroup(action.companyId, action.groupId)
      .pipe(
        tap((data) => {
          ctx.patchState({
            groups: {
              ...ctx.getState().groups,
              [action.groupId]: data,
            },
          });
        }),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(AddContact)
  create(
    ctx: StateContext<ContactsStateModel>,
    action: AddContact,
  ): Observable<void> {
    ctx.patchState({
      created: null,
    });
    return this.contactsService
      .createContact(action.companyId, action.payload)
      .pipe(
        switchMap((resp) =>
          ctx.dispatch(new ContactCreated(action.companyId, resp)),
        ),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(UpdateContact)
  update(
    ctx: StateContext<ContactsStateModel>,
    action: UpdateContact,
  ): Observable<void> {
    ctx.patchState({
      updated: null,
    });
    return this.contactsService
      .updateContact(action.id, action.companyId, action.payload)
      .pipe(
        switchMap((resp) =>
          ctx.dispatch(new ContactUpdated(action.companyId, resp)),
        ),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(DeleteContact)
  deleteContact(
    ctx: StateContext<ContactsStateModel>,
    action: DeleteContact,
  ): Observable<void> {
    return this.contactsService.deleteContact(action.id, action.companyId).pipe(
      switchMap((resp) => ctx.dispatch(new GetContacts(action.companyId))),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(SearchFyioExternalContact)
  searchFyioExternalContact(
    ctx: StateContext<ContactsStateModel>,
    action: SearchFyioExternalContact,
  ): Observable<any> {
    ctx.patchState({
      fyioExternalContact: null,
    });
    return this.contactsService
      .searchFyioExternalContact(action.companyId, action.email)
      .pipe(
        tap((data) => {
          ctx.patchState({
            fyioExternalContact: data,
          });
        }),
        catchError((e: HttpErrorResponse) => {
          ctx.patchState({
            fyioExternalContact: null,
          });
          if (e.status > 400) {
            return throwError(e);
          }
          return of();
        }),
      );
  }

  @Action(ContactCreated)
  created(
    ctx: StateContext<ContactsStateModel>,
    action: ContactCreated,
  ): Observable<void> {
    ctx.patchState({
      created: action.payload,
    });
    return ctx.dispatch(new GetContacts(action.companyId));
  }

  @Action(ContactUpdated)
  updated(
    ctx: StateContext<ContactsStateModel>,
    action: ContactUpdated,
  ): Observable<void> {
    ctx.patchState({
      updated: action.payload,
    });
    return ctx.dispatch(new GetContacts(action.companyId));
  }

  @Action(ClearContactsErrors)
  clearErrors(ctx: StateContext<ContactsStateModel>): void {
    ctx.patchState({
      error: null,
    });
  }
  @Action(ClearExternalContactsSearch)
  clearExternalSearch(ctx: StateContext<ContactsStateModel>): void {
    ctx.patchState({
      fyioExternalContact: null,
    });
  }
}
