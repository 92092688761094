import { TemplateRef } from '@angular/core';
import { DataTableConfig } from '../interfaces';
import { TableImplementation } from '../enums/table-implementation';

export const DefaultDataTableConfig: DataTableConfig = {
  implementation: TableImplementation.PrimeNg,
  alignHeaders: 'left',
  enableColumnsResizing: true,
  enableColumnsSorting: false,
  enableFiltering: false,
  enableSorting: true,
  pageSizes: [30, 50, 100],
  responsive: false,
};

export const DefaultExpandCellOptions = (
  template: TemplateRef<any> | undefined,
) => ({
  id: 'expand',
  label: '',
  options: {
    align: 'center',
    width: 50,
    sortable: false,
    resizable: false,
  },
  cellOptions: {
    renderType: 'template',
    template,
  },
});
