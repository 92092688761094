import {
  AfterViewInit,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { checkImage } from '@common/shared/utils/check-image';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import {
  ReplaySubject,
  Subject,
  combineLatest,
  BehaviorSubject,
  NEVER,
  of,
} from 'rxjs';
import { Avatar } from 'primeng/avatar';

@Directive({
  selector: '[appDefaultImage]',
})
export class DefaultImageDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() set defaultImage(image: string) {
    this._defaultImage = image;
  }

  get defaultImage() {
    return this._defaultImage;
  }

  @Input() size: string | 'large' | 'small' = 'large';
  @Input() shape: string | 'square' | 'circle' = 'circle';

  @Input() set src(src: string) {
    this._src = src;
    this.changed$.next(src);
  }

  get src(): string {
    return this._src;
  }

  private cmp!: ComponentRef<Avatar>;
  private _defaultImage =
    "data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='600' height='600' fill='white'%3E%3Ctitle%3EAbstract user icon%3C/title%3E%3Cdefs%3E%3CclipPath id='circular-border'%3E%3Ccircle cx='300' cy='300' r='280' /%3E%3C/clipPath%3E%3CclipPath id='avoid-antialiasing-bugs'%3E%3Crect width='100%25' height='498' /%3E%3C/clipPath%3E%3C/defs%3E%3Ccircle cx='300' cy='300' r='280' fill='black' clip-path='url(%23avoid-antialiasing-bugs)' /%3E%3Ccircle cx='300' cy='230' r='115' /%3E%3Ccircle cx='300' cy='550' r='205' clip-path='url(%23circular-border)' /%3E%3C/svg%3E";
  private _src: string = this._defaultImage;
  private destroy$ = new Subject<void>();
  private changed$ = new ReplaySubject<string | null>(1);
  private viewInit$ = new ReplaySubject<void>(1);
  private dimensions: { width: number; height: number } = {
    width: 300,
    height: 300,
  };

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    combineLatest([this.viewInit$, this.changed$])
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => {
          if (!this.cmp || this.src !== this.cmp.instance.image) {
            return checkImage(
              this._src,
              this.renderer,
              this.el.nativeElement,
              this.dimensions,
            );
          } else {
            return of(!!this.src);
          }
        }),
      )
      .subscribe((res) => {
        if (!this.cmp || this.src !== this.cmp.instance.image) {
          this.viewContainerRef.clear();
          this.renderer.removeChild(
            (this.el.nativeElement as HTMLElement).parentElement,
            this.el.nativeElement,
          );
          const cmp: ComponentRef<Avatar> =
            this.viewContainerRef.createComponent(Avatar);
          cmp.instance.image = res ? this.src : this.defaultImage;
          cmp.instance.styleClass =
            (this.el.nativeElement as HTMLElement).className +
            ' ' +
            (this.dimensions.width > this.dimensions.height
              ? 'landscape'
              : 'portrait');

          cmp.instance.shape = this.shape;
          cmp.instance.size = this.size;
          cmp.changeDetectorRef.detectChanges();
          this.cmp = cmp;
          this.el.nativeElement.style.display = 'none';
        }
      });
  }

  ngAfterViewInit() {
    (this.el.nativeElement as HTMLElement).style.display = 'none';
    this.viewInit$.next();
  }
}
