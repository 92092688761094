import { CreateCompanyRequest } from '@core/models/create-company-request.model';
import { Company } from '@core/models/company.model';
import { UpdateCompanyRequest } from '@core/models/update-company-request.model';
import { Nullable } from '@core/interfaces/nullable';

export class CreateCompany {
  static readonly type = '[COMPANIES] Create Company';

  constructor(
    public payload: {
      company: CreateCompanyRequest;
      logoFile: File;
    },
  ) {}
}
export class UpdateCompany {
  static readonly type = '[COMPANIES] Update Company';

  constructor(
    public payload: {
      id: string;
      company: UpdateCompanyRequest;
      logoFile?: Nullable<File>;
    },
  ) {}
}

export class DeleteCompany {
  static readonly type = '[COMPANIES] Delete Company';

  constructor(
    public payload: {
      id: string;
    },
  ) {}
}

export class CompanyCreated {
  static readonly type = '[COMPANIES] Company Created';

  constructor(public payload: Company) {}
}
export class CompanyUpdated {
  static readonly type = '[COMPANIES] Company Updated';

  constructor(public payload: Company) {}
}
export class CompanyDeleted {
  static readonly type = '[COMPANIES] Company Deleted';

  constructor(public id: string) {}
}
export class ClearCompanyErrors {
  static readonly type = '[COMPANIES] Company Clear Errors';
}

export class GetCompanies {
  static readonly type = '[COMPANIES] Get Companies';
}
export class ChangeActiveCompany {
  static readonly type = '[COMPANIES] Change Active Company';
  constructor(public payload: Company) {}
}

export class GetCategories {
  static readonly type = '[COMPANIES] Get Categories';
  constructor(public companyId: string) {}
}
export class GetClientCategories {
  static readonly type = '[COMPANIES] Get Contact Categories';
  constructor(public companyId: string) {}
}
