import { NgModule } from '@angular/core';
import { UiModule } from '@ui/ui.module';
import { AuthCommonModule } from '@common/auth/auth-common.module';
import { SharedCommonModule } from '@common/shared/shared-common.module';
import { WsNotificationsModule } from '@common/ws-notifications/ws-notifications.module';

@NgModule({
  exports: [UiModule, AuthCommonModule, SharedCommonModule],
})
export class AppCommonModule {}
