<div class="list">
  <app-search-field
    [placeholder]="'Search...'"
    (search)="onSearch('client', $event)"
  ></app-search-field>
  <cdk-virtual-scroll-viewport itemSize="6" class="scroll-wrapper">
    <div
      *cdkVirtualFor="let item of filteredClients; trackBy: trackById"
      class="list-item"
      (click)="onClientSelected([item.id])"
    >
      <app-contact [contact]="item"></app-contact>
      <button
        pButton
        class="p-button-rounded p-button-icon p-button-text p-button-sm"
        icon="pi pi-times"
        (click)="onDeselect(item.id)"
      ></button>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
