import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Output,
} from '@angular/core';
import { ContactsDropdownComponent } from '@ui/forms/elements/contacts-dropdown/contacts-dropdown.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-contacts-listbox',
  templateUrl: './contacts-listbox.component.html',
  styleUrls: ['./contacts-listbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ContactsListboxComponent),
    },
  ],
})
export class ContactsListboxComponent extends ContactsDropdownComponent {
  @Output() deselect = new EventEmitter<string>();
  onDeselect(id: string): void {
    this.deselect.emit(id);
  }
}
