import { NgModule } from '@angular/core';
import { AuthenticationService } from '@common/auth/services/authentication.service';
import { SharedStoreModule } from '@store/shared-store.module';
import {
  AUTH_SERVICE,
  AuthModule,
  PROTECTED_FALLBACK_PAGE_URI,
  PUBLIC_FALLBACK_PAGE_URI,
} from 'ngx-auth';
import { CoreModule } from '@core/core.module';
import { environment } from '@env/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthErrorsInterceptor } from '@common/auth/interceptors/auth-errors.interceptor';

@NgModule({
  imports: [
    CoreModule,
    SharedStoreModule,
    AuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
  ],
  exports: [AuthModule],
  providers: [
    AuthenticationService,
    { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/login' },
    { provide: AUTH_SERVICE, useClass: AuthenticationService },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AuthErrorsInterceptor,
    },
  ],
})
export class AuthCommonModule {}
