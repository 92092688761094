<div *ngIf="user && profile" class="user-info" (click)="onClick()">
  <img
    *ngIf="profile"
    class="user-avatar"
    [src]="profile.avatar"
    appDefaultImage
  />
  <div class="name-holder">
    <div
      class="user-name text-base"
      [pTooltip]="profile.firstName + ' ' + profile.lastName"
    >
      {{ profile.firstName }} {{ profile.lastName }}
    </div>
    <div class="user-role text-xs">
      <span>{{ user.roles[0] }}</span>
    </div>
  </div>
  <div class="icon"><i class="pi pi-chevron-right"></i></div>
</div>
