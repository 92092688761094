<form *ngIf="form" [formGroup]="form" class="create-category-form-content">
  <div class="p-2 w-full">
    <app-form-fields-wrapper [showLabels]="true">
      <div *appFormField="'categoryPath'">
        <div *ngIf="categoryPath" class="category-path flex align-items-center">
          <i class="pi pi-folder"></i>
          <span
            class="ml-2"
            [pTooltip]="categoryPath"
            [tooltipPosition]="'bottom'"
            >{{ categoryPath }}</span
          >
        </div>
      </div>
      <input
        *appFormField="'name'; label: 'Category name'"
        [readOnly]="disabledFields?.includes('name')"
        [class.readonly]="disabledFields?.includes('name')"
        [autofocus]="true"
        formControlName="name"
        type="text"
        placeholder="Enter name"
        pInputText
        class="w-full mb-1"
      />
      <textarea
        *appFormField="'description'; label: 'Description'"
        formControlName="description"
        type="text"
        placeholder="Enter description"
        pInputTextarea
        class="w-full mb-1"
      ></textarea>
      <input
        *appFormField="'id'"
        formControlName="id"
        type="hidden"
        pInputText
        class="w-full mb-1"
      />
      <input
        *appFormField="'logoUrl'"
        formControlName="logoUrl"
        type="hidden"
      />
      <input
        *appFormField="'parentId'"
        formControlName="parentId"
        type="hidden"
      />
    </app-form-fields-wrapper>
  </div>
</form>
