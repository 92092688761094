import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { NgxsModule } from '@ngxs/store';
import { CompaniesState } from '@store/companies/companies.state';
import { CompaniesStoreService } from '@store/companies/companies-store.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, CoreModule, NgxsModule.forFeature([CompaniesState])],
  providers: [CompaniesState, CompaniesStoreService],
})
export class CompaniesStoreModule {}
