import {
  Input,
  OnInit,
  Output,
  Component,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordFormComponent implements OnInit {
  @Input() title = 'Forgot Password?';
  @Input() buttonText = 'Send';
  @Input() processing: boolean | undefined;
  @Output() restorePassword: EventEmitter<any> = new EventEmitter();

  form!: FormGroup;

  constructor(private fb: FormBuilder, private router: Router) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email]),
    });
  }

  onSubmit(): void {
    if (!this?.form?.valid) {
      return;
    }

    this.restorePassword.emit(this.form.value);
  }

  navigateToLogin(): void {
    this.router.navigate(['/auth/login']);
  }
}
