import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable, share } from 'rxjs';
import { environment } from '@env/environment';
import { DataNode, TreeNode } from '@common/shared/types/data-node';
import { FilesUploadService } from '@core/services/api/files-upload.service';
import { UploadedFile } from '@core/models/uploaded-file.model';
import { filter, map } from 'rxjs/operators';
import { CreateDocumentRequest } from '@core/models/create-document-request.model';
import {
  CreateDocument,
  CreateDocumentShare,
  Document,
  DocumentShareRequest,
  DocumentShareResponse,
  PaginatedResponse,
  SharedDocument,
  UploadProgress,
} from '@core/models';
import { doc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(
    private filesUploadService: FilesUploadService,
    private http: HttpClient,
  ) {}

  loadDocumentFile(url: string): Observable<Blob> {
    return this.http
      .get(url, {
        responseType: 'blob',
      })
      .pipe(share());
  }

  getByContact(
    companyId: string,
    contactId: string,
    page: number,
    pageSize: number,
    sortBy: string,
    order: string,
    where: Record<string, any>,
  ): Observable<PaginatedResponse<SharedDocument>> {
    const query: string = where
      ? `${Object.entries(where)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`
      : '';
    return this.http.get<PaginatedResponse<SharedDocument>>(
      `${environment.API_DOMAIN}/company/${companyId}/documents/clients/${contactId}?page=${page}&size=${pageSize}&sortBy=${sortBy}&order=${order}&${query}`,
    );
  }

  getStatsByContact(companyId: string): Observable<Record<string, number>> {
    return this.http.get<Record<string, number>>(
      `${environment.API_DOMAIN}/company/${companyId}/documents/client-stats`,
    );
  }

  upload(file: File, companyId: string): Observable<UploadedFile> {
    return this.filesUploadService
      .uploadCompanyDocumentFile(file, companyId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((event: { type: HttpEventType; response: any }) => {
          return event?.response && event.response[0];
        }),
      );
  }

  uploadWithProgress(
    file: File,
    companyId: string,
  ): Observable<UploadedFile | UploadProgress> {
    return this.filesUploadService
      .uploadCompanyDocumentFile(file, companyId)
      .pipe(
        map(
          (event: {
            type: HttpEventType;
            response: any;
            progress?: {
              loaded: number;
              total: number;
            };
          }) => {
            if (event?.progress) {
              return {
                ...event.progress,
              };
            } else {
              return event?.response && event.response[0];
            }
          },
        ),
      );
  }

  getDocuments(
    companyId: string,
    page = 1,
    pageSize = 50,
    sortBy = '',
    order = 'asc',
    where?: Record<string, string>,
  ): Observable<PaginatedResponse<Document>> {
    const query: string = where
      ? `${Object.entries(where)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`
      : '';
    return this.http.get<PaginatedResponse<Document>>(
      `${environment.API_DOMAIN}/company/${companyId}/documents?page=${page}&size=${pageSize}&sortBy=${sortBy}&order=${order}&${query}`,
    );
  }

  getDocumentsCount(
    companyId: string,
    where?: Record<string, string>,
  ): Observable<Record<string, number>> {
    const query: string = where
      ? `${Object.entries(where)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`
      : '';
    return this.http.get<Record<string, number>>(
      `${environment.API_DOMAIN}/company/${companyId}/documents/categories?${query}`,
    );
  }

  createDocument(
    companyId: string,
    payload: CreateDocument,
  ): Observable<Document> {
    return this.http.post<Document>(
      `${environment.API_DOMAIN}/company/${companyId}/documents`,
      {
        ...payload,
      },
    );
  }

  shareDocument(
    companyId: string,
    payload: CreateDocumentShare,
  ): Observable<SharedDocument[]> {
    return this.http.post<SharedDocument[]>(
      `${environment.API_DOMAIN}/company/${companyId}/shared-documents/${payload.document}`,
      {
        ...payload,
      },
    );
  }

  checkSharingProgressStatus(
    companyId: string,
    tasks: string[],
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.API_DOMAIN}/company/${companyId}/shared-documents/check-sharing-progress`,
      {
        tasks,
      },
    );
  }

  getSharedByCompany(
    companyId: string,
    page = 1,
    pageSize = 50,
    sortBy = '',
    order = 'asc',
    where?: Record<string, string>,
  ): Observable<PaginatedResponse<SharedDocument>> {
    const query: string = where
      ? `${Object.entries(where)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`
      : '';
    return this.http.get<PaginatedResponse<SharedDocument>>(
      `${environment.API_DOMAIN}/company/${companyId}/shared-documents/company?page=${page}&size=${pageSize}&sortBy=${sortBy}&order=${order}&${query}`,
    );
  }

  getSharedByClients(
    companyId: string,
    page = 1,
    pageSize = 50,
    sortBy = '',
    order = 'asc',
    where?: Record<string, string>,
  ): Observable<PaginatedResponse<SharedDocument>> {
    const query: string = where
      ? `${Object.entries(where)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`
      : '';
    return this.http.get<PaginatedResponse<SharedDocument>>(
      `${environment.API_DOMAIN}/company/${companyId}/shared-documents/clients?page=${page}&size=${pageSize}&sortBy=${sortBy}&order=${order}&${query}`,
    );
  }

  getSharedByDocument(
    companyId: string,
    documentId: string,
    page = 1,
    pageSize = 50,
    sortBy = '',
    order = 'asc',
    where?: Record<string, string>,
  ): Observable<PaginatedResponse<SharedDocument>> {
    const query: string = where
      ? `${Object.entries(where)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`
      : '';
    return this.http.get<PaginatedResponse<SharedDocument>>(
      `${environment.API_DOMAIN}/company/${companyId}/documents/${documentId}/shared?page=${page}&size=${pageSize}&sortBy=${sortBy}&order=${order}&${query}`,
    );
  }

  revokeCompanyShare(
    id: string,
    companyId: string,
  ): Observable<SharedDocument> {
    return this.http.delete<SharedDocument>(
      `${environment.API_DOMAIN}/company/${companyId}/shared-documents/${id}`,
    );
  }

  canBeShared(
    id: string,
    email: string,
    companyId: string,
  ): Observable<boolean> {
    return this.http
      .get<{ success: boolean }>(
        `${environment.API_DOMAIN}/company/${companyId}/shared-documents/${id}/${email}`,
      )
      .pipe(map((res) => res.success));
  }

  deleteDocument(id: string, companyId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.API_DOMAIN}/company/${companyId}/documents/${id}`,
    );
  }
}
