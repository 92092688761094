import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { User, UserProfile } from '@core/models';
import { AuthenticationService } from '@common/auth/services/authentication.service';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Nullable } from '@core/interfaces/nullable';
import { ProfileStoreService } from '@store/profile/profile-store.service';

@Component({
  selector: 'app-user-info-widget',
  templateUrl: './user-info-widget.component.html',
  styleUrls: ['./user-info-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoWidgetComponent implements OnInit, OnDestroy {
  @Output() seeDetails = new EventEmitter<Nullable<User>>();

  user: Nullable<User>;
  profile: Nullable<UserProfile>;
  defaultImage =
    "data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='600' height='600' fill='white'%3E%3Ctitle%3EAbstract user icon%3C/title%3E%3Cdefs%3E%3CclipPath id='circular-border'%3E%3Ccircle cx='300' cy='300' r='280' /%3E%3C/clipPath%3E%3CclipPath id='avoid-antialiasing-bugs'%3E%3Crect width='100%25' height='498' /%3E%3C/clipPath%3E%3C/defs%3E%3Ccircle cx='300' cy='300' r='280' fill='black' clip-path='url(%23avoid-antialiasing-bugs)' /%3E%3Ccircle cx='300' cy='230' r='115' /%3E%3Ccircle cx='300' cy='550' r='205' clip-path='url(%23circular-border)' /%3E%3C/svg%3E";

  avatarReady$ = new ReplaySubject<boolean>(1);
  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private profileService: ProfileStoreService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.profileService
      .getUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile) => {
        this.profile = profile;
        this.cdr.markForCheck();
      });

    this.authService
      .getIdentity()
      .pipe(
        filter((u) => !!u),
        takeUntil(this.destroy$),
      )
      .subscribe((user) => {
        this.user = user;
        this.cdr.markForCheck();
      });
  }

  onClick(): void {
    this.seeDetails.emit(this.user);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
