<app-data-table
  [initialSorting]="initialSorting"
  [initialFilter]="sharedWithFilter"
  [config]="config"
  [columns]="columns"
  [dataSource]="sharedDataSource"
></app-data-table>

<ng-template #sharedWith let-row="row">
  <app-contact [contact]="row.sharedWith"></app-contact>
</ng-template>

<ng-template #filtersPanel let-filter="filter">
  <p-multiSelect
    panelStyleClass="requested-from-dropdown"
    [filter]="true"
    [appendTo]="'body'"
    filterBy="name"
    [options]="contacts$ | async | filterByField : 'id' : null"
    placeholder="Shared with"
    optionValue="id"
    optionLabel="name"
    styleClass="mr-2"
    [virtualScroll]="true"
    [virtualScrollItemSize]="50"
    [ngModel]="requestedFromFilter"
    (ngModelChange)="filter($event, 'sharedWith.id', 'in')"
  >
    <ng-template let-items pTemplate="selectedItems">
      <ng-container *ngIf="items?.length > 0; else empty">
        Shared with ({{ items.length }})
      </ng-container>
      <ng-template #empty> Shared with</ng-template>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <app-contact [contact]="item"></app-contact>
    </ng-template>
  </p-multiSelect>
  <p-dropdown
    styleClass="mr-2"
    [autoDisplayFirst]="true"
    [options]="clientCategories$ | async"
    placeholder="Category"
    [ngModel]="categoryFilter"
    optionValue="id"
    optionLabel="name"
    [filter]="true"
    [filterBy]="'name'"
    (ngModelChange)="filter($event, 'category.id', 'equals')"
  ></p-dropdown>
</ng-template>

<ng-template #statusActions let-row="row">
  <div class="flex justify-content-center w-full">
    <div *ngIf="row.status === statuses.Pending">
      <p-tag severity="info" [rounded]="true" value="Pending"></p-tag>
    </div>
    <div *ngIf="row.status === statuses.Accepted">
      <p-tag
        *ngIf="row.origin === origins.Company"
        severity="success"
        [rounded]="true"
        value="Accepted"
      ></p-tag>
    </div>
    <div *ngIf="row.status === statuses.Declined">
      <p-tag
        *ngIf="row.origin === origins.Company"
        severity="danger"
        [rounded]="true"
        value="Declined"
      ></p-tag>
    </div>
    <div *ngIf="row.status === statuses.Revoked">
      <p-tag severity="warning" [rounded]="true" value="Revoked"></p-tag>
    </div>
    <div *ngIf="row.status === statuses.Failed">
      <i
        pTooltip="Failed document share or the document is corrupted"
        class="pi pi-exclamation-circle color-error"
      ></i>
    </div>
  </div>
</ng-template>
