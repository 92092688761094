import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@common/auth/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { delay, take, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss'],
  providers: [MessageService],
})
export class RestorePasswordComponent implements OnInit {
  private token!: string;
  private viewReady$ = new ReplaySubject<boolean>(1);

  processing$ = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private logger: NGXLogger,
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot?.paramMap?.get('token') as string;
  }

  onRestorePassword(event: {
    password: string;
    confirmPassword: string;
  }): void {
    this.processing$.next(true);
    this.cdr.detectChanges();
    this.authService
      .restorePassword(this.token, event.password, event.confirmPassword)
      .pipe(
        take(1),
        tap(() => {
          this.messageService.addAll([
            {
              severity: 'success',
              detail:
                'Password has been changed. Use a new password to sign in',
            },
          ]);
          this.processing$.next(false);
        }),
        delay(3000),
      )
      .subscribe({
        next: () => {
          this.router.navigate([this.authService.getInterruptedUrl()]);
          this.processing$.next(false);
        },
        error: (e) => {
          const msg = e.error?.message || 'Invalid credentials';
          this.processing$.next(false);
          this.messageService.addAll([
            {
              severity: 'error',
              detail: msg,
            },
          ]);
          this.logger.error(
            'RestorePasswordComponent.onRestorePassword error',
            e,
          );
        },
      });
  }
}
