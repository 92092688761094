import { ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '@common/auth/services/authentication.service';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { delay, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [MessageService],
})
export class RegisterComponent {
  processing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  errors: any = [];

  constructor(
    private authService: AuthenticationService,
    private logger: NGXLogger,
    private router: Router,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
  ) {}

  onCreate(userData: any): void {
    this.processing$.next(true);
    this.cdr.detectChanges();
    this.authService
      .createAccount({
        username: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        password: userData.password,
        confirmPassword: userData.confirmPassword,
      })
      .pipe(
        take(1),
        tap((user) => {
          this.logger.debug('onCreateWithGoogle: ', user);
          this.messageService.addAll([
            {
              severity: 'success',
              detail: 'Account has been created. Check your email to continue',
            },
          ]);
          this.processing$.next(false);
        }),
        delay(5000),
      )
      .subscribe({
        next: (user) => {
          this.logger.debug('onCreate: ', user);
          this.router.navigate([this.authService.getInterruptedUrl()]);
        },
        error: (e) => {
          this.processing$.next(false);
          let msg = 'Something went wrong';
          if (e?.error?.message) {
            msg =
              e?.error?.message instanceof Array
                ? e.error.message.join(' ')
                : e.error.message;
          }
          this.messageService.addAll([
            {
              severity: 'error',
              detail: msg,
            },
          ]);
          this.logger.error('RegisterComponent.onCreate error', e);
        },
      });
  }

  onCreateWithGoogle(): void {
    this.authService
      .createAccountWithGoogle()
      .pipe(
        take(1),
        tap((user) => {
          this.logger.debug('onCreateWithGoogle: ', user);
          this.messageService.addAll([
            {
              severity: 'success',
              detail: 'Account has been created. Login with Google to proceed',
            },
          ]);
          this.processing$.next(false);
        }),
        delay(5000),
      )
      .subscribe({
        next: (user) => {
          this.router.navigate([this.authService.getInterruptedUrl()]);
        },
        error: (e) => {
          const msg =
            e.error?.message || 'Something goes wrong. Contact support';
          this.processing$.next(false);
          this.messageService.addAll([
            {
              severity: 'error',
              detail: msg,
            },
          ]);
          this.logger.error('RegisterComponent.onLoginWithGoogle error', e);
        },
      });
  }
}
