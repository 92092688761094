import { Category } from '@core/models/category.model';
import { CreateCategory } from '@core/models/create-category.model';
import { Nullable } from '@core/interfaces/nullable';

export class GetCategories {
  static readonly type = '[CATEGORIES] Get Categories';

  constructor(public companyId: string) {}
}

export class CategoriesLoaded {
  static readonly type = '[CATEGORIES] Categories Loaded';

  constructor(public payload: Category[]) {}
}

export class AddCategory {
  static readonly type = '[CATEGORIES] Create Category';

  constructor(
    public companyId: string,
    public payload: CreateCategory,
    public logo: Nullable<File>,
  ) {}
}

export class CategoryCreated {
  static readonly type = '[CATEGORIES] Category Created';

  constructor(public companyId: string, public payload: Category) {}
}

export class CategoryUpdated {
  static readonly type = '[CATEGORIES] Category Updated';

  constructor(public companyId: string, public payload: Category) {}
}

export class UpdateCategory {
  static readonly type = '[CATEGORIES] Update Category';

  constructor(
    public id: string,
    public companyId: string,
    public payload: Partial<CreateCategory>,
    public logo: Nullable<File>,
  ) {}
}

export class DeleteCategory {
  static readonly type = '[CATEGORIES] Delete Category';

  constructor(public id: string, public companyId: string) {}
}

export class ClearCategoriesErrors {
  static readonly type = '[CATEGORIES] Clear Categories Errors';

  constructor() {}
}
