import { Pipe, PipeTransform } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Nullable } from '@core/interfaces/nullable';
import { TreeLike } from '@common/shared/types/data-node';

const path = (node: Nullable<TreeNode | TreeLike>): string => {
  if (!node) {
    return '';
  }
  const parentPath = path(node.parent);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const label = node.label ? node.label : node['name'];
  return `${parentPath?.length > 0 ? parentPath + ' / ' : ''}${label}`;
};

@Pipe({
  name: 'treePath',
})
export class TreePathPipe implements PipeTransform {
  transform(node: Nullable<TreeNode | TreeLike>): string {
    if ((node as TreeLike)?.isRoot || node?.data?.isRoot) {
      return '/';
    }
    const fullPath = path(node);
    return fullPath.length === 0 ? '/' : `/ ${fullPath} /`;
  }
}
