export const PERMISSIONS = {
  COMMON_VIEW: 'COMMON_VIEW',
  COMMON_MODIFY: 'COMMON_MODIFY',
  SYSTEM_ADMINISTRATION_VIEW: 'SYSTEM_ADMINISTRATION_VIEW',
  SYSTEM_ADMINISTRATION_MODIFY: 'SYSTEM_ADMINISTRATION_MODIFY',
  COMPANY_COMMON_MODIFY: 'COMPANY_COMMON_MODIFY',
  COMPANY_COMMON_VIEW: 'COMPANY_COMMON_VIEW',
  COMPANY_DOCUMENTS_MODIFY: 'COMPANY_DOCUMENTS_MODIFY',
  COMPANY_DOCUMENTS_VIEW: 'COMPANY_DOCUMENTS_VIEW',
  COMPANY_DOCUMENT_REQUESTS_MODIFY: 'COMPANY_DOCUMENT_REQUESTS_MODIFY',
  COMPANY_DOCUMENT_REQUESTS_VIEW: 'COMPANY_DOCUMENT_REQUESTS_VIEW',
  ACCOUNT_MODIFY: 'ACCOUNT_MODIFY',
  ACCOUNT_VIEW: 'ACCOUNT_VIEW',
};
