import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core/core.module';
import { PrimengUiModule } from '@ui/primeng/primeng-ui.module';

import { LoginFormComponent } from './components/login-form/login-form.component';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { SharedCommonModule } from '@common/shared/shared-common.module';
import { FormElementsUiModule } from '@ui/forms/elements/form-elements-ui.module';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';

@NgModule({
  declarations: [
    LoginFormComponent,
    ForgotPasswordFormComponent,
    RegisterFormComponent,
    ChangePasswordFormComponent,
  ],
  exports: [
    LoginFormComponent,
    ForgotPasswordFormComponent,
    RegisterFormComponent,
    ChangePasswordFormComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    PrimengUiModule,
    SharedCommonModule,
    FormElementsUiModule,
  ],
})
export class AuthFormsUiModule {}
