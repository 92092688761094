export enum CellType {
  Default = 'DEFAULT',
  Date = 'DATE',
  TimeDuration = 'TIME_DURATION',
  Icon = 'ICON',
  Actions = 'ACTIONS',
  // eslint-disable-next-line id-blacklist
  Boolean = 'BOOLEAN',
  Checkbox = 'CHECKBOX',
  FormattedNumber = 'FORMATTED_NUMBER',
  Image = 'IMAGE',
  Editable = 'EDITABLE',
  Humanize = 'HUMANIZE',
  FileSize = 'FILE_SIZE',
  RouterLink = 'ROUTER_LINK',
  DeepProperty = 'DEEP_PROPERTY',
  FormattedString = 'FORMATTED_STRING',
}
