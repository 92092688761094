import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appFormField]',
})
export class FormFieldDirective {
  @Input() appFormField!: string;
  @Input() appFormFieldLabel!: string;

  constructor(public templateRef: TemplateRef<any>) {}
}
