import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from '@core/models/category.model';
import { environment } from '@env/environment';
import { CreateCategory } from '@core/models/create-category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(private http: HttpClient) {}

  getCategories(companyId: string): Observable<Category[]> {
    return this.http.get<Category[]>(
      `${environment.API_DOMAIN}/company/${companyId}/categories`,
    );
  }

  createCategory(
    companyId: string,
    payload: CreateCategory,
  ): Observable<Category> {
    return this.http.post<Category>(
      `${environment.API_DOMAIN}/company/${companyId}/categories`,
      payload,
    );
  }

  updateCategory(
    id: string,
    companyId: string,
    payload: Partial<CreateCategory>,
  ): Observable<Category> {
    return this.http.put<Category>(
      `${environment.API_DOMAIN}/company/${companyId}/categories/${id}`,
      payload,
    );
  }

  deleteCategory(id: string, companyId: string): Observable<Category> {
    return this.http.delete<Category>(
      `${environment.API_DOMAIN}/company/${companyId}/categories/${id}`,
    );
  }
}
