import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CategoryShort, UploadProgress } from '@core/models';
import { take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject, Subject, withLatestFrom } from 'rxjs';
import { FileNamePipe } from '@core/pipes/file-name.pipe';
import { FormApplyService } from '@common/shared/services/form-apply.service';
import { NGXLogger } from 'ngx-logger';
import { FileType } from '@core/enums/file-type.enum';
import { MediaType } from '@core/enums/media-type.enum';
import { Nullable } from '@core/interfaces/nullable';

@Directive({
  selector: '[appBaseUploadDocuments]',
})
export class BaseUploadDocumentsDirective
  implements OnInit, OnDestroy, AfterViewInit
{
  filesLimit = 50;

  @Input() disabled!: boolean;
  @Input() categories!: CategoryShort[];
  @Input() progress!: Array<{
    index: number;
    loaded: number;
    total: number;
    error?: string;
  }>;

  @Input() set clear(value: boolean) {
    if (value !== undefined) {
      this.clearValue = value;
      if (value) {
        this.viewReady$
          .pipe(take(1), takeUntil(this.destroy$))
          .subscribe(() => {
            this.clearInput();
          });
      }
    }
    this.clearChange.emit(value);
    this.cdr.markForCheck();
  }

  get clear(): boolean {
    return this.clearValue;
  }

  @Output() clearChange = new EventEmitter<boolean>();
  @Output() errors = new EventEmitter<string>();
  @Output() selected = new EventEmitter<File[]>();
  @Output() uploading = new EventEmitter<boolean>();
  @Output() completed = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<{ files: File[]; names: string[] }>();

  processing$ = new BehaviorSubject(false);
  names: Array<string> = [];
  files!: File[];
  filesDataUrls!: Array<{ index: number; result: string }>;

  protected loaded: any;
  protected error: any;
  protected viewReady$ = new ReplaySubject<boolean>(1);
  protected destroy$ = new Subject<void>();
  protected clearValue!: boolean;

  constructor(
    protected fileName: FileNamePipe,
    protected applyService: FormApplyService,
    protected cdr: ChangeDetectorRef,
    protected logger: NGXLogger,
  ) {}

  ngOnInit(): void {
    this.processing$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.uploading.emit(val);
    });

    this.applyService
      .onApply()
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.processing$))
      .subscribe(([_, processing]) => {
        if (!processing) {
          // this.upload();
          this.processing$.next(true);
        }
      });

    this.applyService
      .onCancel()
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.processing$))
      .subscribe(([_, processing]) => {
        if (!processing) {
          this.clearInput();
        }
      });

    this.completed.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      if (val) {
        this.clearInput();
      }
    });
  }

  ngAfterViewInit(): void {
    this.viewReady$.next(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSelect(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.disabled) {
      return;
    }
    if (event.currentTarget?.files && event.currentTarget.files.length > 0) {
      this.readFiles(event.currentTarget.files);
    }
  }

  onLoaded(index: number, event: any): void {
    if (event?.currentTarget?.result) {
      this.filesDataUrls.push({
        index,
        result: event.currentTarget.result,
      });
      this.cdr.markForCheck();
    }
  }

  onError(error: any): void {
    this.logger.error(
      'UploadSingleDocumentComponent.onError',
      error.target.message,
    );
    this.errors.emit('Unsupported file format');
    this.cdr.markForCheck();
  }

  dropHandler(event: any): void {
    event.preventDefault();
    if (this.disabled) {
      return;
    }
    const files: File[] = [];
    if (event.dataTransfer.items) {
      [...event.dataTransfer.items].forEach((item, i) => {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          files.push(file);
        }
      });
    } else {
      files.push(...event.dataTransfer.files);
    }
    this.readFiles(files);
  }

  get acceptTypes(): string {
    return Object.keys(FileType)
      .map((key) => '.' + key.toLowerCase())
      .join(',');
  }

  get acceptExtensions(): string[] {
    return Object.keys(FileType).map((key) => key.toLowerCase());
  }

  fileSrc(index: number): Nullable<string> {
    return this.filesDataUrls?.find((file) => file.index === index)?.result;
  }

  onFileUnselected(index: number): void {
    if (index >= 0) {
      this.files.splice(index, 1);
      this.names.splice(index, 1);
    }
    this.notify();
  }

  clearInput(): void {
    this.filesDataUrls = [];
    this.files = [];
    this.names = [];
    this.clearChange.emit(false);
    this.notify();
    this.cdr.detectChanges();
  }

  onNameEdit(name: string, i: number): void {
    this.names[i] = name;
    this.notify();
    this.cdr.markForCheck();
  }

  progressForFile(index: number): Nullable<UploadProgress> {
    return this.progress?.find((p) => p.index === index);
  }

  protected notify(): void {
    this.changed.emit({ files: [...this.files], names: [...this.names] });
  }

  protected readFiles(files: File[]): void {
    this.filesDataUrls = [];
    this.files = [...files]
      .slice(0, this.filesLimit)
      .filter((file) => this.validateFileFormat(file));
    if (this.files.length === 0) {
      this.errors.emit('Unsupported file format');
      return;
    }
    this.names = this.files.map((f) => this.fileName.transform(f.name));
    this.files.forEach((file, i) => {
      if (
        [MediaType.Png, MediaType.Jpg, MediaType.Jpeg, MediaType.Gif].includes(
          file.type as MediaType,
        )
      ) {
        const reader = new FileReader();
        reader.onload = this.onLoaded.bind(this, i);
        reader.onerror = this.onError.bind(this, i);
        reader.readAsDataURL(file);
      }
    });
    this.selected.emit(this.files);
    this.notify();
  }

  protected validateFileFormat(file: File): boolean {
    return (
      Object.keys(MediaType)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .map((key: string) => MediaType[key] as string)
        .includes(file.type)
    );
  }
}
