import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseFormDirective } from '@ui/forms/base-form.component';
import { CategoryShort, CreateDocument } from '@core/models';
import { DateFormats } from '@core/constants';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-upload-document-form',
  templateUrl: './upload-document-form.component.html',
  styleUrls: ['./upload-document-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDocumentFormComponent extends BaseFormDirective<CreateDocument> {
  @Input() categories!: CategoryShort[];

  override initForm(model?: CreateDocument): void {
    this.form = this.fb.group({
      category: this.fb.control(model?.category || '', [Validators.required]),
      name: this.fb.control(model?.name ?? (model?.file as File)?.name, [
        Validators.required,
      ]),
    });
  }
}
