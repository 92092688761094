import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  ChangePassword,
  GetUserProfile,
  UpdateAvatar,
  UpdateEmail,
  UpdateUserName,
} from '@store/profile/profile.actions';
import { filter, switchMap } from 'rxjs/operators';
import { ProfileState } from '@store/profile/states/profile.state';
import { UserProfile } from '@core/models';

@Injectable()
export class ProfileStoreService {
  constructor(private store: Store) {}

  getUserProfile(): Observable<UserProfile> {
    return this.store.dispatch(new GetUserProfile()).pipe(
      switchMap(() => {
        return this.store
          .select(ProfileState.selectProfile)
          .pipe(filter((user) => !!user)) as Observable<UserProfile>;
      }),
    );
  }

  updateUserName(firstName: string, lastName: string): Observable<void> {
    return this.store.dispatch(
      new UpdateUserName({
        firstName,
        lastName,
      }),
    );
  }

  updateEmail(email: string, password: string): Observable<void> {
    return this.store.dispatch(
      new UpdateEmail({
        email,
        password,
      }),
    );
  }

  changePassword(
    oldPassword: string,
    password: string,
    confirmPassword: string,
  ): Observable<void> {
    return this.store.dispatch(
      new ChangePassword({
        oldPassword,
        password,
        confirmPassword,
      }),
    );
  }

  updateAvatar(avatar: File): Observable<void> {
    return this.store.dispatch(new UpdateAvatar(avatar));
  }
}
