<div class="actions-wrapper">
  <ng-container *ngFor="let action of actions">
    <button
      pButton
      pRipple
      [disabled]="disabled(action)"
      [class.disabled]="disabled(action)"
      [icon]="icon(action)"
      class="p-button-text p-button-rounded p-button-{{ color(action) }} {{
        action.className
      }}"
      [pTooltip]="tooltip(action)"
      tooltipPosition="top"
      (click)="onAction(action, $event)"
    ></button>
  </ng-container>
</div>
