<div *ngIf="contact" class="contact">
  <div class="contact__avatar">
    <div *ngIf="contact?.isSynced" class="fyio-label"></div>
    <ng-container *ngIf="contact.avatar">
      <img class="ml-2" [src]="contact.avatar" size="small" appDefaultImage />
    </ng-container>
    <ng-container *ngIf="!contact.avatar">
      <p-avatar
        styleClass="ml-2"
        shape="circle"
        [label]="name | uppercase | capitalLetters"
      ></p-avatar>
    </ng-container>
  </div>
  <div class="contact__name-wapper">
    <div class="contact__name">
      {{ name }}
    </div>
    <div class="contact__email">{{ contact.email || contact.username }}</div>
  </div>
</div>
