import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseFormDirective } from '@ui/forms/base-form.component';
import { Validators } from '@angular/forms';
import { CustomValidators } from '@common/shared/utils/validation/custom-validators';

@Component({
  selector: 'app-profile-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordFormComponent extends BaseFormDirective<{
  oldPassword: string;
  password: string;
  confirmPassword: string;
}> {
  override initForm(model: {
    oldPassword: string;
    password: string;
    confirmPassword: string;
  }) {
    this.form = this.fb.group({
      password: this.fb.control(model?.password, [Validators.required]),
      oldPassword: this.fb.control(model?.oldPassword, [Validators.required]),
      confirmPassword: this.fb.control(model?.confirmPassword, [
        Validators.required,
        CustomValidators.passwordMatchValidator,
      ]),
    });
  }
}
