<div
  *ngIf="!file"
  class="uploader-container field mb-3"
  [class.disabled]="disabled"
  (drop)="dropHandler($event)"
>
  <input
    #input
    type="file"
    [disabled]="disabled"
    (change)="onSelect($event)"
    [accept]="acceptTypes"
  />
  <i class="pi pi-upload"></i>
  <div class="empty-placeholder">
    <div>Click to upload or drag and drop file here</div>
    <span class="desc">Upload limit: Max 100 MB per file allowed.</span>
  </div>
</div>
<div class="mb-3">
  <app-file-info
    [file]="file"
    [src]="fileSrc(0)"
    [progress]="progress?.length ? progress[0] : null"
    [disabled]="disabled"
    (closed)="clearInput()"
  ></app-file-info>
</div>
