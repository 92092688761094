import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  EMPTY,
  interval,
  Observable,
  skipUntil,
  Subject,
  switchMap,
  throwError,
} from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AuthenticationService } from '@common/auth/services/authentication.service';

@Injectable()
export class AuthErrorsInterceptor implements HttpInterceptor {
  private updating = false;
  private refreshed$ = new Subject<{ jwt: string }>();

  constructor(private authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const isUnauthorizedError = (request: HttpRequest<any>) => {
          return (
            request.url.includes(environment.API_DOMAIN) &&
            [401].includes(err.status)
          );
        };
        if (isUnauthorizedError(request)) {
          if (this.authService.refreshShouldHappen(err, request)) {
            let sub$: Observable<{
              request: HttpRequest<any>;
              token: { jwt: string };
            }>;
            if (this.updating) {
              sub$ = interval().pipe(
                skipUntil(this.refreshed$),
                switchMap(() => this.refreshed$),
                take(1),
                map((token) => ({ request, token })),
              );
            } else {
              this.updating = true;
              sub$ = this.authService.refreshToken().pipe(
                take(1),
                tap((res) => this.refreshed$.next(res)),
                map((token) => ({ request, token })),
                tap(() => (this.updating = false)),
                catchError((e) => {
                  if (isUnauthorizedError(request)) {
                    this.authService.logout();
                    return EMPTY;
                  } else {
                    return throwError(e);
                  }
                }),
              );
            }
            return sub$.pipe(
              switchMap((payload) =>
                this.updateAuthorizationToken(payload.request),
              ),
              switchMap((updatedRequest) => next.handle(updatedRequest)),
            );
          }
        }

        return throwError(err);
      }),
    );
  }

  private updateAuthorizationToken(
    request: HttpRequest<any>,
  ): Observable<HttpRequest<any>> {
    return this.authService.getAccessToken().pipe(
      map((token) =>
        request.clone({
          headers: request.headers,
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ),
    );
  }
}
