import { Pipe, PipeTransform } from '@angular/core';
import { filesize } from 'filesize';

@Pipe({
  name: 'filesize',
})
export class FileSizePipe implements PipeTransform {
  transform(value: string | number, options?: any): string {
    // const filesize: any = filesize_;
    return filesize(value, options) as string;
  }
}
