import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TableRowData } from '../../../interfaces';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@Component({
  selector: 'app-formatted-number-cell-renderer',
  templateUrl: './formatted-number-cell-renderer.component.html',
  styleUrls: ['./formatted-number-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormattedNumberCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<string, R>
  implements OnInit
{
  locale: string | undefined = 'en-US';
  format: string | undefined = '1.2-2';
  fontStyle: { weight: string | 'normal' | 'bold'; color: string } | undefined =
    { weight: 'normal', color: 'default' };

  ngOnInit(): void {
    if (this.getOptions()['locale']) {
      this.locale = this.getOptions()['locale'] as string;
    }
    if (this.getOptions()['format']) {
      this.format = this.getOptions()['format'] as string;
    }
    if (this.getOptions()['font']) {
      this.fontStyle = this.getOptions()['font'] as any;
    }
  }

  override render(): string {
    return this.getRawValue() as string;
  }

  getFontStyle(): any {
    return {
      fontWeight: this.fontStyle?.weight || 'normal',
    };
  }

  getFontColor(): string {
    return 'color-' + (this.fontStyle?.color || 'default');
  }
}
