import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root',
})
export class JwtService {
  decode<T>(token: string): T {
    return jwt_decode(token);
  }

  isTokenExpired(token: string): boolean {
    const payload = this.decode<{ exp: string }>(token);
    const now = Math.round(new Date().getTime() / 1000);
    if (payload && payload.exp && parseInt(payload.exp) > now) {
      return false;
    }
    return true;
  }
}
