import { Nullable } from '@core/interfaces/nullable';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Category } from '@core/models/category.model';
import { CategoriesService } from '@core/services/api/categories.service';
import { interval, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import {
  AddCategory,
  CategoryCreated,
  CategoryUpdated,
  ClearCategoriesErrors,
  DeleteCategory,
  GetCategories,
  UpdateCategory,
} from '@store/categories/categories.actions';
import { PublicAssetsUploadService } from '@common/shared/services/public-assets-upload.service';
import { UploadedFile } from '@core/models/uploaded-file.model';
import { CreateCategory } from '@core/models/create-category.model';
import { restoreParentNodeInTree } from '@common/shared/utils/restore-parent-node-in-tree';

export interface CategoriesStateModel {
  list: Nullable<Category[]>;
  created: Nullable<Category>;
  updated: Nullable<Category>;
  error: any;
}

@State<CategoriesStateModel>({
  name: 'categories',
  defaults: {
    list: null,
    created: null,
    updated: null,
    error: null,
  },
})
@Injectable()
export class CategoriesState {
  constructor(
    private categories: CategoriesService,
    private uploader: PublicAssetsUploadService,
  ) {}

  @Selector()
  static getState(state: CategoriesStateModel): CategoriesStateModel {
    return state;
  }

  @Selector()
  static getCreated(state: CategoriesStateModel): Nullable<Category> {
    return state.created;
  }

  @Selector()
  static getUpdated(state: CategoriesStateModel): Nullable<Category> {
    return state.updated;
  }

  @Selector()
  static getError(state: CategoriesStateModel): Nullable<any> {
    return state.error;
  }

  @Selector()
  static getCategories(state: CategoriesStateModel): Nullable<Category[]> {
    return state.list;
  }

  @Action(GetCategories)
  getCategories(
    ctx: StateContext<CategoriesStateModel>,
    action: GetCategories,
  ): Observable<any> {
    return this.categories.getCategories(action.companyId).pipe(
      tap((data) => {
        const list = data.map(
          (c) => <Category>restoreParentNodeInTree(c),
        );
        ctx.patchState({
          list,
        });
      }),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(AddCategory)
  create(
    ctx: StateContext<CategoriesStateModel>,
    action: AddCategory,
  ): Observable<any> {
    ctx.patchState({
      created: null,
    });
    const next$: Observable<Nullable<UploadedFile | string>> = action.logo
      ? this.uploader.upload(action.logo)
      : interval(0).pipe(
          take(1),
          map(() => action.payload.logoUrl),
        );
    return next$.pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      take(1),
      switchMap((file: UploadedFile | string) => {
        return this.categories.createCategory(action.companyId, {
          ...action.payload,
          logoUrl: file && typeof file !== 'string' ? file.path : file,
        } as CreateCategory);
      }),
      switchMap((resp: Category) =>
        ctx.dispatch(new CategoryCreated(action.companyId, resp)),
      ),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(UpdateCategory)
  update(
    ctx: StateContext<CategoriesStateModel>,
    action: UpdateCategory,
  ): Observable<void> {
    ctx.patchState({
      updated: null,
    });
    const next$: Observable<Nullable<UploadedFile | string>> = action.logo
      ? this.uploader.upload(action.logo)
      : interval(0).pipe(
          take(1),
          map(() => action.payload.logoUrl),
        );
    return next$.pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      take(1),
      switchMap((file: UploadedFile | string) =>
        this.categories.updateCategory(action.id, action.companyId, {
          ...action.payload,
          logoUrl: file && typeof file !== 'string' ? file.path : file,
        }),
      ),
      switchMap((resp) =>
        ctx.dispatch(new CategoryUpdated(action.companyId, resp)),
      ),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(DeleteCategory)
  deleteCategory(
    ctx: StateContext<CategoriesStateModel>,
    action: DeleteCategory,
  ): Observable<void> {
    return this.categories.deleteCategory(action.id, action.companyId).pipe(
      switchMap((resp) => ctx.dispatch(new GetCategories(action.companyId))),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(CategoryCreated)
  created(
    ctx: StateContext<CategoriesStateModel>,
    action: CategoryCreated,
  ): Observable<void> {
    ctx.patchState({
      created: action.payload,
    });
    return ctx.dispatch(new GetCategories(action.companyId));
  }

  @Action(CategoryUpdated)
  updated(
    ctx: StateContext<CategoriesStateModel>,
    action: CategoryUpdated,
  ): Observable<void> {
    ctx.patchState({
      updated: action.payload,
    });
    return ctx.dispatch(new GetCategories(action.companyId));
  }

  @Action(ClearCategoriesErrors)
  clearErrors(ctx: StateContext<CategoriesStateModel>): void {
    ctx.patchState({
      error: null,
    });
  }
}
