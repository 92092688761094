import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { SharedCommonModule } from '@common/shared/shared-common.module';
import { PrimengUiModule } from '@ui/primeng/primeng-ui.module';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { DocumentPreviewWidgetComponent } from '@ui/components/document-preview-widget/document-preview-component-widget.component';
import { NgOptimizedImage } from '@angular/common';

@NgModule({
  imports: [
    CoreModule,
    SharedCommonModule,
    PrimengUiModule,
    PdfJsViewerModule,
    NgOptimizedImage,
  ],
  declarations: [DocumentPreviewWidgetComponent],
  exports: [DocumentPreviewWidgetComponent],
})
export class DocumentPreviewWidgetModule {}
