import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { Nullable } from '@core/interfaces/nullable';
import { MediaType } from '@core/enums/media-type.enum';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { UploadedFile } from '@core/models/uploaded-file.model';
import { take, takeUntil } from 'rxjs/operators';
import { DocumentsService } from '@core/services/api/documents.service';

@Component({
  selector: 'app-document-preview-widget',
  templateUrl: './document-preview-widget.component.html',
  styleUrls: ['./document-preview-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewWidgetComponent implements OnDestroy {
  @ViewChild('pdfViewer', { static: true }) pdfViewer!: PdfJsViewerComponent;
  @ViewChild('imageViewer', { static: true }) imageViewer!: ElementRef;

  @Input() set file(file: Nullable<UploadedFile>) {
    this.fileValue = file;
    this.reset();
    if (file?.url) {
      this.documentFilesService
        .loadDocumentFile(file.url)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe((data) => {
          this.initFileData(data);
        });
    }
  }

  @Output() loaded = new EventEmitter<boolean>();

  imageSrc!: Nullable<string>;
  pdfSrc!: Nullable<Blob>;
  fileValue!: Nullable<UploadedFile>;

  loading$ = new BehaviorSubject(true);
  destroy$ = new Subject<void>();
  message!: Nullable<string>;

  constructor(
    private documentFilesService: DocumentsService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onLoaded(_: any): void {
    this.loaded.emit(true);
  }

  isImage(type?: string): boolean {
    return (
      !!type &&
      [MediaType.Jpg, MediaType.Jpeg, MediaType.Png].includes(type as MediaType)
    );
  }

  private initFileData(data: Nullable<Blob>): void {
    if (data) {
      if (data.type === MediaType.Pdf) {
        if (data.size > 0 && this.pdfViewer) {
          this.pdfViewer.showSpinner = false;
          this.pdfViewer.pdfSrc = data as Blob;
          this.pdfViewer.refresh();
          this.pdfSrc = data;
          this.loading$.next(false);
        } else {
          data = null;
        }
      } else if (this.isImage(data.type)) {
        this.loading$.next(false);
        const urlCreator = window.URL || window.webkitURL;
        this.imageSrc = urlCreator.createObjectURL(data);
        this.imageViewer.nativeElement.src = this.imageSrc;
      } else {
        this.message = 'No preview is available for this file format';
        this.loading$.next(false);
      }
    }
    this.cdr.detectChanges();
  }
  private reset(): void {
    this.message = null;
    this.loading$.next(true);
    this.pdfSrc = null;
    this.imageSrc = null;
    if (this.pdfViewer) {
      this.pdfViewer.pdfSrc = '';
      this.pdfViewer.showSpinner = true;
    }
    if (this.imageViewer?.nativeElement) {
      this.imageViewer.nativeElement.src = undefined;
    }
    this.cdr.detectChanges();
  }
}
