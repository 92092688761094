<form *ngIf="form" [formGroup]="form" class="profile-form-content">
  <div class="p-2 w-full">
    <app-form-fields-wrapper [showLabels]="true">
      <input
        *appFormField="'oldPassword'; label: 'Password'"
        formControlName="oldPassword"
        type="password"
        placeholder="Enter old password"
        pInputText
        class="w-full mb-1"
      />
      <p-password
        *appFormField="'password'; label: 'New Password'"
        formControlName="password"
        placeholder="Enter new password"
        styleClass="w-full mb-1"
      />
      <input
        *appFormField="'confirmPassword'; label: 'Confirm Password'"
        formControlName="confirmPassword"
        type="password"
        placeholder="Confirm new password"
        pInputText
        class="w-full mb-1"
      />
    </app-form-fields-wrapper>
  </div>
</form>
