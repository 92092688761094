import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Nullable } from '@core/interfaces/nullable';
import { MediaType } from '@core/enums/media-type.enum';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadProgress } from '@core/models';
import { FileType } from '@core/enums/file-type.enum';

@Component({
  selector: 'app-file-info',
  templateUrl: './file-info.component.html',
  styleUrls: ['./file-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInfoComponent implements OnInit, OnDestroy {
  @Input() editable!: boolean;
  @Input() progress!: Nullable<UploadProgress>;
  @Input() name!: string;
  @Input() inline!: boolean;
  @Input() file!:
    | Nullable<File>
    | Nullable<{
        type: FileType;
        mediaType: MediaType;
        name: string;
        size: number;
      }>;
  @Input() src!: Nullable<string>;
  @Input() disabled!: boolean;
  @Input() outline = true;
  @Input() closable = true;

  @Output() closed = new EventEmitter<void>();
  @Output() edited = new EventEmitter<string>();

  inputChanged$ = new Subject<string>();

  updatedName!: string;

  defaultImage =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' shape-rendering='geometricPrecision' text-rendering='geometricPrecision' image-rendering='optimizeQuality' fill-rule='evenodd' clip-rule='evenodd' viewBox='0 0 399 511.66'%3E%3Cpath fill-rule='nonzero' d='M71.1 0h190.92c5.22 0 9.85 2.5 12.77 6.38L394.7 136.11c2.81 3.05 4.21 6.92 4.21 10.78l.09 293.67c0 19.47-8.02 37.23-20.9 50.14l-.09.08c-12.9 12.87-30.66 20.88-50.11 20.88H71.1c-19.54 0-37.33-8.01-50.22-20.9C8.01 477.89 0 460.1 0 440.56V71.1c0-19.56 8-37.35 20.87-50.23C33.75 8 51.54 0 71.1 0zm45.78 254.04c-8.81 0-15.96-7.15-15.96-15.95 0-8.81 7.15-15.96 15.96-15.96h165.23c8.81 0 15.96 7.15 15.96 15.96 0 8.8-7.15 15.95-15.96 15.95H116.88zm0 79.38c-8.81 0-15.96-7.15-15.96-15.96 0-8.8 7.15-15.95 15.96-15.95h156.47c8.81 0 15.96 7.15 15.96 15.95 0 8.81-7.15 15.96-15.96 15.96H116.88zm0 79.39c-8.81 0-15.96-7.15-15.96-15.96s7.15-15.95 15.96-15.95h132.7c8.81 0 15.95 7.14 15.95 15.95 0 8.81-7.14 15.96-15.95 15.96h-132.7zm154.2-363.67v54.21c1.07 13.59 5.77 24.22 13.99 31.24 8.63 7.37 21.65 11.52 38.95 11.83l36.93-.05-89.87-97.23zm96.01 129.11-43.31-.05c-25.2-.4-45.08-7.2-59.39-19.43-14.91-12.76-23.34-30.81-25.07-53.11l-.15-2.22V31.91H71.1c-10.77 0-20.58 4.42-27.68 11.51-7.09 7.1-11.51 16.91-11.51 27.68v369.46c0 10.76 4.43 20.56 11.52 27.65 7.11 7.12 16.92 11.53 27.67 11.53h256.8c10.78 0 20.58-4.4 27.65-11.48 7.13-7.12 11.54-16.93 11.54-27.7V178.25z'/%3E%3C/svg%3E";

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.inputChanged$
      .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
      .subscribe((value: string) => {
        this.edited.emit(value);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get fileType(): string {
    let type: MediaType;
    if (!this.file) {
      return 'other';
    }
    if (this.file instanceof File) {
      type = this.file.type as MediaType;
    } else {
      type = this.file.mediaType;
    }

    switch (type) {
      case MediaType.Jpeg:
      case MediaType.Jpg: {
        return 'jpg';
      }
      case MediaType.Png:
        return 'png';
      case MediaType.Pdf:
        return 'pdf';
      case MediaType.Xls:
      case MediaType.Xlsx:
        return 'xls';
      case MediaType.Doc:
      case MediaType.Docx:
        return 'doc';
      default:
        return 'other';
    }
  }

  get fileName(): string {
    return this.name || (this.file?.name as string);
  }

  get isImage(): boolean {
    if (!this.file) {
      return false;
    }
    let mediaType: MediaType;
    if (this.file instanceof File) {
      mediaType = this.file.type as MediaType;
    } else {
      mediaType = this.file.mediaType;
    }

    return [
      MediaType.Jpg,
      MediaType.Jpeg,
      MediaType.Png,
      MediaType.Gif,
    ].includes(mediaType);
  }

  onEdit(name: string): void {
    this.updatedName = name?.trim();
    if (this.updatedName?.trim()?.length > 0) {
      this.inputChanged$.next(this.updatedName);
    }
  }
}
