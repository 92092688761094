import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SharingProgress } from '@store/sharing-progress/sharing-progress.state';
import { ProgressStatus } from "@common/shared/types/progress-status.enum";

@Component({
  selector: 'app-sharing-progress-item',
  templateUrl: './sharing-progress-item.component.html',
  styleUrls: ['./sharing-progress-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharingProgressItemComponent {
  @Input() item!: SharingProgress;
  @Output() remove = new EventEmitter<string>();

  status = ProgressStatus;

  get progressText(): string {
    const progress = this.item;
    return progress?.status === 'DELIVERING'
      ? `${progress?.delivered + progress?.failed}/${progress?.total}`
      : `${progress?.sent}/${progress?.total}`;
  }

  get progressValue(): number {
    const progress = this.item;
    return progress?.status === 'DELIVERING'
      ? Math.round(
          (100 * ((progress.failed || 0) + (progress.delivered || 0))) /
            (progress?.total || 1),
        )
      : Math.round((100 * (progress?.sent || 0)) / (progress?.total || 1));
  }

  onRemove() {
    this.remove.emit(this.item.taskId);
  }
}
