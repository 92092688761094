import { NavLink } from '@core/interfaces/nav-link.interface';

export const MAIN_NAVIGATION_LINKS: NavLink[] = [
  { name: 'Tiber Tracker', path: '/tracker' },
  { name: 'Properties', path: '/properties' },
  { name: 'Budget', path: '/budget' },
  { name: 'Review', path: '/review' },
  { name: 'Account', path: '/account' },
];

export const PROPERTIES_NAVIGATION_LINKS: NavLink[] = [
  { name: 'Tiber Capital', path: '/tiber-capital' },
  { name: 'Tiber Owned', path: '/properties' },
];

export const PROPERTY_NAVIGATION_LINKS: NavLink[] = [
  { name: 'Rental', path: 'rental' },
  { name: 'Flip', path: 'flip' },
  { name: 'Lease', path: 'lease' },
  { name: 'Notes', path: 'notes' },
  { name: 'OCC/HOA', path: 'occ-hoa' },
  { name: 'DOCS', path: 'docs' },
  { name: 'HUD/CLOSING', path: 'hud-closing' },
];
