import { Nullable } from '@core/interfaces/nullable';
import { Account, User } from '@core/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AccountService } from '@core/services/api/account.service';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
  CreateAccount,
  CreateAccountBySocialProvider,
} from '@store/account/account.actions';

export interface AccountStateModel {
  account: Nullable<Account>;
}

@State<AccountStateModel>({
  name: 'account',
  defaults: {
    account: null,
  },
})
@Injectable()
export class AccountState {
  constructor(private accountService: AccountService) {}

  @Selector()
  static getState(state: AccountStateModel): AccountStateModel {
    return state;
  }

  @Selector()
  static getAccountId(state: AccountStateModel): Nullable<string> {
    return state.account?.id;
  }

  @Selector()
  static getAccount(state: AccountStateModel): Nullable<Account> {
    return state.account;
  }

  @Action(CreateAccount)
  create(
    ctx: StateContext<AccountStateModel>,
    action: CreateAccount,
  ): Observable<Nullable<Account>> {
    return this.accountService
      .createAccount(action.payload)
      .pipe(
        tap((resp) => {
          ctx.patchState({
            account: resp,
          });
        }),
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        }),
      );
  }

  @Action(CreateAccountBySocialProvider)
  createBySocial(
    ctx: StateContext<AccountStateModel>,
    action: CreateAccountBySocialProvider,
  ): Observable<Nullable<Account>> {
    return this.accountService
      .createAccountBySocialProvider(action.payload)
      .pipe(
        tap((resp) => {
          ctx.patchState({
            account: resp,
          });
        }),
      )
      .pipe(
        catchError((e) => {
          return throwError(e);
        }),
      );
  }
}
