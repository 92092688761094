<div *ngIf="item" class="sharing-progress__item" [attr.data-id]="item.taskId">
  <div class="sharing-info">
    <i class="sharing-info__close-icon pi pi-times" (click)="onRemove()"></i>
    <div *ngIf="item?.status !== status.Completed" class="sharing-info__title">
      Sharing of '{{ item?.document?.name }}' in progress
    </div>
    <div *ngIf="item?.status === status.Completed" class="sharing-info__title">
      Sharing of '{{ item?.document?.name }}' is completed
    </div>
    <div class="sharing-info__progress">
      <p-progressBar
        [style]="{ height: '14px' }"
        [value]="progressValue"
      ></p-progressBar>
    </div>
    <div class="sharing-info__desc">
      <div>Started at: {{ item?.startTime | dateFormat }}</div>
      <div>
        {{ item?.status === status.Delivering ? 'Delivering' : 'Sending' }}:
        {{ progressText }}
      </div>
    </div>
  </div>
</div>
