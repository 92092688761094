import { Pipe, PipeTransform } from '@angular/core';
import pluralize from 'pluralize';

@Pipe({
  name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {
  transform(word: string, count: number): unknown {
    return pluralize(word, count || 1);
  }
}
