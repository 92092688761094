import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BaseUploadDocumentsDirective } from '@ui/components/upload-documents/base-upload-documents.directive';
import { Nullable } from '@core/interfaces/nullable';

@Component({
  selector: 'app-upload-single-document',
  templateUrl: './upload-single-document.component.html',
  styleUrls: ['./upload-single-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadSingleDocumentComponent
  extends BaseUploadDocumentsDirective
  implements OnInit, OnDestroy, AfterViewInit
{
  override readonly filesLimit = 1;
  get file(): Nullable<File> {
    return this.files?.length > 0 ? this.files[0] : null;
  }
}
