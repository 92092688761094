import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Company } from '@core/models/company.model';
import { BehaviorSubject, Subject, switchMap } from 'rxjs';
import { Nullable } from '@core/interfaces/nullable';
import { CompaniesStoreService } from '@store/companies/companies-store.service';
import { CreateCompanyRequest } from '@core/models/create-company-request.model';
import { MessageService } from 'primeng/api';
import { take, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from '@common/auth/services/authentication.service';
import { BaseFormDirective } from '@ui/forms/base-form.component';
import { Form } from '@ui/forms/form.service';

@Component({
  selector: 'app-companies-bar',
  templateUrl: './companies-bar.component.html',
  styleUrls: ['./companies-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class CompaniesBarComponent implements OnInit, OnDestroy {
  companyForm!: Form;
  active!: string;
  addCompanyDialogVisible = false;
  company: Nullable<Company> = {} as Company;
  formValid!: boolean;
  logoFile!: Nullable<File>;

  companies!: Company[];
  clearImage = true;
  processing$ = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<void>();

  constructor(
    private companiesService: CompaniesStoreService,
    private messagesService: MessageService,
    private cdr: ChangeDetectorRef,
    private auth: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.companiesService
      .getActiveCompany()
      .pipe(takeUntil(this.destroy$))
      .subscribe((company) => {
        this.active = company.id;
        this.cdr.markForCheck();
      });
    this.companiesService
      .getCompanies()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
    this.companiesService
      .selectCompanies()
      .pipe(takeUntil(this.destroy$))
      .subscribe((companies) => {
        this.companies = [...companies].sort(
          (a: Company, b: Company) => a.name.localeCompare(b.name) as number,
        );
        this.cdr.markForCheck();
      });
    this.auth
      .getIdentity()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user && this.company && !this.company.email) {
          this.company.email = user.username;
          this.cdr.markForCheck();
        }
      });

    this.companiesService
      .error()
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        this.messagesService.clear();
        this.messagesService.addAll([
          {
            severity: 'error',
            detail: error.error?.message || 'Something goes wrong',
          },
        ]);
        this.processing$.next(false);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showAddCompany(): void {
    this.addCompanyDialogVisible = true;
  }

  onFormInit(form: Form): void {
    this.companyForm = form;
  }

  onCancel(): void {
    const email = this.company?.email;
    this.company = {} as Company;
    this.logoFile = null;
    this.clearImage = false;
    this.cdr.detectChanges();
    this.company = {
      email,
    } as Company;
    this.companyForm.reset(this.company);
    this.logoFile = null;
    this.clearImage = true;
    this.addCompanyDialogVisible = false;
    this.cdr.detectChanges();
  }

  onLogoSelected(file: File): void {
    this.logoFile = file;
  }

  onActiveCompanyChange(company: Company): void {
    this.companiesService
      .changeActiveCompany(company)
      .pipe(take(1))
      .subscribe((active) => {
        this.active = active.id;
        this.cdr.markForCheck();
      });
  }

  onSubmit(): void {
    this.companyForm.validate();
    this.cdr.detectChanges();
    if (this.formValid && this.logoFile) {
      this.processing$.next(true);
      this.companiesService
        .createCompany(
          {
            email: this.company?.email as string,
            name: this.company?.name as string,
            description: this.company?.description as string,
            logoUrl: '',
          } as CreateCompanyRequest,
          this.logoFile,
        )
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe((company) => {
          this.processing$.next(false);
          this.messagesService.addAll([
            {
              severity: 'success',
              detail:
                "Company '" + company.name + "' has been successfully created",
            },
          ]);
          this.onCancel();
        });
    }
  }
}
