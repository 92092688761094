import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from '@store/auth/auth.state';
import { AuthStoreService } from '@store/auth/auth-store.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, CoreModule, NgxsModule.forFeature([AuthState])],
  providers: [AuthState, AuthStoreService],
})
export class AuthStoreModule {}
