import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of, from, Observable } from 'rxjs';
import { LoginResponse } from '@core/models/login-response.model';
import { environment } from '@env/environment';
import { User } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  // fakeTokens: LoginResponse = {
  //   token:
  //     'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbkBmeWVvLmNvbSIsInZlcmlmaWVkIjp0cnVlLCJleHAiOjE2MjQxNDU1MDYsImlhdCI6MTYyNDEzODMwNiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9BRE1JTiJdfQ.4NPHctJ-5zZuctooUDAcdaiPZRq-7vaJtbH7Kb0sYGt_ZOR5QlQrO447vPIk2Rp_mcDu5GpOcWZwDls-o0CIoA',
  //   // accessToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MTczOTg5ODYsImV4cCI6MTYxNzQ4NTM4Niwic3ViIjoiMGJmNDNiMDctNjI0ZS00NGZlLWFiNDctMzcyZGU5NjVkMDNjIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoidGVzdEB0ZXN0LmNvbSIsImF2YXRhciI6Ii9hc3NldHMvaW1hZ2VzL2F2YXRhci5wbmciLCJkaXNwbGF5TmFtZSI6IlRlc3QgVXNlciIsInBlcm1pc3Npb25zIjpbIkNPTU1PTl9WSUVXIiwiQ09NTU9OX01PRElGWSIsIlNZU1RFTV9TRVRUSU5HU19WSUVXIiwiU1lTVEVNX1NFVFRJTkdTX01PRElGWSIsIlNZU1RFTV9VU0VSU19WSUVXIiwiU1lTVEVNX1VTRVJTX01PRElGWSIsIlNZU1RFTV9QRVJNSVNTSU9OU19WSUVXIiwiU1lTVEVNX1BFUk1JU1NJT05TX01PRElGWSIsIlVTRVJfUFJPRklMRV9WSUVXIiwiVVNFUl9QUk9GSUxFX01PRElGWSJdfQ.A7nwaW81OWQV94akDcvX81NTc34kRxsRSPTb-4JSqgeoAbgJpI7U-uoYRf-juuUAehuUIN-pQPDhcRYpPAywCPKI5qIzxJEn4V4YMY-yaGpVTvgtYmKiZ_allY8QsWNFqBxv3IR8DmSAR-4Y4q6JyJyfpUEDBihdOErhWKqHFPYNPtVbFIkrm6VFuP5BOgDHSU1ZRys7NeB3NxAegcfj-mCadHuKlmlkahISGploX4IGkJQxOChsd3_6-0IM2XF_8SssUZwXymNaI46lgDQCS8Er4SWSdTvkhFnYISD-tT6UYdMEL482BW7zIrhwK5GrRnb5KIlznILROsiSa_zn620ickVVzy5MDCbAsI9jMhqBp9QrumwpdCcU3X1zUVA2eHfYPxR2ajvOyqRo-ZLVAJVfVDMEvmn776QJreI2zTohGAlfgO5qIfVKrvSYX9aCfllbVZF4lRtGTIECKoQcBiL9PeUSff_7avufzCUM2skAZBkPi5oAu2oh4DOtrL1o5rrAKVCoB564IedTrJl6NAWnPOCgFq0VLAmN2hy6HhhB3HoDHuoy6nxLQK7Ba_JBlr1wYNazFNBYyRXRIFAYLXjLrlhnAKSkTAFtH-AG-nWgUQKuinZ-17uYUCZsEUcpBMkh_h-Eo8MvYBTsns3jAle-d_8QhHby7ZrzYyvP4L8',
  //   // refreshToken: 'asdasdasd',
  // };

  constructor(private http: HttpClient) {}

  loginByCredentials(
    username: string,
    password: string,
  ): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      environment.API_DOMAIN + '/auth/login',
      {
        username,
        password,
      },
    );
    // return of(this.fakeTokens);
  }

  loginBySocialProvider(idToken: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      environment.API_DOMAIN + '/auth/social-login',
      {
        idToken,
      },
    );
  }

  refreshToken(refreshToken: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      environment.API_DOMAIN + '/auth/refresh-token',
      {
        refreshToken,
      },
    );
  }

  getUserInfo(): Observable<User> {
    return this.http.get<User>(environment.API_DOMAIN + '/auth/info');
  }

  confirmEmail(token: string): Observable<void> {
    return this.http.get<void>(
      environment.API_DOMAIN + '/auth/confirm-email/' + token,
    );
  }

  sendRestorePassword(email: string): Observable<void> {
    return this.http.post<void>(
      environment.API_DOMAIN + '/auth/send-restore-password',
      {
        email,
      },
    );
  }

  checkRestorePasswordToken(token: string): Observable<void> {
    return this.http.get<void>(
      environment.API_DOMAIN + '/auth/restore-password/' + token,
    );
  }

  restorePassword(
    token: string,
    password: string,
    confirmPassword: string,
  ): Observable<void> {
    return this.http.post<void>(
      environment.API_DOMAIN + '/auth/restore-password/',
      {
        token,
        password,
        confirmPassword,
      },
    );
  }
}
