import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
import { PERMISSIONS } from '@core/constants/permissions.constants';
import { CompaniesStoreService } from '@store/companies/companies-store.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { firstValueFrom, lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class MenuService {
  constructor(
    private permissionsService: NgxPermissionsService,
    private companiesService: CompaniesStoreService,
  ) {}

  private menu$: ReplaySubject<MenuItem[]> = new ReplaySubject(1);

  async loadUserMenu(): Promise<MenuItem[]> {
    const common: boolean = await this.permissionsService.hasPermission(
      PERMISSIONS.COMMON_VIEW,
    );
    let menu: MenuItem[] = [];
    if (common) {
      menu = [
        // {
        //   label: 'Notifications',
        //   icon: 'pi pi-fw pi-bell',
        //   routerLink: '/user/notifications',
        // },
        // {
        //   label: 'Settings',
        //   icon: 'pi pi-fw pi-cog',
        //   routerLink: '/user/settings',
        // },
      ];
    }
    return menu;
  }

  loadMenu(): Observable<MenuItem[]> {
    const activeCompany$ = this.companiesService.isActiveCompanySelected();
    return activeCompany$.pipe(
      switchMap((val) => this.initMenu(val)),
      tap((items) => this.menu$.next(items)),
    );
  }

  onMenuReady(): Observable<MenuItem[]> {
    return this.menu$.asObservable();
  }

  updateBadge(id: string, bage: string): void {
    const activeCompany$ = this.companiesService.isActiveCompanySelected();
    activeCompany$
      .pipe(
        switchMap((val) => this.initMenu(val)),
        take(1),
      )
      .subscribe((menu) => {
        const res = menu.map((root) => {
          const found = root.items?.find((item) => item.id === id);
          if (found) {
            found.badge = bage;
          }
          return {
            ...root,
          };
        });
        this.menu$.next({ ...menu });
      });
  }

  private initMenu(activeCompany: boolean): Observable<MenuItem[]> {
    const menu: MenuItem[] = [];
    const common: Promise<boolean> = this.permissionsService.hasPermission(
      PERMISSIONS.COMPANY_COMMON_VIEW,
    );
    const companyAdmin: Promise<boolean> =
      this.permissionsService.hasPermission([
        PERMISSIONS.COMPANY_COMMON_MODIFY,
        PERMISSIONS.ACCOUNT_MODIFY,
      ]);
    const admin: Promise<boolean> = this.permissionsService.hasPermission(
      PERMISSIONS.SYSTEM_ADMINISTRATION_VIEW,
    );

    const permissons$: Observable<boolean[]> = fromPromise(
      Promise.all([common, companyAdmin, admin]),
    );

    return permissons$.pipe(
      map(([common, companyAdmin, admin]) => {
        if (activeCompany) {
          if (common) {
            const general = {
              label: 'General',
              items: [
                {
                  id: 'dashboard',
                  label: 'Dashboard',
                  icon: 'pi pi-fw pi-home',
                  routerLink: '/dashboard',
                },
                {
                  id: 'requests',
                  label: 'Outgoing Requests',
                  icon: 'pi pi-fw pi-envelope',
                  routerLink: '/requests/outgoing',
                },
                {
                  id: 'pending_documents',
                  label: 'Pending Documents',
                  icon: 'pi pi-fw pi-file-import',
                  routerLink: '/requests/pending',
                },
                {
                  id: 'shared_documents',
                  label: 'Shared Documents',
                  icon: 'pi pi-fw pi-share-alt',
                  routerLink: '/shared-documents',
                },
                {
                  id: 'documents',
                  label: 'Documents',
                  icon: 'pi pi-fw pi-file',
                  routerLink: '/documents',
                },
              ],
            };

            menu.push(general);
          }
          if (companyAdmin) {
            const management: MenuItem = {
              label: 'Company Management',
              items: [],
            };
            menu.push(management);

            if (management.items) {
              management.items.push({
                label: 'Company Info',
                icon: 'pi pi-fw pi-id-card',
                routerLink: '/company/info',
              });
              // management.items.push({
              //   label: 'Users',
              //   icon: 'pi pi-fw pi-users',
              //   routerLink: '/company/users',
              // });
              management.items.push({
                label: 'Contacts',
                icon: 'pi pi-fw pi-users',
                routerLink: '/company/contacts',
              });
              management.items.push({
                label: 'Categories',
                icon: 'pi pi-fw pi-th-large',
                routerLink: '/company/categories',
              });
            }
          }
        } else {
          const general = {
            label: 'General',
            items: [
              {
                label: 'Add Company',
                icon: 'pi pi-fw pi-id-card',
                routerLink: '/company/add',
              },
            ],
          };
          menu.push(general);
        }
        if (admin) {
          const home = {
            items: [
              {
                label: 'System Dashboard',
                icon: 'pi pi-fw pi-home',
                routerLink: '/admin/dashboard',
              },
            ],
          };
          menu.push(home);

          const management: MenuItem = {
            label: 'System Management',
            items: [],
          };
          menu.push(management);

          if (management.items) {
            management.items.push({
              label: 'Accounts',
              icon: 'pi pi-fw pi-id-card',
              routerLink: '/admin/accounts',
            });
            management.items.push({
              label: 'Users',
              icon: 'pi pi-fw pi-users',
              routerLink: '/admin/users',
            });

            management.items.push({
              label: 'Permissions',
              icon: 'pi pi-fw pi-shield',
              routerLink: '/admin/permissions',
            });
          }
        }
        return menu;
      }),
    );
  }
}
