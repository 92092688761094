import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { CustomValidators } from '@common/shared/utils/validation/custom-validators';
import { FormHelper } from '@common/shared/helpers/form-helper';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterFormComponent implements OnInit, OnDestroy {
  @Input() title = 'Create Account';
  @Input() buttonText = 'Create';
  @Input() processing: boolean | undefined;

  @Output() create: EventEmitter<any> = new EventEmitter();
  @Output() createWithGoogle: EventEmitter<any> = new EventEmitter();
  @Output() createWithFacebook: EventEmitter<any> = new EventEmitter();

  form!: FormGroup;

  private destroy$ = new Subject<void>();

  strongPasswordRegex =
    '^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\\-__+.]){1,}).{8,}$';

  constructor(
    private fb: FormBuilder,
    private logger: NGXLogger,
    private router: Router,
    private formHelper: FormHelper,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email]),
      firstName: this.fb.control('', [Validators.required]),
      lastName: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [
        Validators.required,
        CustomValidators.hasSmallCaseCharacter,
        CustomValidators.hasNumberValidator,
        CustomValidators.hasCapitalCaseCharacter,
        CustomValidators.hasSpecialCharacter,
        Validators.minLength(8),
      ]),
      confirmPassword: this.fb.control('', [
        Validators.required,
        CustomValidators.passwordMatchValidator,
      ]),
    });
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.formHelper.clearValidation(this.form);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onGoogleSubmit(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.createWithGoogle.emit();
  }

  onSubmit(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this?.form?.valid) {
      this.formHelper.validate(this.form);
      this.cdr.detectChanges();
      return;
    }

    this.create.emit(this.form.value);
  }

  navigateToLogin(): void {
    this.router.navigate(['/auth/login']);
  }
}
