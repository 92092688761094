import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Nullable } from '@core/interfaces/nullable';
import {
  CheckSharingProgressAction,
  SaveSharingProgressAction,
  SaveSharingProgressCompleteAction,
} from '@store/sharing-progress/sharing-progress.actions';
import { DocumentsService } from '@core/services/api/documents.service';

export interface SharingProgress {
  taskId: string;
  total: number;
  delivered: number;
  sent: number;
  failed: number;
  status: string;
  startTime: string;
  document?: {
    id: string;
    name: string;
  };
}

export interface SharingProgressStateModel {
  progress: Nullable<SharingProgress[]>;
}

@State<SharingProgressStateModel>({
  name: 'sharing_progress',
  defaults: {
    progress: null,
  },
})
@Injectable()
export class SharingProgressState {
  @Selector()
  static getState(state: SharingProgressStateModel): SharingProgressStateModel {
    return state;
  }

  static getSharingProgress(state: any): Nullable<SharingProgress[]> {
    return state.sharing_progress?.progress || null;
  }

  constructor(private documentsService: DocumentsService) {}

  @Action(SaveSharingProgressAction)
  saveProgress(
    ctx: StateContext<SharingProgressStateModel>,
    action: SaveSharingProgressAction,
  ) {
    const progress = ctx.getState().progress;
    const tasks = progress?.filter((t) => t.taskId !== action.taskId);
    ctx.patchState({
      progress: [
        ...(tasks || []),
        {
          taskId: action.taskId,
          ...action.payload,
        },
      ].sort((a, b) => b.startTime.localeCompare(a.startTime)),
    });
  }

  @Action(SaveSharingProgressCompleteAction)
  complete(
    ctx: StateContext<SharingProgressStateModel>,
    action: SaveSharingProgressCompleteAction,
  ) {
    let progress = ctx.getState().progress || [];
    const tasks = progress?.filter((t) => t.taskId !== action.taskId);
    progress = [...tasks];
    ctx.patchState({
      progress,
    });
  }

  @Action(CheckSharingProgressAction)
  checkProgress(
    ctx: StateContext<SharingProgressStateModel>,
    action: CheckSharingProgressAction,
  ) {
    return this.documentsService.checkSharingProgressStatus(
      action.companyId,
      action.tasks,
    );
  }
}
