import { NgModule, Type } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkScrollableModule } from '@angular/cdk/scrolling';

const modules: Array<Type<any>> = [
  CdkTableModule,
  DragDropModule,
  CdkMenuModule,
  OverlayModule,
  CdkScrollableModule,
];

@NgModule({
  exports: [...modules],
})
export class AngularCdkModule {}
