import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from '@common/auth/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService],
})
export class LoginComponent implements OnInit {
  processing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  errors: any = [];

  constructor(
    private authService: AuthenticationService,
    private logger: NGXLogger,
    private router: Router,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  onLogin(userCreds: { email: string; password: string }): void {
    this.processing$.next(true);
    this.cdr.detectChanges();
    this.authService.login(userCreds.email, userCreds.password).subscribe({
      next: (user) => {
        this.logger.debug('onLogin: ', user);
        this.router.navigate([this.authService.getInterruptedUrl()]);
        this.processing$.next(false);
      },
      error: (e) => {
        const msg = e.error?.message || 'Invalid credentials provided';
        this.processing$.next(false);
        this.messageService.addAll([
          {
            severity: 'error',
            detail: msg,
          },
        ]);
        this.logger.error('LoginComponent.onLogin error', e);
      },
    });
  }

  onLoginWithGoogle(): void {
    this.authService.loginWithGoogle().subscribe({
      next: (user) => {
        this.logger.debug('onLoginWithGoogle: ', user);
        this.router.navigate([this.authService.getInterruptedUrl()]);
        this.processing$.next(false);
      },
      error: (e) => {
        this.processing$.next(false);
        this.messageService.addAll([
          {
            severity: 'error',
            detail: 'Cannot find related user',
          },
        ]);
        this.logger.error('LoginComponent.onLoginWithGoogle error', e);
      },
    });
  }
}
