import { Nullable } from '@core/interfaces/nullable';
import { CreateDocumentShare, SharedDocument } from '@core/models';

export class GetSharedByCompany {
  static readonly type = '[SHARED DOCUMENTS] Get Shared By Company';

  constructor(
    public payload: {
      companyId: string;
      page: number;
      pageSize: number;
      sortBy?: string;
      order?: string;
      where?: Nullable<Record<string, string>>;
    },
  ) {}
}

export class GetSharedByClients {
  static readonly type = '[SHARED DOCUMENTS] Get Shared By Contacts';

  constructor(
    public payload: {
      companyId: string;
      page: number;
      pageSize: number;
      sortBy?: string;
      order?: string;
      where?: Nullable<Record<string, string>>;
    },
  ) {}
}

export class GetSharedByDocument {
  static readonly type = '[SHARED DOCUMENTS] Get Shared By Document';

  constructor(
    public payload: {
      companyId: string;
      documentId: string;
      page: number;
      pageSize: number;
      sortBy?: string;
      order?: string;
      where?: Nullable<Record<string, string>>;
    },
  ) {}
}

export class ShareDocument {
  static readonly type = '[SHARED DOCUMENTS] Share Document';

  constructor(public companyId: string, public payload: CreateDocumentShare) {}
}

export class DocumentShared {
  static readonly type = '[SHARED DOCUMENTS] Document Shared';

  constructor(public companyId: string) {}
}

export class RevokeDocumentAccess {
  static readonly type = '[SHARED DOCUMENTS] Revoke Document';

  constructor(public companyId: string, public id: string) {}
}

export class CanBeShared {
  static readonly type = '[SHARED DOCUMENTS] Can Be Shared';

  constructor(
    public companyId: string,
    public documentId: string,
    public email: string,
  ) {}
}

export class DocumentAccessRevoked {
  static readonly type = '[SHARED DOCUMENTS] Document Access Revoked';

  constructor(public companyId: string, public payload: SharedDocument) {}
}

export class ClearSharedDocumentsErrors {
  static readonly type = '[SHARED DOCUMENTS] Documents Clear Errors';
}
