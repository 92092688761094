import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from '@common/shared/utils/validation/custom-validators';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordFormComponent implements OnInit {
  @Input() title = 'Restore Password';
  @Input() buttonText = 'Change';
  @Input() processing: boolean | undefined;
  @Output() restorePassword: EventEmitter<any> = new EventEmitter();

  form!: FormGroup;

  constructor(private fb: FormBuilder, private router: Router) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      password: this.fb.control('', [
        Validators.required,
        CustomValidators.hasSmallCaseCharacter,
        CustomValidators.hasNumberValidator,
        CustomValidators.hasCapitalCaseCharacter,
        CustomValidators.hasSpecialCharacter,
        Validators.minLength(8),
      ]),
      confirmPassword: this.fb.control('', [
        Validators.required,
        CustomValidators.passwordMatchValidator,
      ]),
    });
  }

  onSubmit(): void {
    if (!this?.form?.valid) {
      return;
    }

    this.restorePassword.emit(this.form.value);
  }

  navigateToLogin(): void {
    this.router.navigate(['/auth/login']);
  }
}
