import { Inject, Injectable } from '@angular/core';
import {
  AUTH_SERVICE,
  AuthService,
  ProtectedGuard,
  PUBLIC_FALLBACK_PAGE_URI,
} from 'ngx-auth';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { switchMap, take, tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { User } from '@core/models/user.model';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '@common/auth/services/authentication.service';
import { Nullable } from '@core/interfaces/nullable';

@Injectable()
export class AuthGuard extends ProtectedGuard {
  constructor(
    private permissionsService: NgxPermissionsService,
    @Inject(AUTH_SERVICE) private authenticationService: AuthService,
    @Inject(PUBLIC_FALLBACK_PAGE_URI) fallbackUrl: string,
    @Inject(DOCUMENT) document: Document,
    router: Router,
  ) {
    super(authenticationService, fallbackUrl, document, router);
  }

  override canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return super.canActivate(route, state).pipe(
      tap(() => {
        (this.authenticationService as AuthenticationService)
          .getIdentity()
          .pipe(take(1))
          .subscribe((user: Nullable<User>) => {
            if (user) {
              this.permissionsService.loadPermissions(
                user.permissions || ['COMMON_VIEW'],
              );
            }
          });
      }),
    );
  }

  override canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
