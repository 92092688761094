<p-dialog
  modal="true"
  position="right"
  [draggable]="false"
  [focusOnShow]="true"
  [closeOnEscape]="false"
  header="Share Document"
  [style]="{ width: '450px', height: '100vw' }"
  [closable]="true"
  [(visible)]="show"
  (onHide)="onCancel()"
>
  <div>
    <input type="hidden" [ngModel]="document?.id" />
  </div>
  <div class="documents-list field m-2 mb-3 p-field">
    <p-dropdown
      [options]="documents$ | async"
      [disabled]="disabled"
      [(ngModel)]="document"
      [lazy]="true"
      [filter]="true"
      [filterBy]="'name'"
      [group]="true"
      (ngModelChange)="documentChange.emit($event)"
      (onFilter)="onSearch($event)"
      (onLazyLoad)="onLazyLoad($event)"
      dataKey="id"
      placeholder="Select Document"
    >
      <ng-template pTemplate="loader">
        <div class="flex align-items-center py-2 px-3" style="height: 38px">
          <i class="pi pi-spinner pi-spin"></i>
        </div>
      </ng-template>
      <ng-template let-group pTemplate="group">
        <div class="flex align-items-center">
          <i class="pi pi-folder mr-2"></i>
          <span>{{ group.label }}</span>
        </div>
      </ng-template>
      <ng-template let-doc pTemplate="item">
        <app-file-info
          [inline]="true"
          [outline]="false"
          [file]="doc?.file"
          [closable]="false"
        ></app-file-info>
      </ng-template>
      <ng-template let-doc pTemplate="selectedItem">
        <app-file-info
          [outline]="false"
          [file]="doc?.file"
          [closable]="false"
        ></app-file-info>
      </ng-template>
    </p-dropdown>
  </div>
  <app-share-document-form
    #form
    [(ngModel)]="shareModel"
    [disabled]="!!(processing$ | async)"
    [categories]="categories"
    [contacts]="contacts"
    [groups]="groups"
    (formInitialized)="onFormInit($event)"
    (ngModelChange)="shareModelChange.emit($event)"
    (createContactOrGroup)="onCreateNewContact($event)"
  ></app-share-document-form>

  <ng-template pTemplate="footer">
    <button
      (click)="onCancel()"
      pButton
      pRipple
      class="p-button-outlined"
      label="Cancel"
    ></button>
    <button
      [disabled]="processing$ | async"
      pButton
      pRipple
      class=""
      iconPos="left"
      [icon]="(processing$ | async) ? 'pi pi-spin pi-spinner' : 'pi pi-upload'"
      label="Share"
      (click)="onApply()"
    ></button>
  </ng-template>
</p-dialog>
