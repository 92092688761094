import {
  ContactGroup,
  CreateContactGroup,
  UpdateContactGroupDto,
} from '@core/models';

export class GetContactGroups {
  static readonly type = '[CONTACT GROUPS] Get Groups';

  constructor(public companyId: string) {}
}

export class ContactGroupsLoaded {
  static readonly type = '[CONTACT GROUPS] Groups Loaded';

  constructor(public payload: ContactGroup[]) {}
}

export class GetGroupCountersAction {
  static readonly type = '[CONTACT GROUPS] Get Group Counters';

  constructor(public companyId: string) {}
}

export class AddContactGroup {
  static readonly type = '[CONTACT GROUPS] Create Contact';

  constructor(public companyId: string, public payload: CreateContactGroup) {}
}

export class ContactGroupCreated {
  static readonly type = '[CONTACT GROUPS] Group Created';

  constructor(public companyId: string, public payload: ContactGroup) {}
}

export class ContactGroupUpdated {
  static readonly type = '[CONTACT GROUPS] Group Updated';

  constructor(public companyId: string, public payload: ContactGroup) {}
}

export class UpdateContactGroup {
  static readonly type = '[CONTACT GROUPS] Update Group';

  constructor(
    public id: string,
    public companyId: string,
    public payload: UpdateContactGroupDto,
  ) {}
}

export class DeleteContactGroup {
  static readonly type = '[CONTACT GROUPS] Delete Group';

  constructor(public id: string, public companyId: string) {}
}

export class ClearContactGroupsErrors {
  static readonly type = '[CONTACT GROUPS] Clear Groups Errors';

  constructor() {}
}
