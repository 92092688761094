<div class="content-wrapper" [hidden]="(loading$ | async) === true">
  <ng2-pdfjs-viewer
    #pdfViewer
    [download]="false"
    zoom="page-width"
    [openFile]="false"
    [print]="false"
    (onDocumentLoad)="onLoaded($event)"
  ></ng2-pdfjs-viewer>
  <div [hidden]="!imageSrc" class="img-wrapper">
    <img #imageViewer />
  </div>
  <div *ngIf="message" class="no-preview-message">{{ message }}</div>
</div>
<div *ngIf="loading$ | async" class="loading">
  <i class="pi pi-spinner pi-spin"></i>
</div>
