import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseFormDirective } from '@ui/forms/base-form.component';
import { Validators } from '@angular/forms';
import { UpdateContactGroupDto } from '@core/models';
import { Contact } from '@core/models/contact.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-contact-group-form',
  templateUrl: './add-contact-group-form.component.html',
  styleUrls: ['./add-contact-group-form.component.scss'],
})
export class AddContactGroupFormComponent extends BaseFormDirective<UpdateContactGroupDto> {
  @Input() clients!: Contact[];

  selectedClients!: Contact[];

  override initForm(model?: UpdateContactGroupDto): void {
    this.form = this.fb.group({
      id: this.fb.control(model?.id),
      name: this.fb.control(model?.name, [Validators.required]),
      clients: this.fb.control(model?.clients || [], []),
    });
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => this.onChanged(value));
  }

  onChanged(value: { clients: string[] }): void {
    this.selectedClients = (value?.clients || [])
      .map((id) => this.clients?.find((c) => c.id === id))
      .filter((s) => !!s) as Contact[];
    this.cdr.detectChanges();
  }

  trackById(item: any): any {
    return item.id;
  }

  onDeselect(id: string): void {
    this.form.patchValue({
      clients: this.form.get('clients')?.value?.filter((v: string) => v !== id),
    });
  }
}
