import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CellActionsOption, TableRowData } from '../../../interfaces';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@Component({
  selector: 'app-actions-cell-renderer',
  templateUrl: './actions-cell-renderer.component.html',
  styleUrls: ['./actions-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<any, R>
  implements OnInit
{
  actions: Array<CellActionsOption<R>> = [];

  ngOnInit(): void {
    if (
      this.getOptions()['actions'] &&
      this.getOptions()['actions'] instanceof Array
    ) {
      this.actions = this.getOptions()['actions'] as Array<
        CellActionsOption<R>
      >;
    }
  }

  override render(): string {
    return '';
  }

  onAction(action: CellActionsOption<R>, event: MouseEvent): void {
    if (typeof action.action === 'function') {
      action.action.call(this, this.getRow(), event);
    }
  }

  icon(action: CellActionsOption<R>): string {
    if (typeof action.icon === 'string') {
      return action.icon;
    }
    if (typeof action.icon === 'function') {
      return action.icon.call(action, this.getRow() as R);
    }
    return '';
  }
  disabled(action: CellActionsOption<R>): boolean {
    if (typeof action.disabled === 'boolean') {
      return action.disabled;
    }
    if (typeof action.disabled === 'function') {
      return action.disabled.call(action, this.getRow() as R);
    }
    return false;
  }

  color(action: CellActionsOption<R>): string {
    if (typeof action.color === 'string') {
      return action.color;
    }
    if (typeof action.color === 'function') {
      return action.color.call(action, this.getRow() as R);
    }
    return '';
  }

  tooltip(action: CellActionsOption<R>): string {
    if (typeof action.tooltip === 'string') {
      return action.tooltip;
    }
    if (typeof action.tooltip === 'function') {
      return action.tooltip.call(action, this.getRow() as R);
    }
    return '';
  }
}
