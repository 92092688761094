<div
  class="uploader-container field mb-3"
  *ngIf="(processing$ | async) === false"
  [class.disabled]="disabled"
  (drop)="dropHandler($event)"
>
  <input
    #input
    type="file"
    [disabled]="disabled"
    [multiple]="true"
    [readonly]="disabled"
    [class.disabled]="disabled"
    (change)="onSelect($event)"
    [accept]="acceptTypes"
  />
  <i class="pi pi-upload"></i>
  <div class="empty-placeholder">
    <div>Click to upload or drag and drop file here</div>
    <span class="desc"
      >Upload limit: Max {{ MAX_UPLOAD_SIZE | filesize }} per file, up to
      {{ filesLimit }} files allowed.</span
    >
  </div>
</div>
<div *ngIf="files?.length > 0" class="files-list mt-3">
  <div *ngFor="let file of files; let i = index" class="mb-3">
    <app-file-info
      [file]="file"
      [src]="fileSrc(i)"
      [progress]="progressForFile(i)"
      [name]="names[i]"
      [editable]="true"
      [disabled]="disabled"
      (edited)="onNameEdit($event, i)"
      (closed)="onFileUnselected(i)"
    ></app-file-info>
  </div>
</div>
