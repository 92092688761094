import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  ColumnDefinition,
  ColumnSortEvent,
  DataTableConfig,
  DataTableRowChangesEvent,
  PaginationEvent,
} from './interfaces';
import { TableImplementation } from './enums/table-implementation';
import { CellType } from './enums/cell-type';
import {
  DefaultDataTableConfig,
  DefaultExpandCellOptions,
} from './constants/defaults';
import {
  ActionsCellRendererComponent,
  BooleanCellRendererComponent,
  CheckboxCellRendererComponent,
  DatetimeCellRendererComponent,
  DefaultCellRendererComponent,
  EditableCellRendererComponent,
  FormattedNumberCellRendererComponent,
  FormattedStringComponent,
  HumanizeStatusCellRendererComponent,
  IconCellRendererComponent,
  ImageCellRendererComponent,
  PropertyCellRendererComponent,
  RouterLinkCellRendererComponent,
  TimeDurationCellRendererComponent,
} from './components/renderers';
import { FileSizeCellRendererComponent } from '@ui/components/data-table/components/renderers/file-size-cell-renderer/file-size-cell-renderer.component';
import { DataSource } from '@ui/components/data-table/interfaces/data-source';
import { LazyLoadEvent } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { Nullable } from '@core/interfaces/nullable';
import { FilterMetadata } from 'primeng/api/filtermetadata';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @typescript-eslint/ban-types
export class DataTableComponent<T extends object>
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('expandCell', { read: TemplateRef }) expandCellTpl:
    | TemplateRef<any>
    | undefined;

  @Input() contextMenu!: ContextMenu;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('columns') set setColumns(columns: Array<ColumnDefinition>) {
    if (columns) {
      // setup default render options for cells
      this.columns = columns.map((column) => {
        const col = { ...column };
        if (!col.cellOptions) {
          col.cellOptions = {
            renderType: 'component',
            type: CellType.Default,
          };
        }
        if (col.cellOptions.type) {
          col.cellOptions.renderType = 'component';
          col.cellOptions.component = this.getRendererType(
            col.cellOptions.type,
          );
        } else if (
          !col.cellOptions.type &&
          col.cellOptions.renderType &&
          col.cellOptions.renderType === 'component' &&
          !col.cellOptions.component
        ) {
          col.cellOptions.component = DefaultCellRendererComponent;
        }
        if (
          !!col.cellOptions.component &&
          col.cellOptions.renderType !== 'component'
        ) {
          col.cellOptions.renderType = 'component';
        }
        return col;
      });

      // insert the cell with expand trigger button as a first column
      // when expandableRows is enabled
      combineLatest([this.configReady$, this.viewReady$])
        .pipe(take(1))
        .subscribe(() => {
          if (
            this.config?.expandableRows &&
            columns[0] &&
            columns[0].id !== 'expand'
          ) {
            this.columns.unshift({
              ...DefaultExpandCellOptions(this.expandCellTpl),
            });
          }
          this.cdr.detectChanges();
        });
    }
  }

  @Input() data: Array<T> = [];
  @Input() set dataSource(ds: DataSource<T>) {
    if (ds && !this.dataSourceValue) {
      this.dataSourceValue = ds;
      this.dataSourceValue
        .onLoad()
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.data = [...data.items];
          this.totalRows = data.meta.totalItems;
          this.loading$.next(false);
          this.cdr.detectChanges();
        });
    }
  }

  get dataSource(): DataSource<T> {
    return this.dataSourceValue;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('config') set setConfig(config: DataTableConfig) {
    if (config) {
      this.config = { ...config };
      this.configReady$.next();
    } else {
      this.config = undefined;
    }
  }

  @Input() initialFilter!: Record<string, string>;
  @Input() initialSorting!: Record<string, number>;

  @Output() loaded: EventEmitter<boolean> = new EventEmitter();
  @Output() columnsUpdated: EventEmitter<Array<ColumnDefinition>> =
    new EventEmitter();
  @Output() sorted: EventEmitter<ColumnSortEvent> = new EventEmitter();
  @Output() paginated: EventEmitter<PaginationEvent> = new EventEmitter();
  @Output() ready: EventEmitter<any> = new EventEmitter();
  @Output() filtered: EventEmitter<T[]> = new EventEmitter();
  @Output() rowEdited: EventEmitter<T> = new EventEmitter();
  @Output() editRowCanceled: EventEmitter<T> = new EventEmitter();
  @Input() selectedRow!: T;
  @Output() selectedRowChange = new EventEmitter<T>();

  implementations = TableImplementation;
  columns: Array<ColumnDefinition> = [];
  config: DataTableConfig | undefined = DefaultDataTableConfig;
  loading$ = new BehaviorSubject<boolean>(false);
  totalRows!: number;

  private dataSourceValue!: DataSource<T>;
  private configReady$: ReplaySubject<void> = new ReplaySubject<void>(1);
  private viewReady$: ReplaySubject<void> = new ReplaySubject<void>(1);
  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.viewReady$.next();
    this.viewReady$.complete();
  }

  private getRendererType(cellType: CellType): Type<any> {
    switch (cellType) {
      case CellType.Boolean:
        return BooleanCellRendererComponent;
      case CellType.Icon:
        return IconCellRendererComponent;
      case CellType.Checkbox:
        return CheckboxCellRendererComponent;
      case CellType.Date:
        return DatetimeCellRendererComponent;
      case CellType.TimeDuration:
        return TimeDurationCellRendererComponent;
      case CellType.Actions:
        return ActionsCellRendererComponent;
      case CellType.Image:
        return ImageCellRendererComponent;
      case CellType.FormattedNumber:
        return FormattedNumberCellRendererComponent;
      case CellType.Humanize:
        return HumanizeStatusCellRendererComponent;
      case CellType.FileSize:
        return FileSizeCellRendererComponent;
      case CellType.RouterLink:
        return RouterLinkCellRendererComponent;
      case CellType.DeepProperty:
        return PropertyCellRendererComponent;
      case CellType.Editable:
        return EditableCellRendererComponent;
      case CellType.FormattedString:
        return FormattedStringComponent;
      default:
        return DefaultCellRendererComponent;
    }
  }

  onFiltered(event: T[]): void {
    this.filtered.emit(event);
  }

  onRowChanged(event: DataTableRowChangesEvent<T>): void {
    this.rowEdited.emit(event.row);
  }

  onEditCancel(row: T): void {
    this.editRowCanceled.emit(row);
  }

  onLazyLoad(event: LazyLoadEvent): void {
    this.loading$.next(true);

    if (this.dataSource) {
      const sortField = event.sortField
        ? event.sortField
        : this.initialSorting &&
          Object.entries(this.initialSorting).reduce(
            (acc, [key, val]) => key,
            '',
          );
      const sortOrder = !this.data?.length
        ? this.initialSorting &&
          this.initialSorting[sortField] !== undefined &&
          this.initialSorting[sortField]
        : event.sortOrder;
      const page =
        Math.round((event.first || 0) + (event.rows || 1)) / (event.rows || 1);
      const order = (sortOrder || 1) > 0 ? 'asc' : 'desc';
      const searchVerb: Nullable<string> = event?.filters?.['global']
        ? event?.filters?.['global'].value
        : null;
      const filters: Record<string, string> = this.initialFilter
        ? { ...this.initialFilter }
        : {};
      if (searchVerb) {
        filters['verb'] = searchVerb;
      }
      if (event && event.filters) {
        Object.keys(event.filters).forEach((key) => {
          if (key !== 'global') {
            filters[key] =
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              event.filters[key].value instanceof Array
                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  event.filters[key].value.join(',')
                : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  event.filters[key].value;
          }
        });
      }
      this.dataSource
        .load(page, event.rows || 50, filters, sortField, order)
        .pipe(take(1))
        .subscribe();
    }
  }
}
