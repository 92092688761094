import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { PrimengUiModule } from '@ui/primeng/primeng-ui.module';
import { SharedCommonModule } from '@common/shared/shared-common.module';
import { FormElementsUiModule } from '@ui/forms/elements/form-elements-ui.module';
import { AddCompanyFormComponent } from './components/add-company-form/add-company-form.component';
import { AddDocumentRequestComponent } from './components/add-document-request/add-document-request.component';
import { AddCategoryFormComponent } from './components/add-category-form/add-category-form.component';
import { AddContactFormComponent } from './components/add-contact-form/add-contact-form.component';
import { UploadDocumentFormComponent } from './components/upload-document-form/upload-document-form.component';
import { ShareDocumentFormComponent } from './components/share-document-form/share-document-form.component';
import { AddContactGroupFormComponent } from './components/add-contact-group-form/add-contact-group-form.component';
import { CommonUiComponentsModule } from '@ui/components/common/common-ui-components.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    PrimengUiModule,
    SharedCommonModule,
    FormElementsUiModule,
    CommonUiComponentsModule,
  ],
  exports: [
    AddCompanyFormComponent,
    AddDocumentRequestComponent,
    AddCategoryFormComponent,
    AddContactFormComponent,
    UploadDocumentFormComponent,
    ShareDocumentFormComponent,
    AddContactGroupFormComponent,
  ],
  declarations: [
    AddCompanyFormComponent,
    AddDocumentRequestComponent,
    AddCategoryFormComponent,
    AddContactFormComponent,
    UploadDocumentFormComponent,
    ShareDocumentFormComponent,
    AddContactGroupFormComponent,
  ],
})
export class CompanyFormsModule {}
