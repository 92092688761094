<div *ngIf="config" class="data-table card">
  <p-table
    #dataTable
    dataKey="id"
    [value]="dataValue"
    editMode="row"
    [columns]="columns"
    [paginator]="!config?.disablePagination"
    [resizableColumns]="config?.enableColumnsResizing"
    [responsive]="config?.responsive"
    [rows]="currentPageSize"
    [totalRecords]="totalRows"
    [showCurrentPageReport]="true"
    [expandedRowKeys]="expandedRowKeys"
    [lazy]="lazy"
    rowExpandMode="single"
    [loading]="loading || (loading$ | async)"
    [contextMenu]="contextMenu"
    [selectionMode]="'row'"
    [(contextMenuSelection)]="selectedRow"
    (contextMenuSelectionChange)="selectedRowChange.emit($event)"
    currentPageReportTemplate="{first} - {last} of {totalRecords}"
    styleClass="p-datatable-gridlines"
    cdkDropList
    cdkDropListLockAxis="x"
    cdkDropListOrientation="horizontal"
    [cdkDropListData]="columns"
    (cdkDropListDropped)="dropEnded($event)"
    [rowsPerPageOptions]="pageSizes"
    [globalFilterFields]="globalFilterFields"
    (onFilter)="onFiltered($event)"
    (onLazyLoad)="onLazyLoad($event)"
  >
    <ng-template *ngIf="config?.globalSearch" pTemplate="caption">
      <div class="table-header p-d-flex p-jc-between">
        <p-toolbar>
          <div class="p-toolbar-group-left">
            <ng-container *ngIf="toolbarItems">
              <ng-container *ngFor="let item of toolbarItems">
                <p-button
                  *ngIf="item.icon || item.command"
                  [icon]="item.icon"
                  [pTooltip]="item.label"
                  tooltipPosition="bottom"
                  class="p-mr-2"
                  (click)="item.command && item.command()"
                ></p-button>
                <ng-container *ngIf="isTemplate(item)">
                  <div class="toolbar-item">
                    <ng-container
                      *ngTemplateOutlet="
                        item;
                        context: {
                          table: dataTable,
                          filter: dataTable.filter.bind(dataTable)
                        }
                      "
                    ></ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="enableExport">
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-o"
                (click)="exportCSV()"
                class="p-mr-2"
                pTooltip="Export To CSV"
                tooltipPosition="bottom"
              ></button>
            </ng-container>
          </div>
          <div class="p-toolbar-group-right">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                (input)="
                  dataTable.filterGlobal($event.target.value, 'contains')
                "
                placeholder="Search"
              />
            </span>
          </div>
        </p-toolbar>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let column of columns; let i = index">
          <th
            [pSortableColumnDisabled]="
              !config?.enableSorting || column?.options?.sortable === false
            "
            [pSortableColumn]="column.id"
            pResizableColumn
            [pResizableColumnDisabled]="
              !config?.enableColumnsResizing ||
              column?.options?.resizable === false
            "
            [ngClass]="cellHeaderCssClass(column)"
            [ngStyle]="cellHeaderCssStyles(column)"
            cdkDrag
            [cdkDragDisabled]="!config?.enableColumnsSorting"
            [cdkDragData]="column"
            (cdkDragStarted)="dragStarted($event, i)"
          >
            <div class="cell-header-wrapper">
              <ng-container
                *ngIf="config?.enableFiltering && column?.options?.filterable"
              >
                <p-columnFilter
                  [field]="column.id"
                  matchMode="in"
                  display="menu"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                  [showApplyButton]="true"
                  [showClearButton]="true"
                >
                  <ng-template
                    pTemplate="filter"
                    let-value
                    let-filter="filterCallback"
                  >
                    <p-multiSelect
                      [ngModel]="value"
                      [options]="columnFilterOptions(column) | async"
                      placeholder="{{ column.label }}"
                      [virtualScroll]="true"
                      itemSize="30"
                      optionValue="value"
                      optionLabel="label"
                      (onChange)="filter($event.value)"
                    >
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </ng-container>
              {{ column.label }}
              <p-sortIcon
                *ngIf="
                  config?.enableSorting && column?.options?.sortable !== false
                "
                [field]="column.id"
              ></p-sortIcon>
            </div>
          </th>
        </ng-container>
        <th
          *ngIf="config?.enableRowEdit"
          style="text-align: center; width: 100px"
        ></th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-row
      let-columns="columns"
      let-rowIndex="rowIndex"
      let-expanded="expanded"
      let-editing="editing"
    >
      <tr [pEditableRow]="row" [pContextMenuRow]="row">
        <ng-container *ngFor="let column of columns">
          <td [class.nowrap]="column.nowrap">
            <div
              class="cell-wrapper"
              [ngClass]="cellCssClass(column)"
              [ngStyle]="cellCssStyles(column)"
            >
              <p-cellEditor [ngStyle]="{ width: '100%' }">
                <ng-template pTemplate="input">
                  <input
                    *ngIf="column?.options?.editable"
                    pInputText
                    type="text"
                    [(ngModel)]="row[column.id]"
                    required
                  />
                </ng-template>
                <ng-template pTemplate="output">
                  <ng-container
                    *ngIf="
                      column?.cellOptions?.renderType === 'component' &&
                      !!column?.cellOptions?.component
                    "
                  >
                    <ng-container
                      *ngComponentOutlet="
                        getColumnRenderComponent(column);
                        injector: getCellInfoProviderInjector(
                          column.id,
                          row,
                          column
                        )
                      "
                    ></ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      column?.cellOptions?.renderType === 'template' &&
                      !!column?.cellOptions?.template
                    "
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        getColumnRenderTemplate(column);
                        context: {
                          $implicit: row[column.id],
                          row: row,
                          column: column,
                          expanded: expanded,
                          toggleExpand: toggleExpand.bind(this)
                        }
                      "
                    ></ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      !column.cellOptions ||
                      !column.cellOptions.renderType ||
                      column.cellOptions.renderType === 'default'
                    "
                  >
                    {{ row[column.id] }}
                  </ng-container>
                </ng-template>
              </p-cellEditor>
            </div>
          </td>
        </ng-container>
        <td *ngIf="config?.enableRowEdit" style="text-align: center">
          <button
            *ngIf="!editing"
            pButton
            pRipple
            type="button"
            pInitEditableRow
            icon="pi pi-pencil"
            (click)="onRowEditInit(row)"
            class="p-button-rounded p-button-text"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pSaveEditableRow
            icon="pi pi-check"
            (click)="onRowEditSave(row)"
            class="p-button-rounded p-button-text p-button-success p-mr-2"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pCancelEditableRow
            icon="pi pi-times"
            (click)="onRowEditCancel(row, rowIndex)"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-row>
      <tr>
        <td [attr.colspan]="columns.length">
          <ng-container *ngIf="getExpandCellTemplate()">
            <ng-container
              *ngTemplateOutlet="
                getExpandCellTemplate();
                context: { $implicit: row, row: row }
              "
            ></ng-container>
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td class="empty-wrapper" [attr.colspan]="columns && columns.length">
          No Records Found.
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
