<form
  *ngIf="form"
  [formGroup]="form"
  class="create-document-request-form-content"
>
  <div class="p-2 w-full">
    <app-form-fields-wrapper [showLabels]="true">
      <input *appFormField type="hidden" formControlName="id" />
      <app-contacts-dropdown
        *appFormField="'contacts'; label: 'Requested From'"
        formControlName="contacts"
        [disabled]="disabled"
        [clients]="contacts"
        [groups]="groups"
        (createNew)="onCreateNewContact($event)"
      ></app-contacts-dropdown>

      <div *appFormField="'lifetime'; label: 'Duration'" class="radio-group">
        <div
          *ngFor="let item of lifetimeItems; let i = index"
          class="radio-row"
        >
          <p-radioButton
            name="lifetime"
            formControlName="lifetime"
            [disabled]="disabled"
            [label]="item.label"
            [value]="item.value"
          ></p-radioButton>
        </div>
        <ng-conatainer *ngIf="form.get('lifetime')?.value === 'custom'">
          <div class="pt-2">
            <p-calendar
              #periodRangePicker
              [hideOnDateTimeSelect]="true"
              [readonlyInput]="true"
              [disabled]="disabled"
              selectionMode="single"
              [dateFormat]="viewDateFormat"
              formControlName="lifetimeCustomPeriod"
              [minDate]="minDate"
              [showIcon]="true"
              appendTo="body"
            ></p-calendar>
          </div>
        </ng-conatainer>
      </div>
      <input
        *appFormField="'type'; label: 'Document type'"
        class="w-full mb-1"
        type="text"
        pInputText
        formControlName="type"
      />
      <p-dropdown
        [autofocus]="true"
        [autoDisplayFirst]="false"
        *appFormField="'verification'; label: 'Verification'"
        [disabled]="disabled"
        [options]="documentTypes"
        placeholder="Select type"
        formControlName="verification"
        optionValue="id"
        optionLabel="name"
      ></p-dropdown>

      <ng-container *ngIf="categories">
        <app-categories-dropdown
          *appFormField="'category'; label: 'Category'"
          [disabled]="disabled"
          [placeholder]="'Select category'"
          [categories]="categories"
          formControlName="category"
        ></app-categories-dropdown>
      </ng-container>
      <textarea
        *appFormField="'message'; label: 'Message'"
        formControlName="message"
        [disabled]="disabled"
        [readonly]="disabled"
        type="text"
        placeholder="Enter a message"
        pInputTextarea
        class="w-full mb-1"
      ></textarea>
    </app-form-fields-wrapper>
  </div>
</form>
