import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TableRowData } from '../../../interfaces';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@Component({
  selector: 'app-default-cell-renderer',
  templateUrl: '../base-cell-renderer.component.html',
  styleUrls: ['./default-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultCellRendererComponent<
  R extends TableRowData,
> extends BaseCellRendererComponent<any, R> {
  override render(): string {
    const rawValue = this.getRawValue();
    if (rawValue !== null && rawValue !== undefined) {
      if (typeof rawValue === 'string') {
        return rawValue;
      } else if (typeof rawValue?.toString === 'function') {
        return rawValue.toString();
      } else if (typeof rawValue === 'function') {
        return rawValue.call(this, this.getRow(), this.getOptions());
      }
    }
    return rawValue as string;
  }
}
