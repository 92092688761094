import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TableRowData } from '@ui/components/data-table/interfaces';
import { BaseCellRendererComponent } from '@ui/components/data-table/components/renderers/base-cell-renderer.component';

@Component({
  selector: 'app-formatted-string',
  templateUrl: '../base-cell-renderer.component.html',
  styleUrls: ['./formatted-string.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormattedStringComponent<R extends TableRowData>
  extends BaseCellRendererComponent<string, R>
  implements OnInit
{
  formatter!: (row: R | null) => string;

  ngOnInit(): void {
    if (this.getOptions()['formatter']) {
      this.formatter = this.getOptions()['formatter'] as (
        row: R | null,
      ) => string;
    }
  }

  override render(): string {
    return typeof this.formatter === 'function'
      ? this.formatter.call(this, this.getRow())
      : (this.getRawValue() as string);
  }
}
