import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Table } from 'primeng/table';
import { AbstractDataTableComponent } from '../abstract-data-table.component';
import { ColumnDefinition } from '../../interfaces';
import { LazyLoadEvent } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { Nullable } from '@core/interfaces/nullable';

@Component({
  selector: 'app-prime-data-table',
  templateUrl: './prime-data-table.component.html',
  styleUrls: ['./prime-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @typescript-eslint/ban-types
export class PrimeDataTableComponent<T extends object>
  extends AbstractDataTableComponent<T>
  implements OnInit
{
  @ViewChild('dataTable', { read: Table }) set table(table: Table | undefined) {
    if (table) {
      this.ready.emit(table);
    }
  }

  @Input() contextMenu!: ContextMenu;
  @Input() selectedRow!: T;
  @Input() customSort!: boolean;
  @Input() totalRows!: Nullable<number>;

  @Output() selectedRowChange = new EventEmitter<T>();

  dataValue: Array<T> | undefined;
  expandedRowKeys: { [key: string]: boolean } = {};

  private clonedRows: Record<any, any> = {};

  onFiltered(event: any): void {
    this.filtered.emit(event.filteredValue);
  }

  isTemplate(item: any): boolean {
    return item instanceof TemplateRef;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  override cellCssStyles(column: ColumnDefinition): object {
    const cssStyles: any = super.cellCssStyles(column);
    cssStyles.width = cssStyles.minWidth;
    return cssStyles;
  }

  toggleExpand(row: any): void {
    if (this.expandedRowKeys[row.id]) {
      this.expandedRowKeys[row.id] = false;
    } else {
      this.expandedRowKeys[row.id] = true;
    }

    this.detectChanges();
  }

  onRowEditInit(row: T) {
    this.clonedRows[(row as any).id] = { ...row };
  }

  onRowEditSave(row: any) {
    delete this.clonedRows[row.id];
    this.rowChanged.emit({
      row,
      id: row.id,
    });
  }

  onRowEditCancel(row: any, index: number) {
    Object.keys(row).map((key) => {
      row[key] = this.clonedRows[row.id][key];
    });
    delete this.clonedRows[row.id];
    this.editCanceled.emit(row);
  }

  onLazyLoad(event: LazyLoadEvent): void {
    console.log('lazy load !!!! ', event);
    this.lazyLoad.emit(event);
  }

  protected override init(
    columns: Array<ColumnDefinition>,
    data: Array<T>,
  ): void {
    this.dataValue = data;
    this.detectChanges();
  }
}
