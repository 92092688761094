import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, ReplaySubject, skip } from 'rxjs';
import { SharedDocumentStatus } from '@core/enums/shared-document-status.enum';
import { DocumentOrigin } from '@core/enums/document-origin.enum';
import { Nullable } from '@core/interfaces/nullable';
import { CategoryShort, ContactGroup, SharedDocument } from '@core/models';
import {
  CellActionsOption,
  ColumnDefinition,
  DataTableConfig,
} from '@ui/components/data-table/interfaces';
import { map, take } from 'rxjs/operators';
import { Contact } from '@core/models/contact.model';
import {
  SHARED_BY_COMPANY_DATA_SOURCE,
  SharedByCompanyDataSource,
} from '@pages/protected/shared-documents/services/shared-by-company-data-source';
import { ContactsStoreService } from '@store/contacts/contacts-store.service';
import { CompaniesStoreService } from '@store/companies/companies-store.service';
import { SharedDocumentsStoreService } from '@store/shared-documents/shared-documents-store.service';
import { ContactGroupsStoreService } from '@store/contact-groups/contact-groups-store.service';
import { ConfirmationService, MessageService, PrimeIcons } from 'primeng/api';
import { CellType } from '@ui/components/data-table/enums/cell-type';
import { DEFAULT_DATE_FORMAT } from '@core/constants';
import {
  SHARED_BY_DOCUMENT_DATA_SOURCE,
  SharedByDocumentDataSource,
} from '@pages/protected/documents/services/shared-by-document-data-source';

@UntilDestroy()
@Component({
  selector: 'app-shared-with-list',
  templateUrl: './shared-with-list.component.html',
  styleUrls: ['./shared-with-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedWithListComponent implements OnInit, AfterViewInit {
  @ViewChild('statusActions') statusActions!: TemplateRef<any>;
  @ViewChild('documentInfo') documentInfo!: TemplateRef<any>;
  @ViewChild('sharedWith') sharedWith!: TemplateRef<any>;
  @ViewChild('filtersPanel') filtersPanel!: TemplateRef<any>;

  @Input() set documentId(documentId: string) {
    if (documentId) {
      this.documentIdValue = documentId;
      this.documentReady$.next(this.documentIdValue);
    }
  }

  get documentId(): string {
    return this.documentIdValue;
  }

  private viewReady$ = new ReplaySubject<void>(1);

  clientCategories$: Observable<CategoryShort[]> = this.companiesService
    .getClientCategories()
    .pipe(untilDestroyed(this))
    .pipe(
      map((data) => [
        {
          id: null,
          name: 'Category',
        } as any,
        ...data,
      ]),
    );

  contacts$: Observable<Contact[]> = this.contactsService
    .selectLoadedContacts()
    .pipe(untilDestroyed(this))
    .pipe(
      map((data) => [
        {
          id: null,
          email: 'Shared with',
          name: 'Shared with',
        } as any,
        ...data,
      ]),
    );

  groups$: Observable<ContactGroup[]> = this.contactGroupsService
    .getContactGroups()
    .pipe(untilDestroyed(this));

  statuses = SharedDocumentStatus;
  origins = DocumentOrigin;
  categoryFilter!: string;
  requestedFromFilter!: string;
  selected!: Nullable<SharedDocument>;
  processing$ = new BehaviorSubject<boolean>(false);
  documentReady$ = new ReplaySubject<string>(1);
  config!: DataTableConfig;
  columns!: ColumnDefinition[];
  initialSorting: Record<string, number> = { respondedAt: -1 };
  sharedWithFilter: Record<string, string> = {};

  private documentIdValue!: string;

  constructor(
    @Inject(SHARED_BY_DOCUMENT_DATA_SOURCE)
    public sharedDataSource: SharedByDocumentDataSource,
    private contactsService: ContactsStoreService,
    private companiesService: CompaniesStoreService,
    private sharedDocumentsService: SharedDocumentsStoreService,
    private contactGroupsService: ContactGroupsStoreService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.viewReady$.pipe(take(1), untilDestroyed(this)).subscribe(() => {
      this.config = {
        enableSorting: true,
        enableColumnsSorting: false,
        enableFiltering: false,
        enableRowEdit: false,
        pageSizes: [30, 50, 100],
        globalSearch: {
          fields: [
            'sharedWith.name',
            'sharedWith.email',
            'comment',
            'status',
            'document.file.name',
          ],
        },
        topToolbar: {
          exportFileName: 'fyio-shared-with',
          items: [this.filtersPanel],
        },
      };
      this.columns = [
        {
          id: 'sharedWith.name',
          label: 'Shared With',
          options: {
            width: 200,
          },
          cellOptions: {
            renderType: 'template',
            template: this.sharedWith,
          },
        },
        {
          id: 'sharedAt',
          label: 'Shared Date',
          cellOptions: {
            type: CellType.Date,
            format: DEFAULT_DATE_FORMAT,
          },
        },
        {
          id: 'sharedUntil',
          label: 'Shared Until',
          cellOptions: {
            type: CellType.Date,
            format: DEFAULT_DATE_FORMAT,
          },
        },
        {
          id: 'category.name',
          label: 'Client Folder',
          options: {
            width: 170,
          },
        },
        {
          id: 'status',
          label: 'Status',
          options: {
            align: 'center',
            width: 100,
          },
          cellOptions: {
            renderType: 'template',
            template: this.statusActions,
          },
        },
        {
          id: '',
          label: '',
          cellOptions: {
            type: CellType.Actions,
            actions: [
              {
                action: (row, event) => {
                  this.onRevoke(row);
                },
                icon: 'pi pi-times-circle',
              } as CellActionsOption<any>,
            ],
          },
        },
      ];
    });
  }

  ngAfterViewInit(): void {
    this.viewReady$.next();
  }

  onRevoke(share: Nullable<SharedDocument>): void {
    if (share) {
      const revoke = () => {
        this.processing$.next(true);
        this.messageService.clear();
        this.sharedDocumentsService
          .revokeDocumentAccess(share.id)
          .pipe(untilDestroyed(this), take(1))
          .subscribe(() => {
            this.messageService.addAll([
              {
                severity: 'success',
                detail: 'Access has been successfully revoked',
              },
            ]);
            this.reload();
            this.cdr.detectChanges();
          });
      };

      const ref = this.confirmationService.confirm({
        message: `You are going to revoke the document access for ${share.sharedWith.name}. Do you want to continue?`,
        acceptLabel: 'Confirm',
        rejectLabel: 'Cancel',
        acceptIcon: PrimeIcons.CHECK,
        acceptButtonStyleClass: 'p-button-danger',
        rejectButtonStyleClass: 'p-button-outlined',
        closeOnEscape: true,
        accept: () => {
          revoke();
        },
        reject: () => {
          this.processing$.next(false);
          this.cdr.detectChanges();
        },
      });
    }
  }

  onCategoryFiltered(values: string[], filter: (...args: any[]) => void): void {
    filter(values, 'category.id', 'in');
  }

  reload(): void {
    this.sharedDataSource
      .load(
        1,
        30,
        {
          'doc.id': this.documentId,
          ...this.sharedWithFilter,
        },
        'sharedAt',
        this.initialSorting['sharedAt'] > 0 ? 'asc' : 'desc',
      )
      .pipe(take(1), untilDestroyed(this))
      .subscribe();
  }
}
