import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const MAX_LEVEL = 3;

@Injectable()
export class SearchFilterService {
  private search$ = new BehaviorSubject<string>('');

  search(text: string): void {
    this.search$.next((text?.trim() || '').toLowerCase());
  }

  filter<T extends object>(data: T[], fields: string[]): Observable<T[]> {
    return this.search$.pipe(
      map((term: string) => [
        ...data.filter((item) => {
          if (term.length === 0) {
            return true;
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return fields.reduce((acc, key) => {
            const value = this.fieldValue(item, key);
            return (
              acc ||
              (typeof value !== 'undefined' &&
                `${value.toLowerCase()}`.includes(term))
            );
          }, false);
        }),
      ]),
    );
  }

  private fieldValue<T extends object>(row: T, field: string): any {
    if (field.match(/\./)) {
      const parts = field.split('.');
      return this.retrieveDeepProperty(row, parts);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return row[field];
  }

  private retrieveDeepProperty(obj: any, ids: string[], level = 0): any {
    if (level > MAX_LEVEL) {
      return obj;
    }
    if (ids.length === 0) {
      return obj;
    }
    if (!obj) {
      return undefined;
    }
    const id: string | undefined = ids.shift();
    if (id) {
      return this.retrieveDeepProperty(obj[id], ids, level++);
    } else {
      return obj;
    }
  }
}
