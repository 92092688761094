import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-update-email-dialog',
  templateUrl: './update-email-dialog.component.html',
  styleUrls: ['./update-email-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateEmailDialogComponent implements OnInit {
  processing$ = new BehaviorSubject(false);
  changed$ = new BehaviorSubject(false);

  form = new FormGroup({});
  model = { email: '' };
  original!: string;

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.model = this.dialogConfig.data;
    this.original = this.dialogConfig.data.email;
  }

  onSubmit(): void {
    if (this?.form?.invalid) {
      return;
    }
    this.processing$.next(true);

    this.dialogRef.close(this.form.value);
  }

  onChanged(data: { newEmail: string }): void {
    this.changed$.next(this.original !== data.newEmail);
  }
}
