<div class="page-container">
  <div></div>
  <div class="notfound-page">
    <div class="pages-body p-d-flex p-flex-column">
      <div class="p-as-center p-mt-auto p-mb-auto">
        <div class="pages-panel card p-d-flex p-flex-column">
          <div class="pages-header p-px-3 p-py-1">
            <h2>NOT FOUND</h2>
          </div>
          <div class="card p-mt-3 p-px-6">
            <img src="assets/layout/images/pages/404.png" alt="" />
          </div>
          <div class="pages-detail p-pb-6">
            Requested resource is not available.
          </div>
          <button
            pButton
            pRipple
            type="button"
            label="GO BACK TO DASHBOARD"
            [routerLink]="['/']"
            class="p-button-text"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <div class="bg"></div>
</div>
