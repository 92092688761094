import { FormHelper } from '@common/shared/helpers/form-helper';
import { FormGroup } from '@angular/forms';

export class Form {
  constructor(private form: FormGroup, private formHelper: FormHelper) {}
  validate(): Form {
    this.formHelper.validate(this.form);
    return this;
  }

  clearValidation(): Form {
    this.formHelper.clearValidation(this.form);
    return this;
  }

  getErrors(): string[] {
    return this.formHelper.getAllErrors(this.form);
  }

  reset(initValue?: any): Form {
    this.formHelper.reset(this.form, initValue);
    return this;
  }

  getForm(): FormGroup {
    return this.form;
  }
}
