export const DateFormats = {
  VIEW_DATE_FORMAT: 'll',
  DATEPICKER_DATE_FORMAT: 'dd-mm-yy',
  DATEPICKER_TIME_FORMAT: '24',
  SERVER_DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  SERVER_DATE_FORMAT: 'YYYY-MM-DD',
};

export const DEFAULT_ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_ISO_TIME_FORMAT = 'HH:mm:ss';
export const DEFAULT_ISO_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DEFAULT_DATE_FORMAT = 'MMM D, YYYY';
export const INFILE_NAME_DATE_FORMAT = 'DDMMYYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'MMM D, YYYY, h:mm a';
export const DEFAULT_TIME_DURATION_FORMAT = 'HH:mm:ss';
