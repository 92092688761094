import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, switchMap, withLatestFrom } from 'rxjs';
import { CompaniesStoreService } from '@store/companies/companies-store.service';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class ActiveCompanyGuard implements CanActivate, CanActivateChild {
  constructor(
    private companiesStore: CompaniesStoreService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.companiesStore.isActiveCompanySelected().pipe(
      tap(() => this.companiesStore.getCompanies()),
      withLatestFrom(this.companiesStore.selectCompanies()),
      map(([isSelected, list]) => !!(isSelected && list?.length)),
      tap((val) => {
        if (!val) {
          this.router.navigate(['/company/add']);
        }
      }),
    );
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
