import { Component, Inject, OnInit } from '@angular/core';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';
import { TableRowData } from '../../../interfaces';
import { CELL_INFO_PROVIDER } from '../../../utils';
import { CellInfoProvider } from '../../../interfaces/cell-info-provider';
import { DEFAULT_TIME_DURATION_FORMAT } from '@core/constants';
import { DateTimeService } from '@common/shared/services/date-time.service';

@Component({
  selector: 'app-time-duration-cell-renderer',
  templateUrl: '../base-cell-renderer.component.html',
  styleUrls: ['./time-duration-cell-renderer.component.scss'],
})
export class TimeDurationCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<number, R>
  implements OnInit
{
  format = DEFAULT_TIME_DURATION_FORMAT;

  constructor(
    @Inject(CELL_INFO_PROVIDER) cellInfoProvider: CellInfoProvider<number, R>,
    private dateTime: DateTimeService,
  ) {
    super(cellInfoProvider);
  }

  ngOnInit(): void {
    if (this.getOptions()['format']) {
      this.format = this.getOptions()['format'] as string;
    }
  }

  override render(): string {
    const time = this.getRawValue() as number;
    return this.dateTime.format(this.dateTime.parse(time, 'x'), this.format);
  }
}
