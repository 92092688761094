import { Nullable } from '@core/interfaces/nullable';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DocumentsService } from '@core/services/api/documents.service';
import {
  CategoryShort,
  Document,
  PaginatedResponse,
  SharedDocument,
  UploadProgress,
} from '@core/models';
import {
  BulkDocumentsCompleted,
  BulkUploadDocuments,
  BulkUploadProgress,
  ClearDocumentsErrors,
  DeleteDocument,
  DocumentCreated,
  GetDocumentsByCategory,
  GetDocumentsByContact,
  GetDocumentsCountersByCategory,
  GetDocumentsStatsByContact,
  GetDocumentsSuccess,
  GetPlainDocuments,
  SetActiveDocumentCategoryAction,
  UploadDocument,
} from '@store/documents/documents.actions';
import { UploadedFile } from '@core/models/uploaded-file.model';
import { VerificationType } from '@core/enums/verification-type.enum';
import * as sha256 from 'sha256';
import { HttpErrorResponse } from '@angular/common/http';
import { MAX_UPLOAD_SIZE } from '@common/shared/constants/upload';
import { filesize } from 'filesize';

export type GroupBy = 'clients';

export interface DocumentsStateModel {
  rootCategory: Nullable<CategoryShort>;
  plainList: Nullable<Document[]>;
  byCategory: Nullable<
    Record<string, Record<string, PaginatedResponse<Document>>>
  >;
  byContact: Nullable<
    Record<string, Record<string, PaginatedResponse<SharedDocument>>>
  >;
  countsByContact: Nullable<Record<string, number>>;
  byCategoryLoading: Nullable<Record<string, boolean>>;
  byContactLoading: Nullable<Record<string, boolean>>;
  countByCategory: Record<string, Record<string, number>>;
  created: Nullable<Document>;
  error: any;
  bulkUpload?: Nullable<{
    inProgress: boolean;
    progress: Array<UploadProgress>;
    isCompleted: boolean;
    withErrors?: Nullable<boolean>;
  }>;
  activeCategory: Nullable<string>;
}

@State<DocumentsStateModel>({
  name: 'documents',
  defaults: {
    rootCategory: null,
    plainList: null,
    byCategory: {},
    byContact: {},
    countsByContact: null,
    created: null,
    error: null,
    bulkUpload: null,
    countByCategory: {},
    byCategoryLoading: {},
    byContactLoading: {},
    activeCategory: null,
  },
})
@Injectable()
export class DocumentsState {
  constructor(private documentsService: DocumentsService) {}

  @Selector()
  static getCreated(state: DocumentsStateModel): Nullable<Document> {
    return state.created;
  }

  @Selector()
  static getActiveCategory(state: DocumentsStateModel): Nullable<string> {
    return state.activeCategory;
  }

  @Selector()
  static getPlainDocuments() {
    return createSelector([DocumentsState], (state) => {
      return state.documents.plainList;
    });
  }

  @Selector()
  static getCountByCategory(where: Nullable<Record<string, string>>) {
    return createSelector([DocumentsState], (state) => {
      const key = DocumentsState.getKey(where);
      return state.documents.countByCategory[key];
    });
  }

  @Selector()
  static isCategoryLoading(id: string) {
    return createSelector([DocumentsState], (state) => {
      return !!state.documents.byCategoryLoading[id];
    });
  }

  @Selector()
  static getDocumentsByCategory(
    categoryId: string,
    where: Nullable<Record<string, string>>,
  ) {
    const key = DocumentsState.getKey(where);
    return createSelector([DocumentsState], (state) => {
      return state.documents.byCategory[categoryId]
        ? state.documents.byCategory[categoryId][key]
        : null;
    });
  }

  @Selector()
  static getDocumentsByContact(
    contactId: string,
    where: Nullable<Record<string, string>>,
  ) {
    const key = DocumentsState.getKey(where);
    return createSelector([DocumentsState], (state) => {
      return state.documents.byContact[contactId] &&
        state.documents.byContact[contactId][key]
        ? state.documents.byContact[contactId][key].items
        : null;
    });
  }

  @Selector()
  static getStatsByContact(
    state: DocumentsStateModel,
  ): Nullable<Record<string, number>> {
    return state.countsByContact;
  }

  @Selector()
  static getState(state: DocumentsStateModel): DocumentsStateModel {
    return state;
  }

  @Selector()
  static isBulkUploadCompleted(state: DocumentsStateModel): Nullable<boolean> {
    return state.bulkUpload?.isCompleted;
  }

  @Selector()
  static isBulkUploadCompletedWithErrors(state: DocumentsStateModel): boolean {
    return !!(state.bulkUpload?.isCompleted && state.bulkUpload?.withErrors);
  }

  @Selector()
  static getBulkUploadProgress(
    state: DocumentsStateModel,
  ): Nullable<Array<UploadProgress>> {
    return state.bulkUpload?.progress;
  }

  @Selector()
  static getError(state: DocumentsStateModel): Nullable<any> {
    return state.error;
  }

  @Selector()
  static getDocuments(groupBy: GroupBy) {
    return createSelector([DocumentsState], (state: any) => {
      switch (groupBy) {
        case 'clients':
          return state.documents.byClients;
      }
    });
  }

  @Action(UploadDocument)
  create(
    ctx: StateContext<DocumentsStateModel>,
    action: UploadDocument,
  ): Observable<void> {
    ctx.patchState({
      created: null,
    });
    return this.documentsService
      .upload(action.payload.file as File, action.companyId)
      .pipe(
        take(1),
        switchMap((file: UploadedFile) => {
          return this.documentsService.createDocument(action.companyId, {
            name: action.payload.name,
            category: action.payload.category,
            note: '',
            verification: VerificationType.Standard,
            fileId: file.id,
          });
        }),
        switchMap((resp) =>
          ctx.dispatch(new DocumentCreated(action.companyId, resp, 0, 1)),
        ),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(BulkUploadDocuments)
  bulkUpload(
    ctx: StateContext<DocumentsStateModel>,
    action: BulkUploadDocuments,
  ): Observable<void[]> {
    ctx.patchState({
      bulkUpload: {
        inProgress: true,
        isCompleted: false,
        progress: [],
      },
    });

    const dataFetchObservables = action.payload.map((item, i) => {
      return new Observable<void>((sub) => {
        this.documentsService
          .uploadWithProgress(item.file as File, action.companyId)
          .pipe(
            tap<any>((res) => {
              if (res?.loaded !== undefined && res?.total !== undefined) {
                ctx.dispatch(
                  new BulkUploadProgress(action.companyId, {
                    loaded: res.loaded,
                    total: res.total,
                    completed: false,
                    index: i,
                  } as UploadProgress),
                );
              } else if (res?.id && res?.url) {
                ctx.dispatch(
                  new BulkUploadProgress(action.companyId, {
                    loaded: 100,
                    total: 100,
                    completed: true,
                    index: i,
                  } as UploadProgress),
                );
              }
            }),
            catchError((err: any) => {
              const errorMessage =
                (err as HttpErrorResponse)?.status === 413
                  ? `File exceeds the ${filesize(MAX_UPLOAD_SIZE)} size limit.`
                  : err?.error?.message ||
                    'File has not been uploaded due error';
              let res = ctx.dispatch(
                new BulkUploadProgress(action.companyId, {
                  loaded: 100,
                  total: 100,
                  index: i,
                  error: errorMessage,
                } as UploadProgress),
              );
              if (action.payload.length === 1) {
                res = ctx.dispatch(
                  new BulkDocumentsCompleted(
                    action.companyId,
                    ctx.getState().bulkUpload,
                  ),
                );
              }
              sub.next();
              return res;
            }),
            filter((res) => !!res && !!res.id && !!res.url),
            take(1),
            switchMap((file: UploadedFile) => {
              return this.documentsService.createDocument(action.companyId, {
                name: item.name,
                category: item.category,
                note: '',
                verification: VerificationType.Standard,
                fileId: file.id,
              });
            }),
            switchMap((resp) =>
              ctx.dispatch(
                new DocumentCreated(
                  action.companyId,
                  resp,
                  i,
                  action.payload.length,
                ),
              ),
            ),
            catchError((e) => {
              ctx.patchState({
                error: e,
              });
              return throwError(e);
            }),
          )
          .subscribe({
            next: () => {
              sub.next();
            },
            error: () => {
              sub.next();
            },
          });
      });
    });

    return forkJoin(dataFetchObservables);
  }

  @Action(BulkUploadProgress)
  bulkUploadProgress(
    ctx: StateContext<DocumentsStateModel>,
    action: BulkUploadProgress,
  ): void {
    const state = ctx.getState();
    const progress =
      state.bulkUpload?.progress?.filter(
        (p) => p.index !== action.progress.index,
      ) || [];
    ctx.patchState({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      bulkUpload: {
        ...state.bulkUpload,
        progress: [...progress, action.progress],
      },
    });
  }

  @Action(DeleteDocument)
  deleteContact(
    ctx: StateContext<DocumentsStateModel>,
    action: DeleteDocument,
  ): Observable<void> {
    return this.documentsService
      .deleteDocument(action.id, action.companyId)
      .pipe(
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(DocumentCreated)
  created(
    ctx: StateContext<DocumentsStateModel>,
    action: DocumentCreated,
  ): void {
    ctx.patchState({
      created: action.payload,
    });
    const progress = ctx.getState().bulkUpload?.progress;
    if (
      progress &&
      progress.length === action.total &&
      progress.reduce((acc, cur) => acc && !!(cur.completed || cur.error), true)
    ) {
      ctx.dispatch(
        new BulkDocumentsCompleted(action.companyId, ctx.getState().bulkUpload),
      );
    }
  }

  @Action(BulkDocumentsCompleted)
  bulkUploadCompleted(ctx: StateContext<DocumentsStateModel>): void {
    ctx.patchState({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      bulkUpload: {
        ...ctx.getState().bulkUpload,
        inProgress: false,
        isCompleted: true,
        withErrors: ctx
          .getState()
          .bulkUpload?.progress.reduce((acc, cur) => acc || !!cur.error, false),
      },
    });
  }

  @Action(GetPlainDocuments)
  getPlainDocuments(
    ctx: StateContext<DocumentsStateModel>,
    action: GetPlainDocuments,
  ): Observable<any> {
    return this.documentsService
      .getDocuments(
        action.payload.companyId,
        action.payload.page || 1,
        action.payload.pageSize || 50,
        action.payload.sortBy || 'createdAt',
        action.payload.order || 'desc',
        action.payload.where || {},
      )
      .pipe(
        tap((data) => {
          ctx.patchState({
            plainList: data.items,
          });
          ctx.dispatch(new GetDocumentsSuccess(action));
        }),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(GetDocumentsByCategory)
  getDocumentsByCategory(
    ctx: StateContext<DocumentsStateModel>,
    action: GetDocumentsByCategory,
  ): Observable<any> {
    ctx.patchState({
      byCategoryLoading: {
        ...ctx.getState().byCategoryLoading,
        [action.payload.categoryId as string]: true,
      },
    });
    return this.documentsService
      .getDocuments(
        action.payload.companyId,
        action.payload.page || 1,
        action.payload.pageSize || 50,
        'createdAt',
        'desc',
        {
          ...(action.payload.where || {}),
          'categories.id':
            action.payload.categoryId ||
            (ctx.getState().rootCategory?.id as string),
        },
      )
      .pipe(
        tap((data: PaginatedResponse<Document>) => {
          const key = DocumentsState.getKey(action.payload.where);
          const state = ctx.getState().byCategory;
          const categoryId =
            action.payload.categoryId ||
            (data.items?.length > 0 ? data.items[0].category.id : '');
          let rootCategory: Nullable<CategoryShort>;
          if (data.items?.length > 0 && data.items[0].category?.isRoot) {
            rootCategory = data.items[0].category;
          }
          ctx.patchState({
            rootCategory: rootCategory || ctx.getState().rootCategory,
            byCategory: {
              ...state,
              [categoryId]: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...(state[categoryId] || {}),
                [key]: data,
              },
            },
            byCategoryLoading: {
              ...ctx.getState().byCategoryLoading,
              [action.payload.categoryId as string]: false,
            },
          });
          ctx.dispatch(new GetDocumentsSuccess(action));
        }),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(GetDocumentsCountersByCategory)
  getCountByCategory(
    ctx: StateContext<DocumentsStateModel>,
    action: GetDocumentsCountersByCategory,
  ): Observable<any> {
    return this.documentsService
      .getDocumentsCount(action.payload.companyId, action.payload.where || {})
      .pipe(
        tap((data) => {
          const key = DocumentsState.getKey(action.payload.where);
          ctx.patchState({
            countByCategory: {
              ...ctx.getState().countByCategory,
              [key]: data,
            },
          });
        }),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(GetDocumentsByContact)
  getDocumentsByContact(
    ctx: StateContext<DocumentsStateModel>,
    action: GetDocumentsByContact,
  ): Observable<any> {
    ctx.patchState({
      byContactLoading: {
        ...ctx.getState().byContactLoading,
        [action.contactId as string]: true,
      },
    });
    return this.documentsService
      .getByContact(
        action.companyId,
        action.contactId,
        action.page || 1,
        action.pageSize || 50,
        'createdAt',
        'desc',
        {
          ...(action.where || {}),
        },
      )
      .pipe(
        tap((data: PaginatedResponse<SharedDocument>) => {
          const key = DocumentsState.getKey(action.where);
          const state = ctx.getState().byContact;
          const contactId = action.contactId;
          ctx.patchState({
            byContact: {
              ...state,
              [contactId]: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...(state[contactId] || {}),
                [key]: data,
              },
            },
            byContactLoading: {
              ...ctx.getState().byContactLoading,
              [contactId]: false,
            },
          });
        }),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(GetDocumentsStatsByContact)
  getDocumentsStatsByContact(
    ctx: StateContext<DocumentsStateModel>,
    action: GetDocumentsStatsByContact,
  ): Observable<any> {
    return this.documentsService.getStatsByContact(action.companyId).pipe(
      tap((data: Record<string, number>) => {
        ctx.patchState({
          countsByContact: data,
        });
      }),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(ClearDocumentsErrors)
  clearErrors(ctx: StateContext<DocumentsStateModel>): void {
    ctx.patchState({
      error: null,
      bulkUpload: null,
    });
  }

  @Action(SetActiveDocumentCategoryAction)
  setActiveCategory(
    ctx: StateContext<DocumentsStateModel>,
    action: SetActiveDocumentCategoryAction,
  ): void {
    ctx.patchState({
      activeCategory: action.categoryId,
    });
  }

  private static getKey(where: Nullable<Record<string, string>>): string {
    return where && Object.keys(where).length > 0
      ? sha256(`${JSON.stringify(where)}`)
      : 'default';
  }
}
