import { Pipe, PipeTransform } from '@angular/core';
import { DataType } from '@core/enums/data-type.enum';
import { CurrencyPipe } from '@angular/common';
import { DateTimeService } from '@common/shared/services/date-time.service';

@Pipe({
  name: 'formatCell',
})
export class FormatCellPipe implements PipeTransform {
  constructor(
    private dateService: DateTimeService,
    private currencyPipe: CurrencyPipe,
  ) {}

  transform(
    value: string | number,
    type: string,
    param?: number | string,
    param2?: string,
  ): string {
    if (value === undefined || value === null) {
      return '';
    }
    switch (type) {
      case DataType.Date:
        value = this.dateService.format(value, 'll');
        break;
      case DataType.Float:
        value = Number(value).toFixed((param as number) || 2);
        break;
      case DataType.Number:
        value = Math.round(Number(value));
        break;
      case DataType.Currency:
        value = this.currencyPipe.transform(
          value,
          param?.toString(),
          param2,
        ) as string;
        break;
      case DataType.Boolean:
        value = value ? 'Yes' : 'No';
        break;
    }
    return value.toString();
  }
}
