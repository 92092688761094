import { Nullable } from '@core/interfaces/nullable';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { DashboardStats } from '@core/models';
import {
  DashboardLoaded,
  GetDashboard,
} from '@store/dashboard/dashboard.actions';
import { DashboardApiService } from '@core/services/api/dashboard-api.service';
import dayjs from 'dayjs';
import { DateFormats } from '@core/constants';

export interface DashboardStateModel {
  stats: Nullable<DashboardStats>;
  error: any;
}

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    stats: null,
    error: null,
  },
})
@Injectable()
export class DashboardState {
  constructor(private dashboardApiService: DashboardApiService) {}

  @Selector()
  static getState(state: DashboardStateModel): DashboardStateModel {
    return state;
  }

  @Selector()
  static getDashboardStats(
    state: DashboardStateModel,
  ): Nullable<DashboardStats> {
    return state.stats;
  }

  @Action(GetDashboard)
  getDashboard(
    ctx: StateContext<DashboardStateModel>,
    action: GetDashboard,
  ): Observable<any> {
    const period: string[] = action.period.map((d) =>
      dayjs(d).format(DateFormats.SERVER_DATE_FORMAT),
    );
    return this.dashboardApiService
      .getCompanyDashboard(action.companyId, period)
      .pipe(
        switchMap((data) => ctx.dispatch(new DashboardLoaded(data))),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(DashboardLoaded)
  loaded(
    ctx: StateContext<DashboardStateModel>,
    action: DashboardLoaded,
  ): void {
    ctx.patchState({
      stats: { ...action.payload },
    });
  }
}
