import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import * as messages from '@common//shared/utils/validation/validation-messages';
import { Nullable } from '@core/interfaces/nullable';

@Injectable()
export class FormHelper {
  hasError(field: Nullable<AbstractControl>): boolean {
    if (!field) {
      return false;
    }
    return field.invalid && field.dirty && field.touched;
  }

  getErrors(name: string, field: Nullable<AbstractControl>): string[] {
    if (!field || !field.errors) {
      return [];
    }
    return Object.keys(field.errors).map((err) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (typeof messages[err + 'ValidationMessage'] === 'function') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return messages[err + 'ValidationMessage'].call(
          field,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          field.errors[err],
          name,
        );
      }
      return err;
    });
  }

  getAllErrors(form: FormGroup): string[] {
    if (!form || !form.controls) {
      return [];
    }
    return Object.entries(form.controls)
      .map(([name, control]) => this.getErrors(name, control))
      .reduce<string[]>((acc, cur) => [...acc, ...cur], []);
  }

  reset(form: FormGroup, initValue?: any): void {
    form.reset(initValue);
  }

  validate(form: FormGroup): void {
    form.markAllAsTouched();
    form.markAsDirty();
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      control?.markAsTouched({ onlySelf: true });
      control?.markAsDirty({ onlySelf: true });
    });
  }

  clearValidation(form: FormGroup): void {
    form.markAsUntouched();
    form.markAsPristine();
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      control?.markAsUntouched({ onlySelf: true });
      control?.markAsPristine({ onlySelf: true });
    });
  }
}
