<p-card
  *ngIf="document"
  class="document-info flex flex-column align-items-start"
>
  <ng-container *ngIf="sharedDocument && !canBeShared">
    <h5><span>Sharing</span> Details</h5>
    <div class="grid">
      <div class="col-6 flex flex-column">
        <div class="flex align-items-start mb-4">
          <div class="ml-2 flex flex-column">
            <span class="text-500 font-normal mb-1">Shared By:</span>
            <div class="mt-2 mb-2">
              <app-contact [contact]="sharedDocument.sharedBy"></app-contact>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 flex flex-column">
        <div class="flex align-items-start mb-4">
          <div class="ml-2 flex flex-column">
            <span class="text-500 font-normal mb-1">Shared With:</span>
            <div class="mt-2 mb-2">
              <app-contact [contact]="sharedDocument.sharedWith"></app-contact>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-6 flex flex-column">
        <div class="flex align-items-start mb-4">
          <div class="pi pi-sync"></div>
          <div class="ml-2 flex flex-column">
            <span class="text-500 font-normal mb-1">Status:</span>
            <span class="font-medium">{{
              sharedDocument.status | humanize
            }}</span>
          </div>
        </div>
        <div class="flex align-items-start mb-4">
          <div class="pi pi-calendar"></div>
          <div class="ml-2 flex flex-column">
            <span class="text-500 font-normal mb-1">
              <span>Shared </span>On:</span
            >
            <span class="font-medium">{{
              sharedDocument?.sharedAt | dateFormat : dateFormat
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-6 flex flex-column">
        <div
          *ngIf="sharedDocument.sharedAt"
          class="flex align-items-start mb-4"
        >
          <div class="pi pi-th-large"></div>
          <div class="ml-2 flex flex-column">
            <span class="text-500 font-normal mb-1">Category:</span>
            <span class="font-medium">{{ sharedDocument.category?.name }}</span>
          </div>
        </div>
        <div class="flex align-items-start mb-4">
          <div class="pi pi-clock"></div>
          <div class="ml-2 flex flex-column">
            <span class="text-500 font-normal mb-1">
              <span>Shared</span> Until:</span
            >
            <span class="font-medium"
              >{{ sharedDocument?.sharedUntil | dateFormat : dateFormat }} ({{
                sharedDocument.lifetime
              }}
              {{ 'day' | pluralize : sharedDocument.lifetime }})</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 flex flex-column">
        <div class="flex align-items-start mb-4">
          <div class="pi pi-comment"></div>
          <div class="ml-2 flex flex-column">
            <span class="text-500 font-normal mb-1">Note:</span>
            <span class="font-medium comment-container">{{
              sharedDocument.message
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container>
    <ng-container *ngIf="document && canBeShared">
      <div class="flex justify-content-between full-width mb-2">
        <div><h5>Sharing Details:</h5></div>
        <div>
          <button
            pButton
            type="button"
            label="Share"
            icon="pi pi-share-alt"
            (click)="share.emit()"
          ></button>
        </div>
      </div>
      <app-shared-with-list [documentId]="document?.id"></app-shared-with-list>
    </ng-container>
  </ng-container>
</p-card>
