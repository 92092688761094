import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByField',
})
export class FilterByFieldPipe implements PipeTransform {
  transform(
    arr: Array<Record<string, any>>,
    field: string,
    value: any,
  ): Array<Record<string, any>> {
    if (!arr || !field) {
      return arr;
    }
    return arr.filter((item) =>
      !value ? !!item[field] : item[field] === value,
    );
  }
}
