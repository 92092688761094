import { TreeLike } from '@common/shared/types/data-node';
import { TreeNode } from 'primeng/api';

export const findInTree = (
  id: string,
  treeNodes?: Array<TreeLike>,
): TreeLike | null => {
  if (!id || !treeNodes || treeNodes.length === 0) {
    return null;
  }
  let found: TreeLike | null = null;
  for (let i = 0; i < treeNodes.length; i++) {
    const node = treeNodes[i];
    if (node.id === id) {
      found = node;
      break;
    }
    found = findInTree(id, node.children);
    if (found) {
      break;
    }
  }
  return found;
};

export const findInTreeNodes = (
  id: string,
  treeNodes?: Array<TreeNode>,
): TreeNode | null => {
  if (!id || !treeNodes || treeNodes.length === 0) {
    return null;
  }
  let found: TreeNode | null = null;
  for (let i = 0; i < treeNodes.length; i++) {
    const node = treeNodes[i];
    if (node.data.id === id) {
      found = node;
      break;
    }
    found = findInTreeNodes(id, node.children);
    if (found) {
      break;
    }
  }
  return found;
};
