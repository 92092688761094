<p-card
  *ngIf="request"
  class="document-info flex flex-column align-items-start"
>
  <h5><span *ngIf="request">Request</span> Details</h5>
  <div *ngIf="request?.document" class="grid">
    <div class="col-12 flex flex-column">
      <div class="info-field flex align-items-start mb-2 full-width">
        <app-file-info
          [closable]="false"
          [file]="request?.document.file"
        ></app-file-info>
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-12 flex flex-column">
      <div
        *ngIf="request?.requestedFrom"
        class="info-field flex align-items-start mb-2"
      >
        <div class="pi pi-user"></div>
        <div class="ml-2 flex flex-column">
          <span class="text-500 font-normal mb-1">Requested From</span>
          <span class="font-medium">
            <app-contact [contact]="request.requestedFrom"></app-contact>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="grid">
    <div class="col-6 flex flex-column">
      <div *ngIf="requestedBy" class="info-field flex align-items-start mb-2">
        <div class="pi pi-user"></div>
        <div class="ml-2 flex flex-column">
          <span class="text-500 font-normal mb-1">Requested By</span>
          <span class="font-medium">
            <app-contact [contact]="requestedBy"></app-contact>
          </span>
        </div>
      </div>
      <div class="info-field flex align-items-start mb-2">
        <div class="pi pi-id-card"></div>
        <div class="ml-2 flex flex-column">
          <span class="text-500 font-normal mb-1">Verification:</span>
          <span class="font-medium">{{ documentType | humanize }}</span>
        </div>
      </div>
      <div
        *ngIf="requestLifetime"
        class="info-field flex align-items-start mb-2"
      >
        <div class="pi pi-clock"></div>
        <div class="ml-2 flex flex-column">
          <span class="text-500 font-normal mb-1">
            <span *ngIf="request">Requested</span>
            For:</span
          >
          <span class="font-medium"
            >{{ requestLifetime }}
            {{ 'day' | pluralize : requestLifetime }}</span
          >
        </div>
      </div>
    </div>
    <div class="col-6 flex flex-column">
      <div *ngIf="requestedAt" class="info-field flex align-items-start mb-2">
        <div class="pi pi-calendar"></div>
        <div class="ml-2 flex flex-column">
          <span class="text-500 font-normal mb-1">
            <span *ngIf="request">Requested</span>
            On:</span
          >
          <span class="font-medium">{{
            requestedAt | dateFormat : dateFormat
          }}</span>
        </div>
      </div>
      <div class="info-field flex align-items-start mb-2">
        <div class="pi pi-th-large"></div>
        <div class="ml-2 flex flex-column">
          <span class="text-500 font-normal mb-1">Category:</span>
          <span class="font-medium">{{ categoryName }}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="request" class="grid">
    <div class="col-12 flex flex-column">
      <div class="info-field flex align-items-start mb-2">
        <div class="pi pi-comment"></div>
        <div class="ml-2 flex flex-column">
          <span class="text-500 font-normal mb-1">Request Message:</span>
          <span class="font-medium comment-container">{{
            request.message
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <span *ngIf="showActions" class="flex justify-content-end">
      <button
        pButton
        type="button"
        label="Decline"
        icon="pi pi-ban"
        class="p-button-outlined mr-2 p-button-danger"
        (click)="decline.emit()"
      ></button>
      <button
        pButton
        type="button"
        label="Approve"
        icon="pi pi-check"
        (click)="approve.emit()"
      ></button>
    </span>
  </ng-template>
</p-card>
