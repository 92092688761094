import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import {
  Account,
  CreateAccountBySocialTokenRequest,
  CreateAccountRequest,
  UserProfile,
} from '@core/models';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient, private logger: NGXLogger) {}

  createAccount(payload: CreateAccountRequest): Observable<Account> {
    return this.http.post<any>(
      environment.API_DOMAIN + '/auth/account',
      payload,
    );
  }

  createAccountBySocialProvider(
    payload: CreateAccountBySocialTokenRequest,
  ): Observable<Account> {
    return this.http.post<any>(
      environment.API_DOMAIN + '/auth/account/social',
      payload,
    );
  }
}
