import { Renderer2 } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as sha256 from 'sha256';

interface Checked {
  size?: {
    width: number;
    height: number;
  };
  checked: boolean;
}

const checked: Map<string, Checked> = new Map<string, Checked>();

export const checkImage = (
  url: string,
  renderer: Renderer2,
  parent: HTMLElement,
  size?: { width: number; height: number },
): Observable<boolean> => {
  const key = sha256(url);
  if (checked.has(key)) {
    if (size && checked.get(key)?.size) {
      size.width = checked.get(key)?.size?.width as number;
      size.height = checked.get(key)?.size?.height as number;
    }
    return of(checked.get(key)?.checked as boolean);
  } else {
    const img: HTMLImageElement = renderer.createElement('img');
    return new Observable((sub) => {
      img.onerror = () => {
        checked.set(key, { checked: false });
        sub.next(false);
        sub.complete();
        renderer.removeChild(parent, img);
      };
      img.onload = () => {
        checked.set(key, {
          checked: true,
          size: { width: img.naturalWidth, height: img.naturalHeight },
        });
        if (size) {
          size.width = img.naturalWidth;
          size.height = img.naturalHeight;
        }
        sub.next(true);
        sub.complete();
        renderer.removeChild(parent, img);
      };
      img.width = 0;
      img.height = 0;
      img.src = url as string;
      renderer.appendChild(parent, img);
    });
  }
};
