import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { NgxsModule } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { DocumentsStoreService } from '@store/documents/documents-store.service';
import { DocumentsState } from '@store/documents/documents.state';

@NgModule({
  imports: [CoreModule, CommonModule, NgxsModule.forFeature([DocumentsState])],
  exports: [],
  providers: [DocumentsStoreService, DocumentsState],
})
export class DocumentsStoreModule {}
