<div class="field clients-selector-field" (click)="op.toggle($event)">
  <input
    pInputText
    type="text"
    [readonly]="true"
    placeholder="Select contact of group of contacts"
    [value]="selectedText"
  />
  <i class="pi pi-chevron-{{ isOpened ? 'up' : 'down' }}"></i>
</div>
<p-overlayPanel
  #op
  styleClass="clients-dropdown"
  (onShow)="isOpened = true"
  (onHide)="isOpened = false"
>
  <div class="w-full flex align-items-center flex-column">
    <p-tabView [(activeIndex)]="activeTabIndex">
      <p-tabPanel header="Contacts">
        <ng-container
          *ngTemplateOutlet="
            tabContent;
            context: {
              options: clients,
              selectedFn: onClientSelected.bind(this),
              selected: selectedClients.selected,
              showContact: true
            }
          "
        ></ng-container>
      </p-tabPanel>
      <p-tabPanel header="Groups">
        <ng-container
          *ngTemplateOutlet="
            tabContent;
            context: {
              options: groups,
              selectedFn: onGroupSelected.bind(this),
              selected: selectedGroups.selected,
              showContact: false
            }
          "
        ></ng-container>
      </p-tabPanel>
    </p-tabView>
    <div class="field w-full mt-4">
      <button
        pButton
        class="p-button-primary w-full flex justify-content-center"
        (click)="onApplySelected($event); op.hide()"
      >
        <ng-container *ngIf="selectedCount === 0">Create new</ng-container>
        <ng-container *ngIf="selectedCount > 0">Add selected</ng-container>
        <span class="ml-2" *ngIf="selectedCount > 0">
          ({{ selectedCount }})</span
        >
      </button>
    </div>
  </div>
</p-overlayPanel>

<ng-template
  #tabContent
  let-options="options"
  let-selectedFn="selectedFn"
  let-selected="selected"
  let-showContact="showContact"
  let-counter="counter"
>
  <div class="list">
    <app-search-field
      [placeholder]="'Search...'"
      (search)="onSearch(showContact ? 'client' : 'group', $event)"
    ></app-search-field>
    <cdk-virtual-scroll-viewport itemSize="6" class="scroll-wrapper">
      <div
        *cdkVirtualFor="let item of options; trackBy: trackById"
        class="list-item"
        (click)="
          showContact ? onClientSelected([item.id]) : onGroupSelected([item.id])
        "
      >
        <p-checkbox
          [label]="showContact ? undefined : item.name"
          [value]="item.id"
          [ngModel]="selected"
          (click)="$event.preventDefault()"
        >
        </p-checkbox>
        <p-badge
          *ngIf="!showContact && item.clients?.length"
          [value]="item.clients?.length"
          styleClass="default"
          (mouseenter)="tooltipContent.show($event)"
          (mouseleave)="tooltipContent.hide()"
        >
        </p-badge>
        <app-contact *ngIf="showContact" [contact]="item"></app-contact>
        <p-overlayPanel #tooltipContent>
          <div class="flex flex-column">
            <div
              *ngFor="let client of item.clients"
              class="mb-4 contact-outlined"
            >
              <app-contact [contact]="client"></app-contact>
            </div>
          </div>
        </p-overlayPanel>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-template>
