<app-prime-data-table
  [contextMenu]="contextMenu"
  [columns]="columns"
  [selectedRow]="selectedRow"
  [data]="data"
  [totalRows]="totalRows"
  [lazy]="!!dataSource"
  [loading]="loading$ | async"
  [config]="config"
  (ready)="ready.emit($event)"
  (filtered)="onFiltered($event)"
  (rowChanged)="onRowChanged($event)"
  (editCanceled)="onEditCancel($event)"
  (lazyLoad)="onLazyLoad($event)"
  (selectedRowChange)="selectedRowChange.emit($event)"
></app-prime-data-table>

<ng-template
  #expandCell
  let-expanded="expanded"
  let-row="row"
  let-toggle="toggleExpand"
>
  <p-button
    [attr.id]="'expand-btn-' + row.id"
    class="p-button p-button-icon"
    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
    (onClick)="toggle(row)"
  ></p-button>
</ng-template>
