import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserShort } from '@core/models/user-short.model';
import { environment } from '@env/environment';
import {
  ContactGroup,
  CreateContactGroup,
  UpdateContactGroupDto,
} from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class ContactGroupsService {
  constructor(private http: HttpClient) {}

  getContactGroups(companyId: string): Observable<ContactGroup[]> {
    return this.http.get<ContactGroup[]>(
      `${environment.API_DOMAIN}/company/${companyId}/client-groups`,
    );
  }

  getGroupCounters(companyId: string): Observable<Record<string, number>> {
    return this.http.get<Record<string, number>>(
      `${environment.API_DOMAIN}/company/${companyId}/client-groups/counters`,
    );
  }

  createContactGroup(
    companyId: string,
    payload: CreateContactGroup,
  ): Observable<ContactGroup> {
    return this.http.post<ContactGroup>(
      `${environment.API_DOMAIN}/company/${companyId}/client-groups`,
      payload,
    );
  }

  updateContactGroup(
    id: string,
    companyId: string,
    payload: UpdateContactGroupDto,
  ): Observable<ContactGroup> {
    return this.http.put<ContactGroup>(
      `${environment.API_DOMAIN}/company/${companyId}/client-groups/${id}`,
      payload,
    );
  }

  deleteContactGroup(id: string, companyId: string): Observable<UserShort> {
    return this.http.delete<UserShort>(
      `${environment.API_DOMAIN}/company/${companyId}/client-groups/${id}`,
    );
  }
}
