import { Injectable } from '@angular/core';
import { EMPTY, interval, NEVER, Observable, of, switchMap } from 'rxjs';
import { User } from '@core/models/user.model';
import { Store } from '@ngxs/store';
import { AuthState } from '@store/auth/auth.state';
import {
  CheckRestorePasswordToken,
  ClearEmailConfirmationResults,
  ConfirmEmailToken,
  Login,
  LoginBySocialProvider,
  Logout,
  RefreshToken,
  RestorePassword,
  SendRestorePassword,
} from '@store/auth/auth.actions';
import { Nullable } from '@core/interfaces/nullable';
import { filter, map, take, tap } from 'rxjs/operators';
import { Account, CreateAccountRequest } from '@core/models';
import {
  CreateAccount,
  CreateAccountBySocialProvider,
} from '@store/account/account.actions';
import { AccountState } from '@store/account/account.state';
import { JwtService } from '@common/shared/services/jwt.service';

@Injectable()
export class AuthStoreService {
  constructor(
    private store: Store,
    private state: AuthState,
    private jwtService: JwtService,
  ) {}

  getAccessToken(): Observable<Nullable<string>> {
    return this.store.selectOnce(AuthState.accessToken);
  }

  getRefreshToken(): Observable<Nullable<string>> {
    return this.store.selectOnce(AuthState.refreshToken);
  }

  isAuthenticated(): Observable<boolean> {
    return this.store.selectOnce(AuthState.isAuthenticated);
  }
  isAccessTokenExpired(): Observable<boolean> {
    return this.getAccessToken().pipe(
      map((token) => !!token && this.jwtService.isTokenExpired(token)),
    );
  }

  getUserInfo(): Observable<Nullable<User>> {
    return this.store.selectOnce(AuthState.identity);
  }

  refreshToken(): Observable<User> {
    return this.store.dispatch(new RefreshToken());
  }

  login(username: string, password: string): Observable<User> {
    return this.store.dispatch(new Login({ username, password })).pipe(
      switchMap(() => this.store.select(AuthState.identity)),
      filter((data) => !!data),
    ) as Observable<User>;
  }

  checkRestorePasswordToken(token: string): Observable<string> {
    return this.store.dispatch(new CheckRestorePasswordToken({ token })).pipe(
      switchMap(() => this.store.select(AuthState.restorePasswordTokenStatus)),
      filter((d) => !!d && d !== 'pending'),
    ) as Observable<string | 'valid' | 'invalid'>;
  }

  sendRestorePassword(email: string): Observable<void> {
    return this.store.dispatch(new SendRestorePassword({ email }));
  }

  restorePassword(
    token: string,
    password: string,
    confirmPassword: string,
  ): Observable<void> {
    return this.store
      .dispatch(new RestorePassword({ token, password, confirmPassword }))
      .pipe(
        tap(() => {
          interval(5000)
            .pipe(take(1))
            .subscribe(() => {
              this.store.dispatch(new ClearEmailConfirmationResults());
            });
        }),
      );
  }

  createAccount(payload: CreateAccountRequest): Observable<Account> {
    return this.store.dispatch(new CreateAccount(payload)).pipe(
      switchMap(() => this.store.select(AccountState.getAccount)),
      filter((data) => !!data),
    ) as Observable<Account>;
  }

  confirmEmail(token: string): void {
    this.store.dispatch(new ConfirmEmailToken(token));
    interval(5000)
      .pipe(take(1))
      .subscribe(() =>
        this.store.dispatch(new ClearEmailConfirmationResults()),
      );
  }

  emailConfirmationStatus(): Observable<
    string | 'confirmed' | 'error' | 'pending'
  > {
    return this.store
      .select(AuthState.emailConfirmationStatus)
      .pipe(filter((d) => !!d)) as Observable<string>;
  }

  createAccountBySocial(idToken: string): Observable<Account> {
    return this.store
      .dispatch(
        new CreateAccountBySocialProvider({
          idToken,
        }),
      )
      .pipe(
        switchMap(() => this.store.select(AccountState.getAccount)),
        filter((data) => !!data),
      ) as Observable<Account>;
  }

  loginBySocialProvider(idToken: string): Observable<User> {
    return this.store.dispatch(new LoginBySocialProvider({ idToken })).pipe(
      switchMap(() => this.store.select(AuthState.identity)),
      filter((data) => !!data),
    ) as Observable<User>;
  }

  logout(): Observable<void> {
    return this.store.dispatch(new Logout());
  }
}
