import { TreeLike } from '@common/shared/types/data-node';

export const restoreParentNodeInTree = (
  item: TreeLike,
  parent?: TreeLike,
): TreeLike => {
  const node = {
    ...item,
    parent,
  };
  return {
    ...node,
    children: item.children
      ? item.children.map((ch) => {
          return restoreParentNodeInTree(ch, node);
        })
      : undefined,
  };
};
