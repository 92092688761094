import { Category } from '@core/models/category.model';
import { CreateContact } from '@core/models/create-contact.model';
import { UserShort } from '@core/models/user-short.model';

export class GetContacts {
  static readonly type = '[CONTACTS] Get Contacts';

  constructor(public companyId: string) {}
}

export class SearchFyioExternalContact {
  static readonly type = '[CONTACTS] Search Fyio External Contact';

  constructor(public companyId: string, public email: string) {}
}

export class GetContactsByGroup {
  static readonly type = '[CONTACTS] Get Contacts By Group';

  constructor(public companyId: string, public groupId: string) {}
}

export class CategoriesLoaded {
  static readonly type = '[CONTACTS] Contacts Loaded';

  constructor(public payload: Category[]) {}
}

export class AddContact {
  static readonly type = '[CONTACTS] Create Contact';

  constructor(public companyId: string, public payload: CreateContact) {}
}

export class ContactCreated {
  static readonly type = '[CONTACTS] Contact Created';

  constructor(public companyId: string, public payload: UserShort) {}
}

export class ContactUpdated {
  static readonly type = '[CONTACTS] Contact Updated';

  constructor(public companyId: string, public payload: UserShort) {}
}

export class UpdateContact {
  static readonly type = '[CONTACTS] Update Contact';

  constructor(
    public id: string,
    public companyId: string,
    public payload: Partial<CreateContact>,
  ) {}
}

export class DeleteContact {
  static readonly type = '[CONTACTS] Delete Contact';

  constructor(public id: string, public companyId: string) {}
}

export class ClearContactsErrors {
  static readonly type = '[CONTACTS] Clear Contacts Errors';
}

export class ClearExternalContactsSearch {
  static readonly type = '[CONTACTS] Clear Search Contacts';
}
