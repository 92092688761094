import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NEVER, Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { NGXLogger } from 'ngx-logger';
import { UserProfile } from '@core/models/user-profile.model';
import { UserProfileDto } from '@core/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient, private logger: NGXLogger) {}

  getProfile(): Observable<UserProfile> {
    return this.http.get<any>(environment.API_DOMAIN + '/user-profile');
  }

  updateName(firstName: string, lastName: string): Observable<UserProfile> {
    return this.http.post<any>(environment.API_DOMAIN + '/user-profile', {
      firstName,
      lastName,
    });
  }

  updateEmail(email: string, password: string): Observable<UserProfile> {
    return this.http
      .post<UserProfile>(environment.API_DOMAIN + '/user-profile/email', {
        email,
        password,
      })
      .pipe(
        map((dto) => ({
          ...dto,
          fullName: `${dto.firstName} ${dto.lastName}`,
        })),
      );
  }

  changePassword(
    oldPassword: string,
    password: string,
    confirmPassword: string,
  ): Observable<void> {
    return this.http.post<void>(
      environment.API_DOMAIN + '/user-profile/password',
      {
        oldPassword,
        password,
        confirmPassword,
      },
    );
  }
  updateAvatar(avatar: string): Observable<void> {
    return this.http.patch<void>(environment.API_DOMAIN + '/user-profile', {
      avatar,
    });
  }

  checkPassword(password: string): Observable<void> {
    return this.http.post<void>(
      environment.API_DOMAIN + '/user-profile/password',
      {
        password,
      },
    );
  }

  getSecuredImage(url: string): Observable<ArrayBuffer | string | null> {
    return new Observable<ArrayBuffer | string | null>((obs) => {
      this.http
        .get(url, {
          responseType: 'blob',
        })
        .subscribe((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            obs.next(
              reader.result
                ? (reader.result as string).replace(
                    /(data:)application\/json(.*)/,
                    '$1application/jpg$2',
                  )
                : null,
            );
            obs.complete();
          };
          reader.onerror = (err) => {
            console.error('Load image error');
            obs.error('Load image error');
            obs.complete();
          };
          reader.readAsDataURL(blob);
        });
    });
  }
}
