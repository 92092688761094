import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from '@core/models/category.model';
import { environment } from '@env/environment';
import { CreateCategory } from '@core/models/create-category.model';
import { UserShort } from '@core/models/user-short.model';
import { CreateContact } from '@core/models/create-contact.model';
import { Contact } from '@core/models/contact.model';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(private http: HttpClient) {}

  getContacts(companyId: string): Observable<UserShort[]> {
    return this.http.get<UserShort[]>(
      `${environment.API_DOMAIN}/company/${companyId}/contacts`,
    );
  }
  getContactsByGroup(
    companyId: string,
    groupId: string,
  ): Observable<Contact[]> {
    return this.http.get<Contact[]>(
      `${environment.API_DOMAIN}/company/${companyId}/contacts/group/${groupId}`,
    );
  }

  searchFyioExternalContact(
    companyId: string,
    email: string,
  ): Observable<Contact> {
    return this.http.post<Contact>(
      `${environment.API_DOMAIN}/company/${companyId}/contacts/external-search`,
      {
        email,
      },
    );
  }

  createContact(
    companyId: string,
    payload: CreateContact,
  ): Observable<UserShort> {
    return this.http.post<UserShort>(
      `${environment.API_DOMAIN}/company/${companyId}/contacts`,
      payload,
    );
  }

  updateContact(
    id: string,
    companyId: string,
    payload: Partial<CreateContact>,
  ): Observable<UserShort> {
    return this.http.put<UserShort>(
      `${environment.API_DOMAIN}/company/${companyId}/contacts/${id}`,
      payload,
    );
  }

  deleteContact(id: string, companyId: string): Observable<UserShort> {
    return this.http.delete<UserShort>(
      `${environment.API_DOMAIN}/company/${companyId}/contacts/${id}`,
    );
  }
}
