<div class="dv-table-cell">
  <i
    [attr.title]="icon?.tooltip"
    class="icon {{ render() }}"
    [class.size-xs]="icon?.size === 'xs'"
    [class.size-sm]="icon?.size === 'sm'"
    [class.size-md]="icon?.size === 'md'"
    [class.size-lg]="icon?.size === 'lg'"
    [class.color-primary]="icon?.color === 'primary'"
    [class.color-accent]="icon?.color === 'accent'"
    [class.color-success]="icon?.color === 'success'"
    [class.color-error]="icon?.color === 'error'"
    [class.color-warning]="icon?.color === 'warning'"
    [class.color-inactive]="icon?.color === 'inactive'"
  ></i>
</div>
