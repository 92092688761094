import { NgModule } from '@angular/core';
import { ContactComponent } from '@ui/components/common/contact/contact.component';
import { CommonModule } from '@angular/common';
import { FileInfoComponent } from '@ui/components/common/file-info/file-info.component';
import { UserInfoWidgetComponent } from '@ui/components/common/user-info-widget/user-info-widget.component';
import { PrimengUiModule } from '@ui/primeng/primeng-ui.module';
import { CoreModule } from '@core/core.module';
import { SearchFieldComponent } from '@ui/components/common/search-field/search-field.component';
import { SharedCommonModule } from '@common/shared/shared-common.module';

@NgModule({
  imports: [CoreModule, CommonModule, PrimengUiModule, SharedCommonModule],
  declarations: [
    ContactComponent,
    FileInfoComponent,
    UserInfoWidgetComponent,
    SearchFieldComponent,
  ],
  exports: [
    ContactComponent,
    FileInfoComponent,
    UserInfoWidgetComponent,
    SearchFieldComponent,
  ],
})
export class CommonUiComponentsModule {}
