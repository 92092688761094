import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  IconCellRendererComponent,
  ActionsCellRendererComponent,
  DatetimeCellRendererComponent,
  CheckboxCellRendererComponent,
  BooleanCellRendererComponent,
  DefaultCellRendererComponent,
  FormattedNumberCellRendererComponent,
  TimeDurationCellRendererComponent,
  HumanizeStatusCellRendererComponent,
  ImageCellRendererComponent,
  RouterLinkCellRendererComponent,
  PropertyCellRendererComponent,
} from './components/renderers';
import { AbstractDataTableComponent } from './components/abstract-data-table.component';
import { BaseCellRendererComponent } from './components/renderers/base-cell-renderer.component';
import { CommonModule } from '@angular/common';
import { PrimeDataTableComponent } from './components/prime-data-table/prime-data-table.component';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToolbarModule } from 'primeng/toolbar';
import { FormsModule } from '@angular/forms';
import { DataTableComponent } from '@ui/components/data-table/data-table.component';
import { PrimengUiModule } from '@ui/primeng/primeng-ui.module';
import { FileSizeCellRendererComponent } from './components/renderers/file-size-cell-renderer/file-size-cell-renderer.component';
import { CoreModule } from '@core/core.module';
import { EditableCellRendererComponent } from './components/renderers/editable-cell-renderer/editable-cell-renderer.component';
import { FormattedStringComponent } from './components/renderers/formatted-string/formatted-string.component';

@NgModule({
  declarations: [
    DataTableComponent,
    IconCellRendererComponent,
    ActionsCellRendererComponent,
    DatetimeCellRendererComponent,
    CheckboxCellRendererComponent,
    BooleanCellRendererComponent,
    DefaultCellRendererComponent,
    PrimeDataTableComponent,
    AbstractDataTableComponent,
    BaseCellRendererComponent,
    ImageCellRendererComponent,
    FormattedNumberCellRendererComponent,
    HumanizeStatusCellRendererComponent,
    TimeDurationCellRendererComponent,
    FileSizeCellRendererComponent,
    RouterLinkCellRendererComponent,
    PropertyCellRendererComponent,
    EditableCellRendererComponent,
    FormattedStringComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    DragDropModule,
    TableModule,
    MultiSelectModule,
    ToolbarModule,
    FormsModule,
    PrimengUiModule,
    CoreModule,
  ],
  exports: [DataTableComponent, PrimeDataTableComponent],
})
export class DataTableModule {}
