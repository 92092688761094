<ng-container *ngIf="companies">
  <div
    *ngFor="let company of companies"
    class="select-app-btn"
    [class.active]="active === company.id"
  >
    <div
      class="company-logo"
      (click)="onActiveCompanyChange(company)"
      [style.background-image]="'url(' + company.logoUrl + ')'"
    ></div>
  </div>
</ng-container>
<div class="add-btn" *ngxPermissionsOnly="['ACCOUNT_MODIFY']">
  <button
    pButton
    class="p-button-icon p-button-text p-button-secondary add-btn"
    icon="pi pi-plus"
    (click)="showAddCompany()"
  ></button>
</div>
<p-dialog
  *ngIf="company"
  modal="true"
  position="left"
  [draggable]="false"
  [focusOnShow]="true"
  [closeOnEscape]="false"
  header="Add Company"
  [style]="{ width: '450px', height: '100vw' }"
  [closable]="true"
  [(visible)]="addCompanyDialogVisible"
  (onHide)="onCancel()"
>
  <div class="w-full flex flex-column align-items-center">
    <app-upload-avatar-widget
      [(clear)]="clearImage"
      (selected)="onLogoSelected($event)"
    ></app-upload-avatar-widget>
    <div class="w-full">
      <app-add-company-form
        #companyForm
        [(model)]="company"
        (validate)="formValid = $event"
        (formInitialized)="onFormInit($event)"
      ></app-add-company-form>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button
      (click)="onCancel()"
      pButton
      pRipple
      class="p-button-outlined"
      label="Cancel"
    ></button>
    <button
      [disabled]="!logoFile || (processing$ | async)"
      pButton
      pRipple
      class=""
      iconPos="left"
      [icon]="(processing$ | async) ? 'pi pi-spin pi-spinner' : 'pi pi-plus'"
      label="Add"
      (click)="onSubmit()"
    ></button>
  </ng-template>
</p-dialog>
<p-toast position="bottom-right"></p-toast>
