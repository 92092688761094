<form *ngIf="form" [formGroup]="form" class="change-email-form-content">
  <div class="p-2 w-full">
    <app-form-fields-wrapper [showLabels]="true">
      <input
        *appFormField="'email'; label: 'Email'"
        formControlName="email"
        type="text"
        placeholder="Enter email"
        pInputText
        class="w-full mb-1"
      />
      <input
        *appFormField="'password'; label: 'Current Password'"
        formControlName="password"
        type="password"
        placeholder="Enter your password"
        pInputText
        class="w-full mb-1"
      />
    </app-form-fields-wrapper>
  </div>
</form>
