import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileExtension',
})
export class FileExtensionPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const split = value.split('.');
    return split[split.length - 1];
  }
}
