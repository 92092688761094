import { UserProfile } from '@core/models';

export class GetUserProfile {
  public static readonly type = '[Profile] Get User Profile';
  constructor() {}
}

export class UserProfileLoaded {
  public static readonly type = '[Profile] User Profile Loaded';
  constructor(public payload: UserProfile) {}
}

export class UpdateUserName {
  public static readonly type = '[Profile] User Profile Update Name';
  constructor(public payload: { firstName: string; lastName: string }) {}
}

export class UpdateEmail {
  public static readonly type = '[Profile] User Profile Update Email';
  constructor(public payload: { email: string; password: string }) {}
}

export class ChangePassword {
  public static readonly type = '[Profile] User Profile Change Password';
  constructor(
    public payload: {
      oldPassword: string;
      password: string;
      confirmPassword: string;
    },
  ) {}
}
export class UpdateAvatar {
  public static readonly type = '[Profile] Update Avatar';
  constructor(public payload: File) {}
}
