import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import {
  DocumentShareRequest,
  DocumentShareResponse,
  PaginatedResponse,
} from '@core/models';
import { CreateDocumentShareRequest } from '@core/models/create-document-share-request.model';
import { DocumentRequestStatus } from '@core/enums/document-request-status.enum';

@Injectable({
  providedIn: 'root',
})
export class RequestsApiService {
  constructor(private http: HttpClient, private logger: NGXLogger) {}

  getOutgoingRequests(
    companyId: string,
    page = 1,
    pageSize = 50,
    sortBy = '',
    order = 'asc',
    where?: Record<string, string>,
  ): Observable<PaginatedResponse<DocumentShareRequest>> {
    const query: string = where
      ? `${Object.entries(where)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`
      : '';
    return this.http.get<PaginatedResponse<DocumentShareRequest>>(
      `${environment.API_DOMAIN}/company/${companyId}/requests?page=${page}&size=${pageSize}&sortBy=${sortBy}&order=${order}&${query}`,
    );
  }

  getPendingDocuments(
    companyId: string,
    page = 1,
    pageSize = 50,
    sortBy = '',
    order = 'asc',
    where?: Record<string, string>,
  ): Observable<PaginatedResponse<DocumentShareResponse>> {
    const query: string = where
      ? `${Object.entries(where)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`
      : '';
    return this.http.get<PaginatedResponse<DocumentShareResponse>>(
      `${environment.API_DOMAIN}/company/${companyId}/requests/pending?page=${page}&size=${pageSize}&sortBy=${sortBy}&order=${order}&${query}`,
    );
  }

  createDocumentRequest(
    companyId: string,
    payload: CreateDocumentShareRequest,
  ): Observable<DocumentShareRequest> {
    return this.http.post<DocumentShareRequest>(
      `${environment.API_DOMAIN}/company/${companyId}/requests`,
      payload,
    );
  }

  updateStatusOfPendingResponse(
    companyId: string,
    id: string,
    approve: boolean,
  ): Observable<unknown> {
    return this.http.patch(
      `${environment.API_DOMAIN}/company/${companyId}/requests/${id}`,
      {
        responseStatus: approve
          ? DocumentRequestStatus.Approved
          : DocumentRequestStatus.Declined,
      },
    );
  }

  resendDocumentRequest(companyId: string, id: string): Observable<unknown> {
    return this.http.post(
      `${environment.API_DOMAIN}/company/${companyId}/requests/${id}`,
      {},
    );
  }
}
