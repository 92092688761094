import { Optional, Pipe, PipeTransform } from '@angular/core';
import { SearchFilterService } from '@common/shared/services/search-filter.service';
import { Observable, of, switchMap } from 'rxjs';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  constructor(@Optional() private search?: SearchFilterService) {}

  transform(
    value: any[] | Observable<any[]>,
    fields: string[],
  ): Observable<any[]> {
    if (!value) {
      return of([]);
    }
    if (!this.search) {
      return value instanceof Observable ? value : of(value);
    }
    if (value instanceof Observable) {
      return value.pipe(
        switchMap(
          (data) => this.search?.filter(data, fields) as Observable<any[]>,
        ),
      );
    } else {
      return this.search?.filter(value, fields) as Observable<any[]>;
    }
  }
}
