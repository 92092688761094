import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { Nullable } from '@core/interfaces/nullable';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchFieldComponent),
      multi: true,
    },
  ],
})
export class SearchFieldComponent
  implements OnInit, OnDestroy, OnChanges, ControlValueAccessor
{
  @HostBinding('class')
  className = 'search-field';

  @Input() placeholder = 'Search';

  @Input()
  @HostBinding('class.disabled')
  disabled = false;

  @Input() debounceTime? = 150;

  @Output() search = new EventEmitter<Nullable<string>>();

  @HostBinding('class.search-field') hostClass = true;

  @HostBinding('class.focused')
  isFocused = false;

  searchControl = new FormControl<Nullable<string>>(null);

  private onChange!: (val: Nullable<string>) => void;
  private onTouch!: (val: Nullable<string>) => void;
  private destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(this.debounceTime as number),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((value: Nullable<string>) => {
        this.search.emit(value);
        if (typeof this.onChange === 'function') {
          this.onChange(value);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      const action = this.disabled ? ('disable' as const) : ('enable' as const);
      this.searchControl[action]({ emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearSearch() {
    this.searchControl.reset();
  }

  /*-- ControlValueAccessor --*/

  writeValue(val: Nullable<string>): void {
    this.searchControl.setValue(val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
