import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseFormDirective } from '@ui/forms/base-form.component';
import { Category } from '@core/models/category.model';
import { CategoryFormModel } from '@core/models';

@Component({
  selector: 'app-add-category-form',
  templateUrl: './add-category-form.component.html',
  styleUrls: ['./add-category-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCategoryFormComponent extends BaseFormDirective<CategoryFormModel> {
  @Input() categoryPath!: string;

  override initForm(model?: CategoryFormModel): void {
    this.form = this.fb.group({
      id: this.fb.control(model?.id),
      parentId: this.fb.control(model?.parentId),
      name: this.fb.control(model?.name, [Validators.required]),
      description: this.fb.control(model?.description, [Validators.required]),
      logoUrl: this.fb.control(model?.logoUrl),
    });
  }
}
