import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { NgxsModule } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { ContactsStoreService } from '@store/contacts/contacts-store.service';
import { ContactsState } from '@store/contacts/contacts.state';

@NgModule({
  imports: [CoreModule, CommonModule, NgxsModule.forFeature([ContactsState])],
  exports: [],
  providers: [ContactsStoreService, ContactsState],
})
export class ContactsStoreModule {}
