import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesRoutingModule } from './pages-routing.module';
import { UiModule } from '@ui/ui.module';
import { CoreModule } from '@app/core/core.module';
import { SharedCommonModule } from '@common/shared/shared-common.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreModule,
    PagesRoutingModule,
    UiModule,
    SharedCommonModule,
  ],
})
export class PagesModule {}
