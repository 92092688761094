import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DataTableModule } from '@ui/components/data-table/data-table.module';
import { UpdateEmailDialogComponent } from './update-email-dialog/update-email-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UiFormsModule } from '@ui/forms/ui-forms.module';
import { PrimengUiModule } from '@ui/primeng/primeng-ui.module';
import { CompaniesBarComponent } from './companies-bar/companies-bar.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DialogsModule } from '@ui/components/dialogs/dialogs.module';
import { CompanyFormsModule } from '@ui/forms/company/company-forms.module';
import { UploadAvatarWidgetComponent } from './upload-avatar-widget/upload-avatar-widget.component';
import { CoreModule } from '@core/core.module';
import { DocumentPreviewWidgetModule } from '@ui/components/document-preview-widget/document-preview-widget.module';
import { DocumentRequestDetailsComponent } from '@ui/components/preview-document/document-request-details/document-request-details.component';
import { UploadSingleDocumentComponent } from '@ui/components/upload-documents/upload-single-document/upload-single-document.component';
import { UploadBulkDocumentsComponent } from '@ui/components/upload-documents/upload-bulk-documents/upload-bulk-documents.component';
import { BaseUploadDocumentsDirective } from './upload-documents/base-upload-documents.directive';
import { ShareDocumentComponent } from '@ui/components/share-document/share-document.component';
import { PreviewDocumentComponent } from '@ui/components/preview-document/preview-document.component';
import { DocumentShareDetailsComponent } from './preview-document/document-share-details/document-share-details.component';
import { DocumentDetailsComponent } from './preview-document/document-details/document-details.component';
import { CommonUiComponentsModule } from '@ui/components/common/common-ui-components.module';
import { AngularCdkModule } from '@ui/cdk/angular-cdk.module';
import { SharedCommonModule } from '@common/shared/shared-common.module';
import { SharedWithListComponent } from '@ui/components/shared-with-list/shared-with-list.component';
import { AddClientComponent } from '@ui/components/contacts/add-client/add-client.component';
import { AddGroupComponent } from '@ui/components/contacts/add-group/add-group.component';
import { SharingProgressWidgetComponent } from './sharing-progress-widget/sharing-progress-widget.component';
import { SharingProgressItemComponent } from './sharing-progress-widget/sharing-progress-item/sharing-progress-item.component';

@NgModule({
  declarations: [
    UpdateEmailDialogComponent,
    CompaniesBarComponent,
    UploadAvatarWidgetComponent,
    DocumentRequestDetailsComponent,
    UploadSingleDocumentComponent,
    UploadBulkDocumentsComponent,
    BaseUploadDocumentsDirective,
    ShareDocumentComponent,
    PreviewDocumentComponent,
    DocumentShareDetailsComponent,
    DocumentDetailsComponent,
    SharedWithListComponent,
    AddClientComponent,
    AddGroupComponent,
    SharingProgressWidgetComponent,
    SharingProgressItemComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiFormsModule,
    PrimengUiModule,
    NgxPermissionsModule.forChild(),
    DialogsModule,
    CompanyFormsModule,
    CoreModule,
    DocumentPreviewWidgetModule,
    SharedCommonModule,
    CommonUiComponentsModule,
    AngularCdkModule,
    DataTableModule,
    NgOptimizedImage,
  ],
  exports: [
    DataTableModule,
    CompaniesBarComponent,
    DialogsModule,
    UploadAvatarWidgetComponent,
    DocumentPreviewWidgetModule,
    DocumentRequestDetailsComponent,
    UploadSingleDocumentComponent,
    UploadBulkDocumentsComponent,
    ShareDocumentComponent,
    PreviewDocumentComponent,
    CommonUiComponentsModule,
    AddClientComponent,
    AddGroupComponent,
    SharingProgressWidgetComponent,
  ],
})
export class UiComponentsModule {}
