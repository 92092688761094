import { NgModule } from '@angular/core';
import { PrimengUiModule } from '@ui/primeng/primeng-ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedCommonModule } from '@common/shared/shared-common.module';
import { FormElementsUiModule } from '@ui/forms/elements/form-elements-ui.module';
import { AuthFormsUiModule } from '@ui/forms/auth/auth-forms-ui.module';
import { CompanyFormsModule } from '@ui/forms/company/company-forms.module';
import { ProfileFormsModule } from '@ui/forms/profile/profile-forms.module';
import { AngularCdkModule } from '@ui/cdk/angular-cdk.module';

@NgModule({
  declarations: [],
  providers: [],
  exports: [AuthFormsUiModule, FormElementsUiModule],
  imports: [
    PrimengUiModule,
    ReactiveFormsModule,
    FormsModule,
    SharedCommonModule,
    FormElementsUiModule,
    AuthFormsUiModule,
    CompanyFormsModule,
    ProfileFormsModule,
    AngularCdkModule,
  ],
})
export class UiFormsModule {}
