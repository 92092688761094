<p-dialog
  modal="true"
  position="right"
  [draggable]="false"
  [focusOnShow]="true"
  [closeOnEscape]="false"
  [header]="contactModel?.id ? 'Update Contact' : 'Create Contact'"
  [style]="{ width: '450px', height: '100vw' }"
  [closable]="true"
  [(visible)]="show"
  (onHide)="closed.emit()"
>
  <div class="w-full h-full grid">
    <app-add-contact-form
      #contactForm
      [groups]="groups"
      [(model)]="contactModel"
      (validate)="formValid = $event"
      (formInitialized)="onFormInit($event)"
    >
    </app-add-contact-form>
  </div>
  <ng-template pTemplate="footer">
    <button
      (click)="closed.emit()"
      pButton
      pRipple
      class="p-button-outlined"
      label="Cancel"
    ></button>
    <button
      [disabled]="processing$ | async"
      pButton
      pRipple
      class=""
      iconPos="left"
      [icon]="
        (processing$ | async)
          ? 'pi pi-spin pi-spinner'
          : contactModel?.id
          ? 'pi pi-save'
          : 'pi pi-plus'
      "
      [label]="contactModel?.id ? 'Update' : 'Add'"
      (click)="onSubmit()"
    ></button>
  </ng-template>
</p-dialog>
