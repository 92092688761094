import { Nullable } from '@core/interfaces/nullable';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { interval, Observable, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { Company } from '@core/models/company.model';
import {
  ChangeActiveCompany,
  ClearCompanyErrors,
  CompanyCreated,
  CompanyDeleted,
  CompanyUpdated,
  CreateCompany,
  DeleteCompany,
  GetCategories,
  GetClientCategories,
  GetCompanies,
  UpdateCompany,
} from '@store/companies/companies.actions';
import { PublicAssetsUploadService } from '@common/shared/services/public-assets-upload.service';
import { CompaniesService } from '@core/services/api/companies.service';
import { UploadedFile } from '@core/models/uploaded-file.model';
import { CategoryShort } from '@core/models';
import { Logout } from '@store/auth/auth.actions';

export interface CompaniesStateModel {
  companies: Nullable<Company[]>;
  created: Nullable<Company>;
  updated: Nullable<Company>;
  error: any;
  active: Nullable<Company>;
  categories: Nullable<CategoryShort[]>;
  clientCategories: Nullable<CategoryShort[]>;
}

@State<CompaniesStateModel>({
  name: 'companies',
  defaults: {
    companies: null,
    created: null,
    updated: null,
    error: null,
    active: null,
    categories: null,
    clientCategories: null,
  },
})
@Injectable()
export class CompaniesState {
  constructor(
    private companiesService: CompaniesService,
    private uploader: PublicAssetsUploadService,
  ) {}

  @Selector()
  static getState(state: CompaniesStateModel): CompaniesStateModel {
    return state;
  }

  @Selector()
  static getCompanies(state: CompaniesStateModel): Nullable<Company[]> {
    return state.companies;
  }

  @Selector()
  static getCategories(state: CompaniesStateModel): Nullable<CategoryShort[]> {
    return state.categories;
  }

  @Selector()
  static getClientCategories(
    state: CompaniesStateModel,
  ): Nullable<CategoryShort[]> {
    return state.clientCategories;
  }

  @Selector()
  static getCompany(id: string): any {
    return createSelector([CompaniesState], (state: any): Nullable<Company> => {
      return state?.companies?.companies?.find((c: Company) => c.id === id);
    });
  }

  @Selector()
  static getActive(state: CompaniesStateModel): Nullable<Company> {
    return state.active || (state.companies ? state.companies[0] : null);
  }

  @Selector()
  static getCreated(state: CompaniesStateModel): Nullable<Company> {
    return state.created;
  }

  @Selector()
  static getUpdated(state: CompaniesStateModel): Nullable<Company> {
    return state.updated;
  }

  @Selector()
  static getError(state: CompaniesStateModel): Nullable<Company> {
    return state.error;
  }

  @Action(CreateCompany)
  create(
    ctx: StateContext<CompaniesStateModel>,
    action: CreateCompany,
  ): Observable<void> {
    ctx.patchState({
      created: null,
    });
    return this.uploader.upload(action.payload.logoFile).pipe(
      take(1),
      switchMap((file: UploadedFile) => {
        return this.companiesService.createCompany({
          ...action.payload.company,
          logoUrl: file.path,
        });
      }),
      switchMap((resp) => ctx.dispatch(new CompanyCreated(resp))),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(UpdateCompany)
  update(
    ctx: StateContext<CompaniesStateModel>,
    action: UpdateCompany,
  ): Observable<void> {
    ctx.patchState({
      updated: null,
    });
    let source$: Observable<any>;
    if (action.payload.logoFile) {
      source$ = this.uploader.upload(action.payload.logoFile).pipe(take(1));
    } else {
      source$ = interval(0).pipe(take(1));
    }
    return source$.pipe(
      take(1),
      switchMap((file: UploadedFile | any) => {
        return this.companiesService.updateCompany(action.payload.id, {
          ...action.payload.company,
          logoUrl: file?.path ? file.path : action.payload.company.logoUrl,
        });
      }),
      switchMap((resp: Company) => ctx.dispatch(new CompanyUpdated(resp))),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(DeleteCompany)
  delete(
    ctx: StateContext<CompaniesStateModel>,
    action: UpdateCompany,
  ): Observable<void> {
    return this.companiesService.deleteCompany(action.payload.id).pipe(
      take(1),
      switchMap(() => ctx.dispatch(new CompanyDeleted(action.payload.id))),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(CompanyCreated)
  companyCreated(
    ctx: StateContext<CompaniesStateModel>,
    action: CompanyCreated,
  ): Observable<any> {
    ctx.patchState({
      created: action.payload,
    });
    return ctx.dispatch(new GetCompanies());
  }

  @Action(CompanyUpdated)
  companyUpdated(
    ctx: StateContext<CompaniesStateModel>,
    action: CompanyUpdated,
  ): Observable<any> {
    ctx.patchState({
      updated: action.payload,
    });
    return ctx.dispatch(new GetCompanies());
  }

  @Action(CompanyDeleted)
  companyDeleted(
    ctx: StateContext<CompaniesStateModel>,
    action: CompanyDeleted,
  ): Observable<any> {
    const companies = ctx.getState()?.companies || [];
    ctx.patchState({
      updated: null,
      active: companies.find((c) => c.id !== action.id),
    });
    return ctx.dispatch(new GetCompanies());
  }

  @Action(ChangeActiveCompany)
  changeActive(
    ctx: StateContext<CompaniesStateModel>,
    action: ChangeActiveCompany,
  ): void {
    const found = ctx
      .getState()
      .companies?.find((c) => c.id === action.payload.id);
    ctx.patchState({
      active: found,
    });
  }

  @Action(GetCompanies)
  getCompanies(
    ctx: StateContext<CompaniesStateModel>,
    action: CompanyCreated,
  ): Observable<any> {
    return this.companiesService.getCompanies().pipe(
      tap((data) => {
        ctx.patchState({
          companies: data,
        });
        const active: Nullable<Company> =
          data && data.length > 0 ? data[0] : null;
        if (!ctx.getState().active) {
          ctx.patchState({
            active,
          });
        } else if (active) {
          ctx.patchState({
            active: {
              ...ctx.getState().active,
              name: active.name,
              description: active.description,
              email: active.email,
              logoUrl: active.logoUrl,
            } as Company,
          });
        }
      }),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(GetCategories)
  getCategories(
    ctx: StateContext<CompaniesStateModel>,
    action: GetCategories,
  ): Observable<any> {
    return this.companiesService.getCategories(action.companyId).pipe(
      tap((data) => {
        ctx.patchState({
          categories: data,
        });
      }),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(GetClientCategories)
  getClientCategories(
    ctx: StateContext<CompaniesStateModel>,
    action: GetClientCategories,
  ): Observable<any> {
    return this.companiesService.getClientCategories(action.companyId).pipe(
      tap((data) => {
        ctx.patchState({
          clientCategories: data,
        });
      }),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(ClearCompanyErrors)
  clearErrors(ctx: StateContext<CompaniesStateModel>): void {
    ctx.patchState({
      error: null,
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<CompaniesStateModel>): void {
    ctx.setState({
      active: null,
      companies: null,
      categories: null,
      updated: null,
      error: null,
      created: null,
      clientCategories: null,
    });
  }
}
