<div
  class="categories-selector-field"
  [style.width]="width"
  [attr.data-qa]="'categories-dropdown-selector'"
  (click)="op.toggle($event)"
>
  <input
    pInputText
    type="text"
    [readonly]="true"
    placeholder="Category"
    [value]="selectedText"
  />
  <i class="pi pi-chevron-{{ isOpened ? 'up' : 'down' }}"></i>
</div>
<p-overlayPanel
  #op
  styleClass="categories-dropdown"
  (onShow)="isOpened = true"
  (onHide)="isOpened = false"
>
  <div class="w-full flex align-items-center flex-column">
    <p-tree
      [filter]="true"
      [filterMode]="'strict'"
      [value]="categories | treeNodes"
      [selectionMode]="'single'"
      (selectionChange)="onTreeItemSelected($event)"
    >
      <ng-template let-node pTemplate="default">
        <div
          class="flex align-items-center justify-content-between w-full"
          [attr.data-qa]="'categories-dropdown-item'"
          [attr.data-id]="node.id"
        >
          <div class="node-label flex align-items-center">
            <p-avatar
              *ngIf="node.data.logoUrl"
              size="small"
              [image]="node.data.logoUrl"
              [shape]="'circle'"
            ></p-avatar>
            <div class="ml-2">{{ node.label }}</div>
          </div>
          <div class="flex justify-content-end">
            <p-checkbox
              *ngIf="selected.isMultipleSelection()"
              [value]="node.data.id"
              [(ngModel)]="selectedValue"
              (click)="$event.preventDefault()"
            >
            </p-checkbox>
            <p-radioButton
              *ngIf="!selected.isMultipleSelection()"
              name="category"
              [value]="node.data.id"
              [(ngModel)]="selectedValue"
              (click)="$event.preventDefault()"
            >
            </p-radioButton>
          </div>
        </div>
      </ng-template>
    </p-tree>
    <div
      *ngIf="selected.isMultipleSelection()"
      class="dropdown-actions"
      [attr.data-qa]="'categories-dropdown-actions'"
    >
      <button
        pButton
        class="p-button-outlined cancel-btn"
        (click)="onCancel(); op.hide()"
      >
        Cancel
      </button>
      <button
        pButton
        class="p-button-primary ml-2 apply-btn"
        (click)="onApply($event); op.hide()"
      >
        Apply
      </button>
    </div>
  </div>
</p-overlayPanel>
