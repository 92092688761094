<form *ngIf="form" [formGroup]="form" class="profile-form-content">
  <div class="p-2 w-full">
    <app-form-fields-wrapper [showLabels]="true">
      <input
        *appFormField="'avatar'"
        formControlName="avatar"
        type="hidden"
        pInputText
      />
      <input
        *appFormField="'id'"
        formControlName="id"
        type="hidden"
        pInputText
      />
      <input
        *appFormField="'firstName'; label: 'First Name'"
        formControlName="firstName"
        type="text"
        placeholder="First Name"
        pInputText
        class="w-full mb-1"
      />
      <input
        *appFormField="'lastName'; label: 'Last Name'"
        formControlName="lastName"
        type="text"
        placeholder="Last Name"
        pInputText
        class="w-full mb-1"
      />
    </app-form-fields-wrapper>
  </div>
</form>
