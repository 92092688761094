import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { NGXLogger } from 'ngx-logger';
import { FileUpload } from 'primeng/fileupload';
import { ReplaySubject, Subject, combineLatest, switchMap } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { checkImage } from '@common/shared/utils/check-image';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-upload-avatar-widget',
  templateUrl: './upload-avatar-widget.component.html',
  styleUrls: ['./upload-avatar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class UploadAvatarWidgetComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  @ViewChild('uploader', { read: FileUpload }) uploader!: FileUpload;
  @ViewChild('utilImage', { read: ElementRef }) utilImage!: ElementRef;

  @Input() set clear(value: boolean) {
    if (value) {
      this.viewReady$.pipe(take(1), untilDestroyed(this)).subscribe(() => {
        this.uploader.clear();
        this.uploadedImage = null;
        this.clearChange.emit(false);
        this.cdr.markForCheck();
      });
    }
  }

  @Input() defaultAvatar!: string;

  @Input() set avatar(avatar: string) {
    this.uploadedImage = null;
    this.avatarValue = avatar;
    this.avatarReady$.next(avatar);
  }

  get avatar(): string {
    return this.avatarValue;
  }

  @Output() clearChange = new EventEmitter<boolean>();

  @Output() selected = new EventEmitter<File>();

  uploadedImage!: string | null;
  imgSize = {
    width: 0,
    height: 0,
  };

  private avatarValue!: string;

  private reader!: FileReader;
  private loaded: any;
  private error: any;
  private viewReady$ = new ReplaySubject<boolean>(1);
  private viewChecked$ = new ReplaySubject<boolean>(1);
  private avatarReady$ = new ReplaySubject<string>(1);

  constructor(
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private logger: NGXLogger,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngOnInit(): void {
    this.loaded = this.onLoaded.bind(this);
    this.error = this.onError.bind(this);

    combineLatest([this.avatarReady$, this.viewReady$])
      .pipe(
        untilDestroyed(this),
        switchMap(([avatar, _]) =>
          checkImage(avatar, this.renderer, this.el.nativeElement),
        ),
        take(1),
      )
      .subscribe((res) => {
        if (!res) {
          this.avatarValue = this.defaultAvatar;
          this.cdr.markForCheck();
        }
      });
    this.viewChecked$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.utilImage?.nativeElement) {
        this.imgSize = {
          width: this.utilImage.nativeElement.naturalWidth,
          height: this.utilImage.nativeElement.naturalHeight,
        };
        this.cdr.detectChanges();
      }
    });
  }

  ngAfterViewInit(): void {
    this.viewReady$.next(true);
  }
  ngAfterViewChecked(): void {
    this.viewChecked$.next(true);
  }

  onSelect(event: any): void {
    this.reader = new FileReader();
    this.reader.onload = this.loaded;
    this.reader.onerror = this.error;
    if (event.currentFiles && event.currentFiles.length > 0) {
      this.reader.readAsDataURL(event.currentFiles[0]);
      this.selected.emit(event.currentFiles[0]);
    }
  }

  onLoaded(event: any): void {
    this.uploadedImage = this.reader.result as string;
    this.uploader.clear();
    setTimeout(() => {
      this.cdr.markForCheck();
    }, 10);
  }

  onError(error: any): void {
    this.messageService.addAll([
      {
        severity: 'error',
        detail: error.message,
      },
    ]);
    this.logger.error('UploadAvatarWidgetComponent.onError', error);
    this.uploader.clear();
    this.cdr.markForCheck();
  }
}
