import { Nullable } from '@core/interfaces/nullable';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  DocumentShareRequest,
  DocumentShareResponse,
  PaginatedResponse,
} from '@core/models';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import {
  ApprovePendingRequest,
  ClearRequestsErrors,
  CreateDocumentRequest,
  DeclinePendingRequest,
  DocumentRequestCreated,
  GetOutgoingRequests,
  GetPendingRequests,
} from '@store/requests/requests.actions';
import { RequestsApiService } from '@core/services/api/requests-api.service';
import * as sha256 from 'sha256';

export interface RequestsStateModel {
  outgoing: Record<string, Nullable<PaginatedResponse<DocumentShareRequest>>>;
  pending: Record<string, Nullable<PaginatedResponse<DocumentShareResponse>>>;
  created: Nullable<boolean>;
  updated: Nullable<DocumentShareResponse>;
  error: any;
}

@State<RequestsStateModel>({
  name: 'requests',
  defaults: {
    outgoing: {},
    pending: {},
    created: null,
    updated: null,
    error: null,
  },
})
@Injectable()
export class RequestsState {
  constructor(private requestsService: RequestsApiService) {}

  @Selector()
  static getState(state: RequestsStateModel): RequestsStateModel {
    return state;
  }

  @Selector()
  static selectOutgoingRequests(where: Nullable<Record<string, string>>) {
    return createSelector([RequestsState], (state) => {
      const key = where ? sha256(`${JSON.stringify(where)}`) : 'default';
      return state.requests.outgoing[key];
    });
  }

  @Selector()
  static selectPendingDocuments(where: Nullable<Record<string, string>>) {
    return createSelector([RequestsState], (state) => {
      const key = where ? sha256(`${JSON.stringify(where)}`) : 'default';
      return state.requests.pending[key];
    });
  }

  @Selector()
  static getCreated(state: RequestsStateModel): Nullable<boolean> {
    return state.created;
  }

  @Selector()
  static getError(state: RequestsStateModel): Nullable<DocumentShareRequest> {
    return state.error;
  }

  @Action(GetOutgoingRequests)
  getRequests(
    ctx: StateContext<RequestsStateModel>,
    action: GetOutgoingRequests,
  ): Observable<any> {
    const key = action.payload.where
      ? sha256(`${JSON.stringify(action.payload.where)}`)
      : 'default';
    return this.requestsService
      .getOutgoingRequests(
        action.payload.companyId,
        action.payload.page || 1,
        action.payload.pageSize || 30,
        action.payload.sortBy || 'requestedAt',
        action.payload.order || 'desc',
        action.payload.where || {},
      )
      .pipe(
        tap((data) => {
          ctx.patchState({
            outgoing: {
              ...ctx.getState().outgoing,
              [key]: { ...data },
            },
          });
        }),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(GetPendingRequests)
  getPendingDocuments(
    ctx: StateContext<RequestsStateModel>,
    action: GetPendingRequests,
  ): Observable<any> {
    const key = action.payload.where
      ? sha256(`${JSON.stringify(action.payload.where)}`)
      : 'default';
    return this.requestsService
      .getPendingDocuments(
        action.payload.companyId,
        action.payload.page || 1,
        action.payload.pageSize || 30,
        action.payload.sortBy || '',
        action.payload.order || 'desc',
        action.payload.where || {},
      )
      .pipe(
        tap((data) => {
          ctx.patchState({
            pending: {
              ...ctx.getState().pending,
              [key]: { ...data },
            },
          });
        }),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(CreateDocumentRequest)
  create(
    ctx: StateContext<RequestsStateModel>,
    action: CreateDocumentRequest,
  ): Observable<void> {
    ctx.patchState({
      created: null,
    });
    return this.requestsService
      .createDocumentRequest(action.companyId, action.payload)
      .pipe(
        switchMap((resp) =>
          ctx.dispatch(new DocumentRequestCreated(action.companyId, resp)),
        ),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(ApprovePendingRequest)
  approve(
    ctx: StateContext<RequestsStateModel>,
    action: ApprovePendingRequest,
  ): Observable<void> {
    return this.requestsService
      .updateStatusOfPendingResponse(action.companyId, action.requestId, true)
      .pipe(
        switchMap(() =>
          ctx.dispatch(
            new GetPendingRequests({
              companyId: action.companyId,
              page: 1,
              pageSize: 30,
              sortBy: 'respondedAt',
              order: 'desc',
            }),
          ),
        ),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(DeclinePendingRequest)
  decline(
    ctx: StateContext<RequestsStateModel>,
    action: DeclinePendingRequest,
  ): Observable<void> {
    return this.requestsService
      .updateStatusOfPendingResponse(action.companyId, action.requestId, false)
      .pipe(
        switchMap(() =>
          ctx.dispatch(
            new GetPendingRequests({
              companyId: action.companyId,
              page: 1,
              pageSize: 30,
              sortBy: 'respondedAt',
              order: 'desc',
            }),
          ),
        ),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(DocumentRequestCreated)
  requestCreated(
    ctx: StateContext<RequestsStateModel>,
    action: DocumentRequestCreated,
  ): void {
    ctx.patchState({
      created: true,
    });
    // return ctx.dispatch(
    //   new GetOutgoingRequests({
    //       companyId: action.companyId,
    //       page: 1,
    //       pageSize: 30,
    //       sortBy: 'requestedAt',
    //       order: 'desc',
    //       where: { status: `${DocumentRequestStatus.Pending},${DocumentRequestStatus.New}` },
    //     }
    //   ),
    // );
  }

  @Action(ClearRequestsErrors)
  clearErrors(ctx: StateContext<RequestsStateModel>): void {
    ctx.patchState({
      error: null,
    });
  }
}
