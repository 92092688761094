import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  CellRenderer,
  CellRenderOptions,
  TableRowData,
} from '../../interfaces';
import { CellInfoProvider } from '../../interfaces/cell-info-provider';
import { CELL_INFO_PROVIDER } from '../../utils';

@Component({
  selector: 'app-base-cell-renderer',
  templateUrl: './base-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseCellRendererComponent<V, R extends TableRowData>
  implements CellRenderer<V, R>
{
  constructor(
    @Inject(CELL_INFO_PROVIDER)
    private cellInfoProvider: CellInfoProvider<V, R>,
  ) {}

  getRawValue(): V | null {
    return this.cellInfoProvider.getRawValue();
  }

  getRow(): R | null {
    return this.cellInfoProvider.getRow();
  }

  getOptions(): CellRenderOptions {
    return this.cellInfoProvider.getOptions();
  }

  getFieldId(): string {
    return this.cellInfoProvider.getFieldId();
  }

  render(): string {
    throw new Error('You must override BaseCellRenderer.render method');
  }

  protected interpolate(str: string): string {
    const row: R | null = this.getRow();
    let res = str;
    if (row) {
      Object.keys(row).forEach((key) => {
        const reg = new RegExp('{{s?' + key + 's?}}');
        res = res.replace(reg, row[key] as string);
      });
    }
    return res;
  }
}
