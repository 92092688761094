import { Nullable } from '@core/interfaces/nullable';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ContactGroup } from '@core/models';
import { ContactGroupsService } from '@core/services/api';
import {
  AddContactGroup,
  ClearContactGroupsErrors,
  ContactGroupCreated,
  ContactGroupUpdated,
  DeleteContactGroup,
  GetContactGroups,
  GetGroupCountersAction,
  UpdateContactGroup,
} from '@store/contact-groups/contact-groups.actions';

export interface ContactGroupsStateModel {
  list: Nullable<ContactGroup[]>;
  created: Nullable<ContactGroup>;
  updated: Nullable<ContactGroup>;
  counters: Nullable<Record<string, number>>;
  error: any;
}

@State<ContactGroupsStateModel>({
  name: 'contact_groups',
  defaults: {
    list: null,
    created: null,
    updated: null,
    error: null,
    counters: null,
  },
})
@Injectable()
export class ContactGroupsState {
  constructor(private contactsService: ContactGroupsService) {}

  @Selector()
  static getState(state: ContactGroupsStateModel): ContactGroupsStateModel {
    return state;
  }

  @Selector()
  static getCreated(state: ContactGroupsStateModel): Nullable<ContactGroup> {
    return state.created;
  }

  @Selector()
  static getUpdated(state: ContactGroupsStateModel): Nullable<ContactGroup> {
    return state.updated;
  }

  @Selector()
  static getError(state: ContactGroupsStateModel): Nullable<any> {
    return state.error;
  }

  @Selector()
  static getContacts(state: ContactGroupsStateModel): Nullable<ContactGroup[]> {
    return state.list;
  }

  @Selector()
  static getGroupCounters(
    state: ContactGroupsStateModel,
  ): Nullable<Record<string, number>> {
    return state.counters;
  }

  @Action(GetContactGroups)
  getContacts(
    ctx: StateContext<ContactGroupsStateModel>,
    action: GetContactGroups,
  ): Observable<any> {
    return this.contactsService.getContactGroups(action.companyId).pipe(
      tap((data) => {
        ctx.patchState({
          list: [...data],
        });
      }),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(GetGroupCountersAction)
  getGroupCounters(
    ctx: StateContext<ContactGroupsStateModel>,
    action: GetGroupCountersAction,
  ): Observable<any> {
    return this.contactsService.getGroupCounters(action.companyId).pipe(
      tap((data) => {
        ctx.patchState({
          counters: { ...data },
        });
      }),
      catchError((e) => {
        ctx.patchState({
          error: e,
        });
        return throwError(e);
      }),
    );
  }

  @Action(AddContactGroup)
  create(
    ctx: StateContext<ContactGroupsStateModel>,
    action: AddContactGroup,
  ): Observable<void> {
    ctx.patchState({
      created: null,
    });
    return this.contactsService
      .createContactGroup(action.companyId, action.payload)
      .pipe(
        switchMap((resp) =>
          ctx.dispatch(new ContactGroupCreated(action.companyId, resp)),
        ),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(UpdateContactGroup)
  update(
    ctx: StateContext<ContactGroupsStateModel>,
    action: UpdateContactGroup,
  ): Observable<void> {
    ctx.patchState({
      updated: null,
    });
    return this.contactsService
      .updateContactGroup(action.id, action.companyId, action.payload)
      .pipe(
        switchMap((resp) =>
          ctx.dispatch(new ContactGroupUpdated(action.companyId, resp)),
        ),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(DeleteContactGroup)
  deleteContact(
    ctx: StateContext<ContactGroupsStateModel>,
    action: DeleteContactGroup,
  ): Observable<void> {
    return this.contactsService
      .deleteContactGroup(action.id, action.companyId)
      .pipe(
        switchMap((resp) =>
          ctx.dispatch(new GetContactGroups(action.companyId)),
        ),
        catchError((e) => {
          ctx.patchState({
            error: e,
          });
          return throwError(e);
        }),
      );
  }

  @Action(ContactGroupCreated)
  created(
    ctx: StateContext<ContactGroupsStateModel>,
    action: ContactGroupCreated,
  ): Observable<void> {
    ctx.patchState({
      created: action.payload,
    });
    return ctx.dispatch(new GetContactGroups(action.companyId));
  }

  @Action(ContactGroupUpdated)
  updated(
    ctx: StateContext<ContactGroupsStateModel>,
    action: ContactGroupUpdated,
  ): Observable<void> {
    ctx.patchState({
      updated: action.payload,
    });
    return ctx.dispatch(new GetContactGroups(action.companyId));
  }

  @Action(ClearContactGroupsErrors)
  clearErrors(ctx: StateContext<ContactGroupsStateModel>): void {
    ctx.patchState({
      error: null,
    });
  }
}
