import { CreateDocumentShareRequest } from '@core/models/create-document-share-request.model';
import { DocumentShareRequest } from '@core/models';
import { Nullable } from '@core/interfaces/nullable';

export class GetOutgoingRequests {
  static readonly type = '[DOCUMENT REQUESTS] Get Outgoing Requests';

  constructor(
    public payload: {
      companyId: string;
      page: number;
      pageSize: number;
      sortBy?: string;
      order?: string;
      where?: Nullable<Record<string, string>>;
    },
  ) {}
}

export class GetPendingRequests {
  static readonly type = '[DOCUMENT REQUESTS] Get Pending Requests';

  constructor(
    public payload: {
      companyId: string;
      page: number;
      pageSize: number;
      sortBy?: string;
      order?: string;
      where?: Nullable<Record<string, string>>;
    },
  ) {}
}

export class CreateDocumentRequest {
  static readonly type = '[DOCUMENT REQUESTS] Create New Request';

  constructor(
    public companyId: string,
    public payload: CreateDocumentShareRequest,
  ) {}
}

export class DocumentRequestCreated {
  static readonly type = '[DOCUMENT REQUESTS] Request Created';

  constructor(public companyId: string, public payload: DocumentShareRequest) {}
}

export class ClearRequestsErrors {
  static readonly type = '[DOCUMENT REQUESTS] Requests Clear Errors';
}

export class ApprovePendingRequest {
  static readonly type = '[DOCUMENT REQUESTS] Approve Pending Request';

  constructor(public companyId: string, public requestId: string) {}
}

export class DeclinePendingRequest {
  static readonly type = '[DOCUMENT REQUESTS] Decline Pending Request';

  constructor(public companyId: string, public requestId: string) {}
}
