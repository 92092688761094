import { State, Action, Selector, StateContext } from '@ngxs/store';
import {
  ChangePassword,
  GetUserProfile,
  UpdateAvatar,
  UpdateEmail,
  UpdateUserName,
  UserProfileLoaded,
} from '@store/profile/profile.actions';
import { Observable } from 'rxjs';
import { mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserProfile } from '@core/models';
import { UsersService } from '@core/services/api';
import { PublicAssetsUploadService } from '@common/shared/services/public-assets-upload.service';
import { UploadedFile } from '@core/models/uploaded-file.model';

export interface ProfileStateModel {
  profile: UserProfile | null;
}

@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    profile: null,
  },
})
@Injectable()
export class ProfileState {
  constructor(
    private userService: UsersService,
    private uploader: PublicAssetsUploadService,
  ) {}

  @Selector<UserProfile>()
  static selectProfile(state: ProfileStateModel): UserProfile | null {
    return state.profile;
  }

  @Action(GetUserProfile)
  public loadProfile(
    ctx: StateContext<ProfileStateModel>,
    action: GetUserProfile,
  ): Observable<any> {
    return this.userService.getProfile().pipe(
      tap((profile) => {
        ctx.patchState({
          profile,
        });
      }),
      mergeMap((profile) => {
        return ctx.dispatch(new UserProfileLoaded(profile));
      }),
    );
  }

  @Action(UpdateUserName)
  public updateUserName(
    ctx: StateContext<ProfileStateModel>,
    action: UpdateUserName,
  ): Observable<any> {
    return this.userService
      .updateName(action.payload.firstName, action.payload.lastName)
      .pipe(
        tap((profile) => {
          ctx.patchState({
            profile,
          });
        }),
        switchMap((profile) => {
          return ctx.dispatch(new GetUserProfile());
        }),
      );
  }

  @Action(UpdateEmail)
  public updateEmail(
    ctx: StateContext<ProfileStateModel>,
    action: UpdateEmail,
  ): Observable<any> {
    return this.userService
      .updateEmail(action.payload.email, action.payload.password)
      .pipe(
        tap((profile) => {
          ctx.patchState({
            profile,
          });
        }),
        switchMap((profile) => {
          return ctx.dispatch(new GetUserProfile());
        }),
      );
  }

  @Action(ChangePassword)
  public changePassword(
    ctx: StateContext<ProfileStateModel>,
    action: ChangePassword,
  ): Observable<any> {
    return this.userService
      .changePassword(
        action.payload.oldPassword,
        action.payload.password,
        action.payload.confirmPassword,
      )
      .pipe(
        switchMap((profile) => {
          return ctx.dispatch(new GetUserProfile());
        }),
      );
  }

  @Action(UpdateAvatar)
  public updateAvatar(
    ctx: StateContext<ProfileStateModel>,
    action: UpdateAvatar,
  ): Observable<any> {
    return this.uploader.upload(action.payload).pipe(
      take(1),
      switchMap((file: UploadedFile | any) => {
        return this.userService.updateAvatar(file.path);
      }),
      switchMap((profile) => {
        return ctx.dispatch(new GetUserProfile());
      }),
    );
  }
}
