import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from '@common/shared/services/date-time.service';
import { DEFAULT_DATE_TIME_FORMAT } from '@core/constants';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  constructor(private dateService: DateTimeService) {}

  transform(
    value: string | number | Date | undefined | null,
    format?: string,
  ): string {
    if (value === undefined || value === null) {
      return '';
    }
    return this.dateService.format(value, format || DEFAULT_DATE_TIME_FORMAT);
  }
}
