import { State, Action, Selector, StateContext } from '@ngxs/store';
import {
  CheckRestorePasswordToken,
  ClearEmailConfirmationResults,
  ConfirmEmailToken,
  EmailConfirmed,
  Login,
  LoginBySocialProvider,
  Logout,
  RefreshToken,
  RestorePassword,
  RestorePasswordSent,
  SendRestorePassword,
} from './auth.actions';
import { Injectable } from '@angular/core';
import { User } from '@core/models/user.model';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, NEVER, Observable, of, throwError } from 'rxjs';
import { AuthApiService } from '@core/services/api';
import { Nullable } from '@core/interfaces/nullable';
import { LoginResponse } from '@core/models';
import { JwtService } from '@common/shared/services/jwt.service';

export interface AuthStateModel {
  accessToken: Nullable<string>;
  refreshToken: Nullable<string>;
  identity: User | null;
  emailConfirmation: Nullable<string>;
  restorePassword: Nullable<string | 'pending' | 'valid' | 'invalid'>;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    accessToken: null,
    refreshToken: null,
    identity: null,
    emailConfirmation: null,
    restorePassword: null,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private jwtService: JwtService,
    private authApiService: AuthApiService,
  ) {}

  @Selector()
  static getState(state: AuthStateModel): AuthStateModel {
    return state;
  }

  @Selector()
  static accessToken(state: AuthStateModel): Nullable<string> {
    return state.accessToken;
  }

  @Selector()
  static identity(state: AuthStateModel): Nullable<User> {
    return state.identity;
  }

  @Selector()
  static refreshToken(state: AuthStateModel): Nullable<string> {
    return state.refreshToken;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.accessToken;
  }

  @Selector()
  static emailConfirmationStatus(state: AuthStateModel): Nullable<string> {
    return state.emailConfirmation;
  }

  @Selector()
  static restorePasswordTokenStatus(
    state: AuthStateModel,
  ): Nullable<string | 'pending' | 'valid' | 'invalid'> {
    return state.restorePassword;
  }

  @Action(ConfirmEmailToken)
  confirmEmail(
    ctx: StateContext<AuthStateModel>,
    action: ConfirmEmailToken,
  ): Observable<void> {
    ctx.patchState({
      emailConfirmation: 'pending',
    });
    return this.authApiService
      .confirmEmail(action.token)
      .pipe(
        switchMap(() => {
          return ctx.dispatch(new EmailConfirmed());
        }),
      )
      .pipe(
        catchError((e) => {
          ctx.patchState({
            emailConfirmation: 'error',
          });
          return throwError(e);
        }),
      );
  }

  @Action(EmailConfirmed)
  emailConfirmed(ctx: StateContext<AuthStateModel>): void {
    ctx.patchState({
      emailConfirmation: 'confirmed',
    });
  }
  @Action(RestorePasswordSent)
  restorePasswordSent(ctx: StateContext<AuthStateModel>): void {
    ctx.patchState({
      restorePassword: 'pending',
    });
  }

  @Action(ClearEmailConfirmationResults)
  clearEmailConfirmation(ctx: StateContext<AuthStateModel>): void {
    ctx.patchState({
      emailConfirmation: null,
      restorePassword: null,
    });
  }

  @Action(CheckRestorePasswordToken)
  checkRestorePasswordToken(
    ctx: StateContext<AuthStateModel>,
    action: CheckRestorePasswordToken,
  ): Observable<void> {
    return this.authApiService
      .checkRestorePasswordToken(action.payload.token)
      .pipe(
        tap(() => {
          ctx.patchState({
            restorePassword: 'valid',
          });
        }),
        catchError(() => {
          ctx.patchState({
            restorePassword: 'invalid',
          });
          return EMPTY;
        }),
      );
  }

  @Action(SendRestorePassword)
  sendRestorePassword(
    ctx: StateContext<AuthStateModel>,
    action: SendRestorePassword,
  ): Observable<void> {
    return this.authApiService
      .sendRestorePassword(action.payload.email)
      .pipe(switchMap(() => ctx.dispatch(new RestorePasswordSent())));
  }

  @Action(RestorePassword)
  restorePassword(
    ctx: StateContext<AuthStateModel>,
    action: RestorePassword,
  ): Observable<void> {
    return this.authApiService.restorePassword(
      action.payload.token,
      action.payload.password,
      action.payload.confirmPassword,
    );
  }

  @Action(Login)
  login(
    ctx: StateContext<AuthStateModel>,
    action: Login,
  ): Observable<Nullable<User>> {
    return this.authApiService
      .loginByCredentials(action.payload.username, action.payload.password)
      .pipe(switchMap((resp) => this.handleLoginResponse(ctx, resp)))
      .pipe(
        catchError((e) => {
          this.logout(ctx);
          return throwError(e);
        }),
      );
  }

  @Action(LoginBySocialProvider)
  loginBySocialProvider(
    ctx: StateContext<AuthStateModel>,
    action: LoginBySocialProvider,
  ): Observable<Nullable<User>> {
    return this.authApiService
      .loginBySocialProvider(action.payload.idToken)
      .pipe(switchMap((resp) => this.handleLoginResponse(ctx, resp)))
      .pipe(
        catchError((e) => {
          this.logout(ctx);
          return throwError(e);
        }),
      );
  }

  @Action(RefreshToken)
  refreshToken(ctx: StateContext<AuthStateModel>): Observable<Nullable<User>> {
    if (!ctx.getState().refreshToken) {
      return EMPTY;
    }
    return this.authApiService
      .refreshToken(ctx.getState().refreshToken as string)
      .pipe(switchMap((resp) => this.handleLoginResponse(ctx, resp)))
      .pipe(
        catchError((e) => {
          this.logout(ctx);
          return throwError(e);
        }),
      );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>): void {
    ctx.setState({
      accessToken: null,
      refreshToken: null,
      identity: null,
      emailConfirmation: null,
      restorePassword: null,
    });
  }

  private handleLoginResponse(
    ctx: StateContext<AuthStateModel>,
    resp: LoginResponse,
  ): Observable<Nullable<User>> {
    ctx.patchState({
      ...resp,
    });
    return this.retrieveUser(ctx);
  }

  public retrieveUser(
    ctx: StateContext<AuthStateModel>,
  ): Observable<Nullable<User>> {
    if (!ctx.getState().accessToken) {
      return of(null);
    }
    return this.authApiService.getUserInfo().pipe(
      tap((identity) => {
        ctx.patchState({
          identity,
        });
      }),
    );
    // return {
    //   id: payload.sub,
    //   displayName: payload.displayName || payload.sub,
    //   roles: payload.authorities,
    //   avatar: payload.avatar || '/assets/images/avatar.png',
    //   username: payload.username || 'Admin',
    //   permissions:
    //     payload.authorities && payload.authorities.includes('ROLE_ADMIN')
    //       ? [
    //           PERMISSIONS.COMMON_VIEW,
    //           PERMISSIONS.COMMON_MODIFY,
    //           PERMISSIONS.SYSTEM_ADMINISTRATION_VIEW,
    //           PERMISSIONS.SYSTEM_ADMINISTRATION_MODIFY,
    //         ]
    //       : [PERMISSIONS.COMMON_VIEW],
    // } as User;
  }
}
