import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { distinctUntilKeyChanged, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Company } from '@core/models/company.model';
import {
  ChangeActiveCompany,
  ClearCompanyErrors,
  CreateCompany,
  DeleteCompany,
  GetCategories,
  GetClientCategories,
  GetCompanies,
  UpdateCompany,
} from '@store/companies/companies.actions';
import { CompaniesState } from '@store/companies/companies.state';
import { CreateCompanyRequest } from '@core/models/create-company-request.model';
import { UpdateCompanyRequest } from '@core/models/update-company-request.model';
import { Nullable } from '@core/interfaces/nullable';
import { CategoryShort } from '@core/models';

@Injectable()
export class CompaniesStoreService {
  constructor(private store: Store) {}

  selectCompanies(): Observable<Company[]> {
    return this.store
      .select(CompaniesState.getCompanies)
      .pipe(filter((data) => !!data)) as Observable<Company[]>;
  }

  getCompanies(): Observable<Nullable<Company[]>> {
    return this.store
      .dispatch(new GetCompanies())
      .pipe(switchMap(() => this.store.select(CompaniesState.getCompanies)));
  }

  getClientCategories(): Observable<CategoryShort[]> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((c) => !!c),
      switchMap((company: Company) =>
        this.store.dispatch(new GetClientCategories(company.id)),
      ),
      switchMap(() => {
        return this.store
          .select(CompaniesState.getClientCategories)
          .pipe(filter((data) => !!data)) as Observable<CategoryShort[]>;
      }),
    );
  }

  getCategories(): Observable<CategoryShort[]> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((c) => !!c),
      switchMap((company: Company) =>
        this.store.dispatch(new GetCategories(company.id)),
      ),
      switchMap(() => {
        return this.store
          .select(CompaniesState.getCategories)
          .pipe(filter((data) => !!data)) as Observable<CategoryShort[]>;
      }),
    );
  }

  getCompany(id: string): Observable<Company> {
    return this.store
      .select(CompaniesState.getCompany(id))
      .pipe(filter((data) => !!data)) as Observable<Company>;
  }

  changeActiveCompany(company: Company): Observable<Company> {
    return this.store.dispatch(new ChangeActiveCompany(company)).pipe(
      switchMap(() => this.store.select(CompaniesState.getActive)),
      filter((data) => !!data),
    ) as Observable<Company>;
  }

  getActiveCompany(): Observable<Company> {
    return this.store.select(CompaniesState.getActive) as Observable<Company>;
  }

  isActiveCompanySelected(): Observable<boolean> {
    return this.store.select(CompaniesState.getActive).pipe(map((c) => !!c));
  }

  createCompany(
    company: CreateCompanyRequest,
    logoFile: File,
  ): Observable<Company> {
    return this.store
      .dispatch(
        new CreateCompany({
          company,
          logoFile,
        }),
      )
      .pipe(
        switchMap(() => {
          return this.store
            .select(CompaniesState.getCreated)
            .pipe(filter((data) => !!data)) as Observable<Company>;
        }),
      );
  }

  updateCompany(
    id: string,
    company: UpdateCompanyRequest,
    logoFile?: Nullable<File>,
  ): Observable<Company> {
    return this.store
      .dispatch(
        new UpdateCompany({
          id,
          company,
          logoFile,
        }),
      )
      .pipe(
        switchMap(() => {
          return this.store
            .select(CompaniesState.getUpdated)
            .pipe(filter((data) => !!data)) as Observable<Company>;
        }),
      );
  }

  deleteCompany(id: string): Observable<void> {
    return this.store.dispatch(
      new DeleteCompany({
        id,
      }),
    );
  }

  error(): Observable<any> {
    return this.store.select(CompaniesState.getError).pipe(
      filter((data) => !!data),
      tap(() => {
        this.store.dispatch(new ClearCompanyErrors());
      }),
    ) as Observable<any>;
  }
}
