import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';
import { TableRowData } from '../../../interfaces';
import { CELL_INFO_PROVIDER } from '../../../utils';
import { CellInfoProvider } from '../../../interfaces/cell-info-provider';
import { DEFAULT_DATE_TIME_FORMAT } from '@core/constants';
import { DateTimeService } from '@common/shared/services/date-time.service';

@Component({
  selector: 'app-datetime-cell-renderer',
  templateUrl: './datetime-cell-renderer.component.html',
  styleUrls: ['./datetime-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatetimeCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<string | Date | number, R>
  implements OnInit
{
  format = DEFAULT_DATE_TIME_FORMAT;
  timezone!: string;

  constructor(
    @Inject(CELL_INFO_PROVIDER) cellInfoProvider: CellInfoProvider<number, R>,
    private dateTime: DateTimeService,
  ) {
    super(cellInfoProvider);
  }

  ngOnInit(): void {
    if (this.getOptions()['format']) {
      this.format = this.getOptions()['format'] as string;
    }
    if (this.getOptions()['timeZone']) {
      this.timezone = this.getOptions()['timeZone'] as string;
    }
  }

  override render(): string {
    if (typeof this.getRawValue() === 'number') {
      const time = this.getRawValue() as number;
      const now = new Date().getTime();
      const input = now / time >= 1000 ? 'X' : 'x';
      return this.dateTime.format(
        this.dateTime.parse(time, input),
        this.format,
      );
    } else if (this.getRawValue() !== undefined) {
      return this.dateTime.format(this.getRawValue(), this.format);
    } else {
      return '';
    }
  }
}
