<div class="field mb-3">
  <p-fileUpload
    #uploader
    [uploadIcon]="!uploadedImage ? 'pi pi-plus' : undefined"
    mode="basic"
    name="logo"
    accept="image/*"
    maxFileSize="50000000"
    [customUpload]="true"
    styleClass="logo-uploader"
    (onSelect)="onSelect($event)"
    [auto]="true"
  >
  </p-fileUpload>
  <div *ngIf="!avatar && !uploadedImage" class="avatar-placeholder"></div>
  <p-avatar
    *ngIf="uploadedImage || avatar"
    shape="circle"
    size="large"
    [styleClass]="imgSize.width / imgSize.height > 1 ? 'landscape' : 'portrait'"
    [image]="uploadedImage ? (uploadedImage | safeImage) : avatar"
  ></p-avatar>
</div>
<img
  *ngIf="uploadedImage"
  #utilImage
  width="0"
  height="0"
  [attr.src]="uploadedImage"
/>
