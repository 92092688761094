import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanize',
})
export class HumanizePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    if (typeof value !== 'string') {
      return (value as object).toString();
    }
    return value
      .toLowerCase()
      .replace(/[\W_-]+/gi, ' ')
      .split(' ')
      .map((s) => s.substr(0, 1).toUpperCase() + s.substr(1, s.length - 1))
      .join(' ');
  }
}
