import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {
  CategoryShort,
  ContactGroup,
  DocumentShareRequestFormModel,
} from '@core/models';
import { VerificationType } from '@core/enums/verification-type.enum';
import { DateFormats } from '@core/constants';
import { Calendar } from 'primeng/calendar';
import { BaseFormDirective } from '@ui/forms/base-form.component';
import { Contact } from '@core/models/contact.model';
import { takeUntil } from 'rxjs/operators';
import { Category } from '@core/models/category.model';
import { CustomValidators } from '@common/shared/utils/validation/custom-validators';

@Component({
  selector: 'app-add-document-request',
  templateUrl: './add-document-request.component.html',
  styleUrls: ['./add-document-request.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddDocumentRequestComponent),
      multi: true,
    },
  ],
})
export class AddDocumentRequestComponent
  extends BaseFormDirective<DocumentShareRequestFormModel>
  implements ControlValueAccessor
{
  @ViewChild('periodRangePicker') protected periodRangePicker!: Calendar;

  @Input() groups!: ContactGroup[];
  @Output() createContactOrGroup = new EventEmitter<'contact' | 'group'>();

  documentTypes: any[] = [
    {
      id: VerificationType.Standard,
      name: 'Standard',
    },
    {
      id: VerificationType.Verified,
      name: 'Verified',
    },
  ];

  lifetimeItems: any[] = [
    {
      value: '1',
      label: '24 hours',
    },
    {
      value: '7',
      label: '7 days',
    },
    {
      value: '30',
      label: '30 days',
    },
    {
      value: 'custom',
      label: 'Custom',
    },
  ];

  @Input() set categories(categories: Category[]) {
    if (categories) {
      this.categoriesValue = categories.filter((c) => !!c.id);
    }
  }

  get categories(): Category[] {
    return this.categoriesValue;
  }

  @Input() set contacts(contacts: Contact[]) {
    if (contacts) {
      this.contactsValue = contacts.filter((c) => !!c.id);
    }
  }

  get contacts(): Contact[] {
    return this.contactsValue;
  }

  private createNewText = 'Create new contact';

  get contactsEmails(): string[] {
    let res = this.contactsValue
      ?.filter(
        (c) =>
          !this.form?.get('contact')?.value ||
          c.email.toLowerCase().includes(this.form?.get('contact')?.value) ||
          c.firstName
            ?.toLowerCase()
            ?.includes(this.form?.get('contact')?.value) ||
          c.lastName?.toLowerCase()?.includes(this.form?.get('contact')?.value),
      )
      ?.map((c) => `${c.firstName} ${c.lastName} <${c.email}>`);
    if (this.contactNameVisible) {
      return [];
    }
    if (res?.length === 0) {
      res = [this.createNewText];
    }
    return res || [];
  }

  minDate = new Date();
  viewDateFormat = DateFormats.DATEPICKER_DATE_FORMAT;
  contactsValue!: Contact[];
  categoriesValue!: Category[];
  contactNameVisible = false;

  override initForm(
    model?: DocumentShareRequestFormModel & { id: string },
  ): void {
    this.contactNameVisible = false;
    this.form = this.fb.group({
      id: this.fb.control(model?.id),
      verification: this.fb.control(model?.verification, [Validators.required]),
      category: this.fb.control(model?.category || '', [Validators.required]),
      type: this.fb.control(model?.type || '', [
        Validators.maxLength(30),
        CustomValidators.noWhitespace,
      ]),
      message: this.fb.control(model?.message),
      contacts: this.fb.control('', [Validators.required]),
      lifetime: this.fb.control(model?.lifetime || '1', [Validators.required]),
      lifetimeCustomPeriod: this.fb.control(new Date()),
    });
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (
        value.lifetimeCustomPeriod &&
        value.lifetimeCustomPeriod instanceof Date &&
        this.periodRangePicker?.overlayVisible
      ) {
        this.periodRangePicker.hideOverlay();
      }
    });
  }

  onCreateNewContact(event: 'contact' | 'group'): void {
    this.createContactOrGroup.emit(event);
  }
}
