import { Component, Inject, OnInit } from '@angular/core';
import { TableRowData } from '../../../interfaces';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@Component({
  selector: 'app-property-cell-renderer',
  templateUrl: '../base-cell-renderer.component.html',
  styleUrls: ['./property-cell-renderer.component.css'],
})
export class PropertyCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<any, R>
  implements OnInit
{
  static readonly MAX_LEVEL = 2;

  value: any;

  ngOnInit(): void {
    if (this.getFieldId().match(/\./)) {
      const parts = this.getFieldId().split('.');
      this.value = this.retrieveDeepProperty(this.getRow(), parts);
    }
  }

  override render(): string {
    return this.value as string;
  }

  private retrieveDeepProperty(obj: any, ids: string[], level = 0): any {
    if (level > PropertyCellRendererComponent.MAX_LEVEL) {
      return obj;
    }
    if (ids.length === 0) {
      return obj;
    }
    const id: string | undefined = ids.shift();
    if (id) {
      return this.retrieveDeepProperty(obj[id], ids, level++);
    } else {
      return obj;
    }
  }
}
