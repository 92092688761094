import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Document, DocumentShareRequest } from '@core/models';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from '@core/constants';
import { VerificationType } from '@core/enums/verification-type.enum';
import { Contact } from '@core/models/contact.model';
import { Nullable } from '@core/interfaces/nullable';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-document-request-details',
  templateUrl: './document-request-details.component.html',
  styleUrls: ['./document-request-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentRequestDetailsComponent {
  @Input() set request(req: Nullable<DocumentShareRequest>) {
    if (req) {
      this.documentValue = req.document;
      this.requestValue = req;
    }
  }
  get request(): DocumentShareRequest {
    return this.requestValue;
  }
  @Input() set document(doc: Document) {
    if (doc) {
      this.documentValue = doc;
    }
  }
  @Input() showActions!: boolean;

  @Output() approve = new EventEmitter<void>();
  @Output() decline = new EventEmitter<void>();
  @Output() resend = new EventEmitter<void>();

  requestValue!: DocumentShareRequest;
  documentValue!: Document;

  dateFormat = DEFAULT_DATE_TIME_FORMAT;

  get requestedFromName(): Nullable<string> {
    if (this.request) {
      return this.request.requestedFrom.name;
    } else {
      return this.documentValue?.requestedFrom?.name;
    }
  }

  get requestedFromEmail(): Nullable<string> {
    if (this.request) {
      return this.request.requestedFrom.email;
    } else {
      return this.documentValue?.requestedFrom?.email;
    }
  }

  get requestedBy(): Contact {
    if (this.request) {
      return this.request.requestedBy;
    } else {
      return this.documentValue.owner;
    }
  }

  get verification(): VerificationType {
    return (
      this.request ? this.request.verification : this.documentValue.verification
    ) as VerificationType;
  }

  get requestLifetime(): number {
    return (
      this.request?.lifetime
        ? this.request.lifetime
        : this.documentValue.requestLifetime
    ) as number;
  }

  get requestedAt(): Nullable<string> {
    if (this.request) {
      return this.request.requestedAt as string;
    } else {
      return this.documentValue.requestedAt;
    }
  }

  get requestedUntil(): string {
    return dayjs(this.request.requestedAt as string)
      .add(this.requestLifetime, 'days')
      .format(DEFAULT_DATE_FORMAT);
  }

  get categoryName(): string {
    return (
      this.request
        ? this.request?.category?.name
        : this.documentValue?.category?.name
    ) as string;
  }
}
