import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthComponent } from '@app/pages/public/auth/auth.component';
import { PublicGuard } from 'ngx-auth';
import { LogoutComponent } from '@app/pages/public/auth/logout/logout.component';
import { RegisterComponent } from '@pages/public/auth/register/register.component';
import { ConfirmEmailComponent } from '@pages/public/auth/confirm-email/confirm-email.component';
import { RestorePassword } from '@store/auth/auth.actions';
import { CheckRestorePasswordTokenGuard } from '@common/shared/guards/check-restore-password-token.guard';
import { RestorePasswordComponent } from '@pages/public/auth/restore-password/restore-password.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    canActivateChild: [PublicGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'confirm-email/:token',
        component: ConfirmEmailComponent,
      },
      {
        path: 'restore-password/:token',
        component: RestorePasswordComponent,
        canActivate: [CheckRestorePasswordTokenGuard],
      },
    ],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
