import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class FormApplyService {
  private apply$ = new ReplaySubject<void>(1);
  private cancel$ = new ReplaySubject<void>(1);
  private validate$ = new ReplaySubject<void>(1);
  private valid$ = new ReplaySubject<boolean>(1);
  private reset$ = new ReplaySubject<void>(1);

  constructor() {}

  apply(): void {
    this.apply$.next();
  }

  cancel(): void {
    this.cancel$.next();
  }

  validate(): void {
    this.validate$.next();
  }

  setValid(): void {
    this.valid$.next(true);
  }

  setInvalid(): void {
    this.valid$.next(false);
  }

  reset(): void {
    this.reset$.next();
  }

  onApply(): Observable<void> {
    return this.apply$.asObservable();
  }

  onCancel(): Observable<void> {
    return this.cancel$.asObservable();
  }

  onReset(): Observable<void> {
    return this.reset$.asObservable();
  }

  onValidate(): Observable<void> {
    return this.validate$.asObservable();
  }

  onValidateResult(): Observable<boolean> {
    return this.valid$.asObservable();
  }
}
