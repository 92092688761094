<form *ngIf="form" [formGroup]="form" class="create-contact-form-content">
  <div class="p-2 w-full">
    <app-form-fields-wrapper [showLabels]="true">
      <input
        *appFormField="'id'"
        formControlName="id"
        type="hidden"
        pInputText
        class="w-full mb-1"
      />
      <input
        *appFormField="'name'; label: 'Group name'"
        formControlName="name"
        type="text"
        placeholder="Enter group name"
        pInputText
        class="w-full mb-1"
        [readonly]="disabled"
      />
      <div *appFormField="'clients'; label: 'Add contacts'">
        <p-multiSelect
          [filter]="true"
          filterBy="name"
          [disabled]="disabled"
          [options]="clients"
          [virtualScroll]="true"
          [virtualScrollItemSize]="50"
          placeholder="Search for a contact"
          optionValue="id"
          optionLabel="name"
          styleClass="w-full mb-1"
          formControlName="clients"
        >
          <ng-template let-item pTemplate="item">
            <app-contact [contact]="item"></app-contact>
          </ng-template>
          <ng-template let-items pTemplate="selectedItems">
            <ng-container *ngIf="items?.length > 0; else empty">
              Selected ({{ items.length }})
            </ng-container>
            <ng-template #empty> Search for a contact </ng-template>
          </ng-template>
        </p-multiSelect>
      </div>
    </app-form-fields-wrapper>
  </div>
</form>
<div *ngIf="selectedClients?.length > 0" class="p-2 w-full flex flex-wrap">
  <app-contacts-listbox
    [clients]="selectedClients"
    (deselect)="onDeselect($event)"
  ></app-contacts-listbox>
</div>
