import { Pipe, PipeTransform } from '@angular/core';
import { FileType } from '@core/enums/file-type.enum';

@Pipe({
  name: 'mimeTypeToExtension',
})
export class MimeTypeToExtensionPipe implements PipeTransform {
  transform(mimeType: unknown, ...args: unknown[]): unknown {
    let type = FileType.OTHER;
    switch (mimeType) {
      case 'image/jpeg':
      case 'image/jpg':
        type = FileType.JPG;
        break;
      case 'image/png':
        type = FileType.PNG;
        break;
      case 'image/gif':
        type = FileType.GIF;
        break;
      case 'image/vnd.adobe.photoshop':
        type = FileType.PSD;
        break;
      case 'application/pdf':
        type = FileType.PDF;
        break;
      case 'text/plain':
        type = FileType.TXT;
        break;
      case 'text/csv':
      case 'application/csv':
        type = FileType.CSV;
        break;
      case 'application/msword':
        type = FileType.DOC;
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        type = FileType.DOCX;
        break;
      case 'application/vnd.ms-excel':
        type = FileType.XLS;
        break;
      case 'application/vnd.oasis.opendocument.spreadsheet':
        type = FileType.ODS;
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        type = FileType.XLSX;
        break;
    }
    return type.toLowerCase();
  }
}
