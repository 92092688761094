import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  Type,
} from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  CdkDragDrop,
  CdkDragStart,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
  ColumnDefinition,
  ColumnSortEvent,
  DataTableConfig,
  DataTableRowChangesEvent,
  PaginationEvent,
} from '../interfaces';
import { DefaultDataTableConfig } from '../constants/defaults';
import { BaseCellRendererComponent } from './renderers/base-cell-renderer.component';
import { CellInfoProviderService } from '../services/cell-info-provider.service';
import { DefaultDataSource } from '../utils/default-data-source';
import { CELL_INFO_PROVIDER } from '../utils';
import { ExportToCsv } from 'export-to-csv';
import { Table } from 'primeng/table';
import * as sha256 from 'sha256';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-abstract-data-table',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @typescript-eslint/ban-types
export class AbstractDataTableComponent<T extends object>
  implements OnInit, OnDestroy
{
  @Input() set columns(columns: Array<ColumnDefinition>) {
    if (columns) {
      this.rawColumns = columns;
      this.columnsReady$.next(this.rawColumns);
    }
  }

  get columns(): Array<ColumnDefinition> {
    return this.rawColumns as Array<ColumnDefinition>;
  }

  @Input() set data(data: Array<T> | null) {
    this.rawData = data;
    if (data) {
      this.dataReady$.next(data);
    } else {
      this.loading$.next(true);
    }
  }

  @Input() lazy!: boolean;
  @Input() loading!: boolean;

  get data(): Array<T> | null {
    return this.rawData;
  }

  @Input() set config(config: DataTableConfig | undefined) {
    if (config) {
      this.enableExport = !!config?.topToolbar?.exportColumns;
      this.exportColumns = config.topToolbar?.exportColumns as string[];
      this.exportFileName = config.topToolbar?.exportFileName as string;
      this.toolbarItems = config.topToolbar?.items as any[];
      this.rawConfig = { ...DefaultDataTableConfig, ...config };
      this.cdr.detectChanges();
    }
  }

  get config(): DataTableConfig | undefined {
    return this.rawConfig;
  }

  @Output() loaded: EventEmitter<boolean> = new EventEmitter();
  @Output() columnsUpdated: EventEmitter<Array<ColumnDefinition>> =
    new EventEmitter();
  @Output() sorted: EventEmitter<ColumnSortEvent> = new EventEmitter();
  @Output() paginated: EventEmitter<PaginationEvent> = new EventEmitter();
  @Output() filtered: EventEmitter<any> = new EventEmitter();
  @Output() ready: EventEmitter<Table> = new EventEmitter();
  @Output() rowChanged: EventEmitter<DataTableRowChangesEvent<T>> =
    new EventEmitter<DataTableRowChangesEvent<T>>();
  @Output() editCanceled = new EventEmitter<T>();
  @Output() lazyLoad: EventEmitter<LazyLoadEvent> = new EventEmitter();

  toolbarItems!: any[];
  enableExport!: boolean;
  exportColumns!: string[] | Array<{ id: string; label: string }>;
  exportFileName!: string;
  dataSource: DataSource<T> | null = null;
  activeSort: ColumnSortEvent | undefined;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  protected rawData: Array<T> | null = null;
  protected rawColumns: Array<ColumnDefinition> | null = null;
  protected rawConfig: DataTableConfig | undefined;
  protected destroy$: Subject<void> = new Subject();
  protected pageIndex = 0;

  private dataReady$: ReplaySubject<Array<T>> = new ReplaySubject(1);
  private columnsReady$: ReplaySubject<Array<ColumnDefinition>> =
    new ReplaySubject(1);
  private rowChangesMap: Map<T, Observable<DataTableRowChangesEvent<T>>> =
    new Map<T, Observable<DataTableRowChangesEvent<T>>>();
  private defaultCsvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showTitle: false,
    useTextFile: false,
    useBom: true,
  };
  private injectors: Map<string, Injector> = new Map<string, Injector>();

  constructor(private cdr: ChangeDetectorRef, private injector: Injector) {}

  ngOnInit(): void {
    combineLatest([this.columnsReady$, this.dataReady$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([columns, data]) => {
        this.init(columns, data);
        this.loading$.next(false);
        this.loaded.next(true);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onColumnSort(event: any): void {}

  get globalFilterFields(): Array<string> {
    return this.config?.globalSearch?.fields || [];
  }

  get activeSortFieldName(): string {
    return this.activeSort && this.activeSort.columns.length > 0
      ? this.activeSort.columns[0]?.columnDefinition?.id
      : '';
  }

  get activeSortDirection(): any {
    return this.activeSort && this.activeSort.columns.length > 0
      ? this.activeSort.columns[0]?.direction
      : 'asc';
  }

  get columnsIds(): Array<string> {
    return this.rawColumns ? this.rawColumns.map((col) => col.id) : [];
  }

  get currentPageSize(): number {
    return this.config &&
      this.config?.pageSizes &&
      this.config?.pageSizes.length > 0
      ? this.config?.pageSizes[0]
      : (DefaultDataTableConfig.pageSizes as number[])[0];
  }

  get pageSizes(): number[] {
    return (
      this.config && this.config?.pageSizes
        ? this.config?.pageSizes
        : DefaultDataTableConfig.pageSizes
    ) as number[];
  }

  get currentPageIndex(): number {
    return this.pageIndex;
  }

  trackByIndex(i: number, item: any): number {
    return i;
  }

  getColumnRenderComponent(
    column: ColumnDefinition,
  ): Type<BaseCellRendererComponent<any, any>> {
    return column.cellOptions?.component as Type<
      BaseCellRendererComponent<any, any>
    >;
  }

  getColumnRenderTemplate(column: ColumnDefinition): TemplateRef<any> {
    return column.cellOptions?.template as TemplateRef<any>;
  }

  getCellInfoProviderInjector(
    fieldId: string,
    row: T,
    column: ColumnDefinition,
  ): Injector {
    const key = sha256(fieldId + '__' + JSON.stringify(row));
    if (this.injectors.has(key)) {
      // @ts-ignore
      return this.injectors.get(key);
    }
    const injector: Injector = Injector.create({
      providers: [
        {
          provide: CELL_INFO_PROVIDER,
          useValue: new CellInfoProviderService(
            fieldId,
            row,
            column.cellOptions || {},
          ),
        },
      ],
      parent: this.injector,
    });
    this.injectors.set(key, injector);
    return injector;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  cellCssClass(column: ColumnDefinition): object {
    const cssClass: any = {};
    if (column.options?.nowrap) {
      cssClass.nowrap = true;
    } else if (column.options?.nowrap === false) {
      cssClass.nowrap = false;
    }
    if (column.options?.align) {
      cssClass['align-' + column.options?.align] = true;
    }
    return cssClass;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  cellHeaderCssClass(column: ColumnDefinition): object {
    const cssClass: any = this.cellCssClass(column);
    if (this.config?.alignHeaders) {
      cssClass['align-' + this.config?.alignHeaders] = true;
    }

    return cssClass;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  cellCssStyles(column: ColumnDefinition): object {
    const cssStyles: any = {};
    if (column.options?.width !== undefined) {
      let width = 0;
      let unit = 'px';
      if (typeof column.options?.width === 'number') {
        width = column.options.width;
      } else if (typeof column?.options?.width === 'object') {
        width = column.options?.width?.value;
        unit = column.options?.width?.unit || unit;
      }
      cssStyles.minWidth = width + unit;
      cssStyles.flex = width + unit;
    }
    return cssStyles;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  cellHeaderCssStyles(column: ColumnDefinition): object {
    return this.cellCssStyles(column);
  }

  columnFilterOptions(column: ColumnDefinition): Observable<any[]> {
    const filterOptions: any[] | Observable<any[]> | undefined =
      column.options?.filterOptions;
    if (filterOptions instanceof Observable) {
      return filterOptions as Observable<any[]>;
    } else if (!filterOptions) {
      return of([]);
    } else {
      return of(filterOptions);
    }
  }

  dropEnded(event: CdkDragDrop<ColumnDefinition[]>): void {
    const cur = this.columns[event.currentIndex];
    const prev = this.columns[event.previousIndex];
    if (prev && cur) {
      moveItemInArray(
        this.rawColumns as Array<ColumnDefinition>,
        this.columns.indexOf(prev),
        this.columns.indexOf(cur),
      );
    }
    this.columnsUpdated.emit(this.rawColumns as Array<ColumnDefinition>);
  }

  dragStarted(event: CdkDragStart, index: number): void {}

  onPageChange(event: any): void {}

  getExpandCellTemplate(): TemplateRef<any> | undefined {
    return (this.config?.expandableRows as { template: TemplateRef<any> })
      ?.template;
  }

  exportCSV(): void {
    const ids: string[] = this.exportColumns?.map((col: any) =>
      typeof col !== 'string' ? col.id : col,
    );
    const labels: string[] =
      this.exportColumns?.map((col: any) =>
        typeof col !== 'string' ? col.label : col,
      ) || this.columns.map((col) => col.id);
    const data =
      this.exportColumns !== undefined && this.rawData
        ? [
            ...[labels],
            ...this.rawData?.map((item) =>
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ids.map((colName) => item[colName] || 'N/A'),
            ),
          ]
        : this.rawData;
    const csv = new ExportToCsv({
      ...this.defaultCsvOptions,
      filename: this.toFileName(this.exportFileName),
      headers: labels,
    });
    csv.generateCsv(data);
  }

  protected detectChanges(): void {
    this.cdr.detectChanges();
  }

  protected init(columns: Array<ColumnDefinition>, data: Array<T>): void {
    this.dataSource = new DefaultDataSource(data);
  }

  protected toFileName(title: string): string {
    return (
      title.toLocaleLowerCase().replace(/[\s-]+/gi, '_') +
      '_export_' +
      new Date().getTime()
    );
  }
}
