<span class="p-input-icon-left">
  <i
    class="pi pi-search search-field__search-icon"
    [ngClass]="{
      disabled: disabled,
      focused: isFocused,
      filled: searchControl.value?.length > 0
    }"
  ></i>
  <input
    pInputText
    (blur)="isFocused = false"
    (focus)="isFocused = true"
    [formControl]="searchControl"
    [ngClass]="{ disabled: disabled, focused: isFocused }"
    [placeholder]="placeholder"
    class="search-field__input"
    type="text"
  />
  <i
    (click)="clearSearch()"
    *ngIf="searchControl.value?.length > 0"
    class="search-field__close-icon pi pi-times"
  ></i>
</span>
