import { NgModule } from '@angular/core';
import { CategoriesStoreService } from '@store/categories/categories-store.service';
import { CategoriesState } from '@store/categories/categories.state';
import { CoreModule } from '@core/core.module';
import { NgxsModule } from '@ngxs/store';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CoreModule, CommonModule, NgxsModule.forFeature([CategoriesState])],
  exports: [],
  providers: [CategoriesStoreService, CategoriesState],
})
export class CategoriesStoreModule {}
