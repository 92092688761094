import {
  Account,
  CreateAccountBySocialTokenRequest,
  CreateAccountRequest,
} from '@core/models';

export class CreateAccount {
  static readonly type = '[ACCOUNT] Create Account';

  constructor(public payload: CreateAccountRequest) {}
}

export class CreateAccountBySocialProvider {
  static readonly type = '[ACCOUNT] Create Account Social Provider';

  constructor(public payload: CreateAccountBySocialTokenRequest) {}
}

export class AccountCreated {
  static readonly type = '[ACCOUNT] Account Created';

  constructor(public payload: Account) {}
}
