import { DataSource } from '@ui/components/data-table/interfaces/data-source';
import { Observable, ReplaySubject } from 'rxjs';
import { Injectable, InjectionToken } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { PaginatedResponse, SharedDocument } from '@core/models';
import { Nullable } from '@core/interfaces/nullable';
import { SharedDocumentsStoreService } from '@store/shared-documents/shared-documents-store.service';

@Injectable()
export class SharedByDocumentDataSource implements DataSource<SharedDocument> {
  private where: Nullable<Record<string, string>>;
  private docId!: string;
  private loaded$ = new ReplaySubject<PaginatedResponse<SharedDocument>>(1);

  constructor(private documentsService: SharedDocumentsStoreService) {}

  setDocumentId(docId: string): void {
    this.docId = docId;
  }

  load(
    page: number,
    pageSize: number,
    where?: Nullable<Record<string, string>>,
    sort?: string,
    order?: string | 'asc' | 'desc',
  ): Observable<PaginatedResponse<SharedDocument>> {
    where = { ...(where || {}), 'doc.id': this.docId };
    if (!where['doc.id']) {
      throw new Error('doc.id is required field');
    }
    this.where = where;
    return this.documentsService
      .getSharedByDocument(where['doc.id'], page, pageSize, sort, order, where)
      .pipe(
        take(1),
        tap((data) => {
          this.loaded$.next(data);
        }),
      ) as Observable<PaginatedResponse<SharedDocument>>;
  }

  onLoad(): Observable<PaginatedResponse<SharedDocument>> {
    return this.loaded$.asObservable();
  }
}

export const SHARED_BY_DOCUMENT_DATA_SOURCE: InjectionToken<
  DataSource<SharedDocument>
> = new InjectionToken<SharedDocument>('SHARED_BY_DOCUMENT_DATA_SOURCE');
