<form *ngIf="form" [formGroup]="form" class="share-document-form-content">
  <div class="p-2 w-full">
    <app-form-fields-wrapper [showLabels]="true">
      <app-contacts-dropdown
        *appFormField="'contacts'; label: 'Share With'"
        formControlName="contacts"
        [disabled]="disabled"
        [clients]="contacts"
        [groups]="groups"
        (createNew)="onCreateNewContact($event)"
      ></app-contacts-dropdown>
      <div *appFormField="'lifetime'; label: 'Duration'" class="radio-group">
        <div
          *ngFor="let item of lifetimeItems; let i = index"
          class="radio-row"
        >
          <p-radioButton
            [disabled]="disabled"
            name="lifetime"
            formControlName="lifetime"
            [label]="item.label"
            [value]="item.value"
          ></p-radioButton>
        </div>
        <ng-conatainer *ngIf="form.get('lifetime')?.value === 'custom'">
          <div class="pt-2">
            <p-calendar
              #periodRangePicker
              [disabled]="disabled"
              [hideOnDateTimeSelect]="true"
              [readonlyInput]="true"
              selectionMode="single"
              [dateFormat]="viewDateFormat"
              formControlName="lifetimeCustomPeriod"
              [minDate]="minDate"
              [showIcon]="true"
              appendTo="body"
            ></p-calendar>
          </div>
        </ng-conatainer>
      </div>

      <ng-container *ngIf="categories">
        <p-dropdown
          [autoDisplayFirst]="false"
          *appFormField="'category'; label: 'Contact folder'"
          [filter]="true"
          styleClass="client-categories-dropdown"
          [options]="categories"
          [readonly]="disabled"
          [disabled]="disabled"
          placeholder="Select"
          formControlName="category"
          optionValue="id"
          optionLabel="name"
        ></p-dropdown>
      </ng-container>
      <textarea
        *appFormField="'message'; label: 'Message'"
        [readonly]="disabled"
        [disabled]="disabled"
        formControlName="message"
        type="text"
        placeholder="Enter a message"
        pInputTextarea
        class="w-full mb-1"
      ></textarea>
    </app-form-fields-wrapper>
  </div>
</form>
