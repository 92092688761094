<form [formGroup]="form" class="auth-form-content" (ngSubmit)="onSubmit()">
  <div class="surface-card p-4 shadow-2 border-round w-full">
    <div class="text-center mb-5">
      <img src="/assets/images/logo.png" alt="Image" height="50" class="mb-3" />
      <div class="text-900 text-4xl font-bold mb-3">{{ title }}</div>
      <div class="mb-3">Please enter a new password</div>
    </div>
    <div>
      <app-form-fields-wrapper>
        <input
          *appFormField
          formControlName="password"
          type="password"
          placeholder="New Password"
          pInputText
          class="w-full mb-1"
        />
        <input
          *appFormField
          formControlName="confirmPassword"
          type="password"
          placeholder="Confirm Password"
          pInputText
          class="w-full mb-1"
        />
      </app-form-fields-wrapper>

      <button
        pButton
        pRipple
        class="w-full ui-button"
        [disabled]="processing"
        [icon]="processing ? 'pi pi-spin pi-spinner' : ''"
        [label]="buttonText | uppercase"
        (click)="onSubmit()"
      ></button>
    </div>
  </div>
</form>
