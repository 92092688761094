import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TableRowData } from '../../../interfaces';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@Component({
  selector: 'app-image-cell-renderer',
  templateUrl: './image-cell-renderer.component.html',
  styleUrls: ['./image-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<string, R>
  implements OnInit
{
  rounded = true;
  height = '100%';
  icon = 'pi pi-image';

  ngOnInit(): void {
    if (this.getOptions()['rounded']) {
      this.rounded = this.getOptions()['rounded'] as boolean;
    }
    if (this.getOptions()['icon']) {
      this.icon = this.getOptions()['icon'] as string;
    }
    if (this.getOptions()['height']) {
      this.height = this.getOptions()['height'] as string;
    }
  }

  override render(): string {
    return this.getRawValue() || '';
  }
}
