import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { CreateCompanyRequest } from '@core/models/create-company-request.model';
import { Company } from '@core/models/company.model';
import { UpdateCompanyRequest } from '@core/models/update-company-request.model';
import { CategoryShort } from '@core/models';
import { UserShort } from '@core/models/user-short.model';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(private http: HttpClient) {}

  getCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(environment.API_DOMAIN + '/companies');
  }

  createCompany(payload: CreateCompanyRequest): Observable<Company> {
    return this.http.post<Company>(
      environment.API_DOMAIN + '/companies',
      payload,
    );
  }

  updateCompany(
    id: string,
    payload: UpdateCompanyRequest,
  ): Observable<Company> {
    return this.http.patch<Company>(
      environment.API_DOMAIN + '/companies/' + id,
      payload,
    );
  }

  deleteCompany(id: string): Observable<void> {
    return this.http.delete<void>(environment.API_DOMAIN + '/companies/' + id);
  }

  getCategories(companyId: string): Observable<CategoryShort[]> {
    return this.http.get<CategoryShort[]>(
      environment.API_DOMAIN + `/company/${companyId}/categories`,
    );
  }
  getClientCategories(companyId: string): Observable<CategoryShort[]> {
    return this.http.get<CategoryShort[]>(
      environment.API_DOMAIN + `/company/${companyId}/client-categories`,
    );
  }

  getExternalUsers(companyId: string): Observable<UserShort[]> {
    return this.http.get<UserShort[]>(
      environment.API_DOMAIN + `/company/${companyId}/external-users`,
    );
  }
}
