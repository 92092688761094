import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BaseUploadDocumentsDirective } from '@ui/components/upload-documents/base-upload-documents.directive';
import { MAX_UPLOAD_SIZE } from '@common/shared/constants/upload';

@Component({
  selector: 'app-upload-bulk-documents',
  templateUrl: './upload-bulk-documents.component.html',
  styleUrls: ['./upload-bulk-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadBulkDocumentsComponent
  extends BaseUploadDocumentsDirective
  implements OnInit, OnDestroy, AfterViewInit
{
  override readonly filesLimit = 50;
  protected readonly MAX_UPLOAD_SIZE = MAX_UPLOAD_SIZE;
}
