<form *ngIf="form" [formGroup]="form" class="create-company-form-content">
  <div class="p-2 w-full">
    <app-form-fields-wrapper>
      <input
        *appFormField="'logoUrl'"
        formControlName="logoUrl"
        type="hidden"
        pInputText
      />
      <input
        *appFormField="'id'"
        formControlName="id"
        type="hidden"
        pInputText
      />
      <input
        *appFormField="'name'"
        [autofocus]="true"
        formControlName="name"
        type="text"
        placeholder="Title"
        pInputText
        class="w-full mb-1"
      />
      <input
        *appFormField="'email'"
        formControlName="email"
        type="text"
        placeholder="Email address"
        pInputText
        class="w-full mb-1"
      />
      <textarea
        *appFormField="'description'"
        formControlName="description"
        type="text"
        placeholder="Description"
        pInputTextarea
        class="w-full mb-1"
      ></textarea>
    </app-form-fields-wrapper>
  </div>
</form>
