import { NgModule } from '@angular/core';
import { FormFieldsWrapperComponent } from '@ui/forms/elements/form-fields-wrapper/form-fields-wrapper.component';
import { PrimengUiModule } from '@ui/primeng/primeng-ui.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormFieldDirective } from './form-fields-wrapper/form-field.directive';
import { CoreModule } from '@core/core.module';
import { ContactsDropdownComponent } from './contacts-dropdown/contacts-dropdown.component';
import { CommonUiComponentsModule } from '@ui/components/common/common-ui-components.module';
import { CategoriesDropdownComponent } from './categories-dropdown/categories-dropdown.component';
import { SharedCommonModule } from '@common/shared/shared-common.module';
import { AngularCdkModule } from '@ui/cdk/angular-cdk.module';
import { ContactsListboxComponent } from './contacts-listbox/contacts-listbox.component';

@NgModule({
  declarations: [
    FormFieldsWrapperComponent,
    FormFieldDirective,
    ContactsDropdownComponent,
    CategoriesDropdownComponent,
    ContactsListboxComponent,
  ],
  exports: [
    FormFieldsWrapperComponent,
    FormFieldDirective,
    ContactsDropdownComponent,
    CategoriesDropdownComponent,
    ContactsListboxComponent,
  ],
  imports: [
    CommonModule,
    PrimengUiModule,
    ReactiveFormsModule,
    CoreModule,
    CommonUiComponentsModule,
    FormsModule,
    SharedCommonModule,
    AngularCdkModule,
  ],
})
export class FormElementsUiModule {}
