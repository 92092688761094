<div class="email-form">
  <form [formGroup]="form" class="auth-form-content" (ngSubmit)="onSubmit()">
    <div class="auth-form-button p-mb-3">
      <p-button
        type="submit"
        pRipple
        iconPos="left"
        styleClass="ui-button"
        [disabled]="
          !(changed$ | async) || form.invalid || (processing$ | async)
        "
        [icon]="(processing$ | async) ? 'pi pi-spin pi-spinner' : ''"
        label="Update"
      ></p-button>
    </div>
  </form>
</div>
