import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { DashboardStats } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  constructor(private http: HttpClient) {}

  getCompanyDashboard(
    companyId: string,
    period: string[],
  ): Observable<DashboardStats> {
    return this.http.get<DashboardStats>(
      `${environment.API_DOMAIN}/company/${companyId}/dashboard?from=${period[0]}&to=${period[1]}`,
    );
  }
}
