import { DashboardStats } from '@core/models';

export class GetDashboard {
  static readonly type = '[DASHBOARD] Get Stats';

  constructor(public companyId: string, public period: [Date, Date]) {}
}

export class DashboardLoaded {
  static readonly type = '[DASHBOARD] Stats Loaded';

  constructor(public payload: DashboardStats) {}
}
