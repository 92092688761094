export class Login {
  static readonly type = '[Auth] Login';

  constructor(public payload: { username: string; password: string }) {}
}
export class LoginBySocialProvider {
  static readonly type = '[Auth] Social Provider Login';

  constructor(public payload: { idToken: string }) {}
}

export class RefreshToken {
  static readonly type = '[Auth] Refresh Token';

  constructor() {}
}

export class ConfirmEmailToken {
  static readonly type = '[Auth] Confirm Email Token';

  constructor(public token: string) {}
}

export class EmailConfirmed {
  static readonly type = '[Auth] Email Confirmed';

  constructor() {}
}

export class ClearEmailConfirmationResults {
  static readonly type = '[Auth] Clear Email Confirmation Results';

  constructor() {}
}

export class SendRestorePassword {
  static readonly type = '[Auth] Send Restore Password';

  constructor(public payload: { email: string }) {}
}
export class RestorePasswordSent {
  static readonly type = '[Auth] Email Restore Password Sent';

  constructor() {}
}

export class CheckRestorePasswordToken {
  static readonly type = '[Auth]Check Restore Password Token';

  constructor(public payload: { token: string }) {}
}

export class RestorePassword {
  static readonly type = '[Auth] Restore Password';

  constructor(
    public payload: {
      token: string;
      password: string;
      confirmPassword: string;
    },
  ) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}
