export enum MediaType {
  Jpeg = 'image/jpeg',
  Jpg = 'image/jpg',
  Png = 'image/png',
  Gif = 'image/gif',
  Psd = 'image/vnd.adobe.photoshop',
  Pdf = 'application/pdf',
  Text = 'text/plain',
  Csv = 'text/csv',
  Csv2 = 'application/csv',
  Doc = 'application/msword',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Xls = 'application/vnd.ms-excel',
  Ods = 'application/vnd.oasis.opendocument.spreadsheet',
  Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Other = 'application/octet-stream',
}
