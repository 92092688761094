import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilesUploadService {
  constructor(private http: HttpClient) {}

  getInternalImage(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob',
    });
  }

  uploadFiles(
    files: File[],
    url: string,
  ): Observable<
    | {
        type: HttpEventType;
        progress?: {
          loaded: number;
          total: number;
        };
        response?: any[];
      }
    | any
  > {
    const formData: FormData = new FormData();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i], files[i].name);
    }
    // TODO: need to add correct handling of those files that have empty mime-type
    return this.http
      .post<any[]>(url, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        filter((event) =>
          [HttpEventType.Response, HttpEventType.UploadProgress].includes(
            event.type,
          ),
        ),
      )
      .pipe(
        map((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            return {
              type: event.type,
              progress: {
                total: event.total,
                loaded: event.loaded,
              },
            };
          } else if (event.type === HttpEventType.Response) {
            return {
              type: event.type,
              response: (event as HttpResponse<any[]>).body,
            };
          }
          return null;
        }),
      );
  }

  uploadPublicFiles(files: File[]): Observable<
    | {
        type: HttpEventType;
        progress?: {
          loaded: number;
          total: number;
        };
        response?: any[];
      }
    | any
  > {
    return this.uploadFiles(files, environment.API_DOMAIN + '/assets/upload');
  }

  uploadCompanyDocumentFile(
    file: File,
    companyId: string,
  ): Observable<
    | {
        type: HttpEventType;
        progress?: {
          loaded: number;
          total: number;
        };
        response?: any[];
      }
    | any
  > {
    return this.uploadFiles(
      [file],
      environment.API_DOMAIN + '/company/' + companyId + '/documents/upload',
    );
  }
}
