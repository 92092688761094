import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { Nullable } from '@core/interfaces/nullable';
import { Document, DocumentShareRequest, SharedDocument } from '@core/models';
import { DocumentsService } from '@core/services/api/documents.service';
import { DocumentSource } from '@core/enums/document-source.enum';
import { UploadedFile } from '@core/models/uploaded-file.model';
import { doc } from '@angular/fire/firestore';

@Component({
  selector: 'app-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewDocumentComponent implements OnDestroy, OnInit {
  @Input() show!: boolean;
  @Input() showActions!: boolean;
  @Input() request!: Nullable<DocumentShareRequest>;

  @Input() set document(document: Nullable<Document>) {
    this.documentValue = document;
    if (document?.file) {
      this.file$.next(document.file);
    } else {
      this.file$.next(null);
    }
  }

  get document(): Nullable<Document> {
    return this.documentValue;
  }

  @Output() showChange = new EventEmitter<boolean>();
  @Output() share = new EventEmitter<void>();
  @Output() accept = new EventEmitter<any>();
  @Output() decline = new EventEmitter<any>();

  sources = DocumentSource;

  file$ = new ReplaySubject<Nullable<UploadedFile>>(1);

  private documentValue!: Nullable<Document>;
  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {}

  onCancel(): void {
    this.showChange.emit(false);
    this.documentValue = null;
    this.file$.next(null);
  }

  onShare(): void {
    this.share.emit();
    this.onCancel();
  }

  protected readonly doc = doc;
}
