<form *ngIf="form" [formGroup]="form" class="create-contact-form-content">
  <div class="p-2 w-full">
    <input *appFormField="'id'" formControlName="id" type="hidden" pInputText />
    <input
      *appFormField="'avatar'"
      formControlName="avatar"
      type="hidden"
      pInputText
    />

    <div class="field mb-3 p-field">
      <label>Email<span class="required">*</span></label>
      <input
        autocomplete="off"
        [attr.data-1p-ignore]="true"
        [autofocus]="true"
        [readonly]="!!model?.id"
        formControlName="email"
        type="text"
        placeholder="Enter contact email"
        pInputText
        class="w-full mb-1"
        (input)="searchComplete$.next(false)"
      />
    </div>
    <button
      *ngIf="!model || !model.id"
      pButton
      pRipple
      [disabled]="form.get('email')?.invalid || (search$ | async) === true"
      label="Find"
      (click)="onSearch(form.get('email')?.value)"
    ></button>
    <div *ngIf="(searchComplete$ | async) === true" class="mb-4 mt-4">
      <ng-container *ngIf="foundContact$ | async"
        ><span class="font-bold">Fyio user is found:</span>
        {{ (foundContact$ | async)?.email }}</ng-container
      >
      <ng-container *ngIf="(foundContact$ | async) === null"
        ><span class="font-bold">{{ contact?.email }}</span> is not registered
        in fyio app yet.<br />You can still add this email to your
        contacts</ng-container
      >
    </div>
    <div class="flex align-items-center justify-content-between mt-4">
      <div>
        <div class="field mb-3 p-field">
          <label>First Name<span class="required">*</span></label>
          <input
            [attr.data-1p-ignore]="true"
            autocomplete="off"
            formControlName="firstName"
            type="text"
            placeholder="Enter first name"
            pInputText
            class="w-full mb-1"
          />
        </div>
        <div class="field mb-3 p-field">
          <label>Last Name<span class="required">*</span></label>
          <input
            [attr.data-1p-ignore]="true"
            autocomplete="off"
            formControlName="lastName"
            type="text"
            placeholder="Enter last name"
            pInputText
            class="w-full mb-1"
          />
        </div>
      </div>
      <div class="contact__avatar">
        <div
          *ngIf="(searchComplete$ | async) && (foundContact$ | async)"
          class="fyio-label"
        ></div>
        <ng-container *ngIf="(foundContact$ | async)?.avatar">
          <img
            *ngIf="contact"
            class="ml-4"
            [src]="(foundContact$ | async)?.avatar"
            size="large"
            appDefaultImage
          />
        </ng-container>
        <ng-container *ngIf="!(foundContact$ | async)?.avatar">
          <p-avatar
            size="large"
            styleClass="ml-4"
            shape="circle"
            [label]="
              (contact || (foundContact$ | async))?.name
                | uppercase
                | capitalLetters : 2
            "
          ></p-avatar>
        </ng-container>
      </div>
    </div>
    <div>
      <div class="field mb-3 p-field">
        <label>Groups</label>
        <p-multiSelect
          [filter]="true"
          filterBy="name"
          [virtualScroll]="true"
          [virtualScrollItemSize]="50"
          [disabled]="disabled"
          [options]="groups | filterByField : 'id' : null"
          placeholder="Search for a group"
          optionValue="id"
          optionLabel="name"
          styleClass="w-full mb-1"
          formControlName="groups"
        >
          <ng-template let-items pTemplate="selectedItems">
            <ng-container *ngIf="items?.length > 0; else empty">
              Selected ({{ items.length }})
            </ng-container>
            <ng-template #empty> Search for a group</ng-template>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
  </div>
</form>
<div class="p-2 w-full flex flex-wrap">
  <p-chip
    *ngFor="let group of selectedGroups"
    [label]="group.name"
    [removable]="true"
    removeIcon="pi pi-times"
    (onRemove)="onDeselect(group.id)"
  ></p-chip>
</div>
