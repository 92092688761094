import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { NgxsModule } from '@ngxs/store';
import { ProfileState } from '@store/profile/states/profile.state';
import { ProfileStoreService } from '@store/profile/profile-store.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, CoreModule, NgxsModule.forFeature([ProfileState])],
  providers: [ProfileStoreService, ProfileState],
})
export class ProfileStoreModule {}
