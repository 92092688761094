import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName',
})
export class FileNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const split = value.split('.');
    if (split.length > 1) {
      split.splice(split.length - 1, 1);
    }
    return split.join('.');
  }
}
