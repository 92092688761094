<form
  *ngIf="form"
  [formGroup]="form"
  class="auth-form-content"
  (ngSubmit)="onSubmit($event)"
>
  <div class="surface-card p-4 shadow-2 border-round w-full">
    <div class="text-center mb-5">
      <img src="/assets/images/logo.png" alt="Image" height="50" class="mb-3" />
      <div class="text-900 text-4xl font-bold mb-3">{{ title }}</div>
      <div class="mb-3">Please sign in to your account to proceed</div>
    </div>
    <div>
      <div class="field mb-3">
        <button
          pButton
          pRipple
          type="button"
          iconPos="left"
          icon="pi pi-google"
          label="Continue with Google"
          class="w-full p-button-outlined"
          (click)="onGoogleSubmit($event)"
        ></button>
      </div>
      <div class="field splitter mb-3">
        <div class="split-left"></div>
        <div>OR</div>
        <div class="split-right"></div>
      </div>
      <app-form-fields-wrapper>
        <input
          *appFormField
          formControlName="email"
          type="text"
          placeholder="Email address"
          pInputText
          class="w-full mb-1"
        />
        <input
          *appFormField
          formControlName="password"
          type="password"
          placeholder="Password"
          pInputText
          class="w-full mb-1"
        />
      </app-form-fields-wrapper>

      <div class="flex align-items-center justify-content-between mb-6">
        <div class="flex align-items-center">
          <p-checkbox
            id="rememberme1"
            [binary]="true"
            styleClass="mr-2"
          ></p-checkbox>
          <label for="rememberme1" class="text-900">Remember me</label>
        </div>
        <a
          class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
          [routerLink]="'/auth/forgot-password'"
          >Forgot password?</a
        >
      </div>

      <button
        pButton
        pRipple
        class="w-full ui-button"
        [disabled]="processing"
        [icon]="processing ? 'pi pi-spin pi-spinner' : ''"
        [label]="buttonText | uppercase"
        (click)="onSubmit($event)"
      ></button>
      <div class="mt-3 mb-3 text-xs text-center">
        By clicking Continue you agree with Fyio
        <a href="#" class="no-underline text-blue-500 cursor-pointer"
          >Terms & conditions</a
        >
        and
        <a href="#" class="no-underline text-blue-500 cursor-pointer"
          >Privacy policy</a
        >
        services
      </div>
    </div>
  </div>
  <div
    class="mt-6 mb-3 text-center flex align-items-center justify-content-center"
  ></div>
</form>
