import { Inject, Injectable } from '@angular/core';
import { AuthService, PROTECTED_FALLBACK_PAGE_URI } from 'ngx-auth';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import {
  firstValueFrom,
  NEVER,
  Observable,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { Router } from '@angular/router';
import { User } from '@core/models/user.model';
import { AuthStoreService } from '@store/auth/auth-store.service';
import { Nullable } from '@core/interfaces/nullable';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { fromArrayLike, fromPromise } from 'rxjs/internal/observable/innerFrom';
import { GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { NGXLogger } from 'ngx-logger';
import { Account, CreateAccountRequest } from '@core/models';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { AuthState } from '@store/auth/auth.state';

@Injectable()
export class AuthenticationService implements AuthService {
  private interruptedUrl: string | null = null;

  private logout$ = new Subject<void>();
  private login$ = new Subject<User>();

  constructor(
    private authStoreService: AuthStoreService,
    private router: Router,
    private logger: NGXLogger,
    @Inject(PROTECTED_FALLBACK_PAGE_URI) private successUrl: string,
    private authFirebase: AngularFireAuth,
  ) {}

  getAccessToken(): Observable<string> {
    return this.authStoreService.getAccessToken() as Observable<string>;
  }

  isAuthorized(): Observable<boolean> {
    return this.authStoreService.isAuthenticated();
  }

  refreshShouldHappen(
    response: HttpErrorResponse,
    request?: HttpRequest<any>,
  ): boolean {
    return (
      response.status === 401 &&
      !response.url?.endsWith('auth/login') &&
      !response.url?.endsWith('auth/refresh-token')
    );
  }

  refreshToken(): Observable<any> {
    return this.authStoreService.refreshToken().pipe(
      switchMap(() => this.authStoreService.isAuthenticated()),
      switchMap(() => this.authStoreService.isAccessTokenExpired()),
      map((isExpired) => !isExpired),
      filter((isAuth) => isAuth),
      take(1),
    );
  }

  setInterruptedUrl(url: string): void {
    this.interruptedUrl = url;
  }

  getInterruptedUrl(): string {
    return this.interruptedUrl || this.successUrl;
  }

  skipRequest(request: HttpRequest<any>): boolean {
    return false;
  }

  verifyRefreshToken(request: HttpRequest<any>): boolean {
    return request.url.endsWith('auth/refresh-token');
  }

  verifyTokenRequest(url: string): boolean {
    return false;
  }

  login(username: string, password: string): Observable<User | null> {
    return this.authStoreService.login(username, password).pipe(
      tap((user) => {
        this.login$.next(user);
      }),
    );
  }

  loginWithGoogle(): Observable<Nullable<User>> {
    return fromPromise(
      this.authFirebase
        .signInWithPopup(new GoogleAuthProvider())
        .then((result) => {
          return result.user?.getIdToken().then((idToken) => {
            return firstValueFrom(
              this.authStoreService.loginBySocialProvider(idToken),
            );
          });
        })
        .catch((error) => {
          this.logger.error(
            'AuthenticationService.loginWithGoogle error',
            error,
          );
          return null;
        }),
    ).pipe(
      tap((user) => {
        this.login$.next(user as User);
      }),
    );
  }

  loginWithFacebook(): Observable<Nullable<User>> {
    return fromPromise(
      this.authFirebase
        .signInWithPopup(new FacebookAuthProvider())
        .then((result) => {
          return result.user?.getIdToken().then((idToken) => {
            return firstValueFrom(
              this.authStoreService.loginBySocialProvider(idToken),
            );
          });
        })
        .catch((error) => {
          this.logger.error(
            'AuthenticationService.loginWithFacebook error',
            error,
          );
          return null;
        }),
    );
  }

  createAccount(userData: CreateAccountRequest): Observable<Account> {
    return this.authStoreService.createAccount(userData);
  }

  createAccountWithGoogle(): Observable<Nullable<Account>> {
    return fromPromise(
      this.authFirebase
        .signInWithPopup(new GoogleAuthProvider())
        .then((result) => {
          return result.user?.getIdToken().then((idToken) => {
            return firstValueFrom(
              this.authStoreService.createAccountBySocial(idToken),
            );
          });
        })
        .catch((error) => {
          this.logger.error(
            'AuthenticationService.createAccountWithGoogle error',
            error,
          );
          return null;
        }),
    );
  }

  checkRestorePasswordToken(token: string): Observable<string> {
    return this.authStoreService.checkRestorePasswordToken(token);
  }

  sendRestorePassword(email: string): Observable<void> {
    return this.authStoreService.sendRestorePassword(email);
  }

  restorePassword(
    token: string,
    password: string,
    confirmPassword: string,
  ): Observable<void> {
    return this.authStoreService.restorePassword(
      token,
      password,
      confirmPassword,
    );
  }

  logout(): void {
    this.authStoreService.logout();
    this.logout$.next();
    this.router.navigateByUrl('/login');
  }

  getIdentity(): Observable<Nullable<User>> {
    return this.authStoreService.getUserInfo();
  }

  onLogout(): Observable<void> {
    return this.logout$.asObservable();
  }
  onLogin(): Observable<User> {
    return this.login$.asObservable();
  }
}
