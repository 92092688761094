import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IconOption, TableRowData } from '../../../interfaces';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@Component({
  selector: 'app-icon-cell-renderer',
  templateUrl: './icon-cell-renderer.component.html',
  styleUrls: ['./icon-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<string, R>
  implements OnInit
{
  size = 'sm';
  defaultIcon?: string;
  icons: { [key: string]: IconOption } | undefined;

  ngOnInit(): void {
    if (this.getOptions()['defaultIcon']) {
      this.defaultIcon = this.getOptions()['defaultIcon'] as string;
    }
    if (this.getOptions()['size']) {
      this.size = this.getOptions()['size'] as string;
    }
    if (this.getOptions()['icons']) {
      this.icons = this.getOptions()['icons'] as any;
    }
  }

  override render(): string {
    return this.icon ? this.icon.icon : (this.defaultIcon as string);
  }

  get icon(): IconOption | undefined {
    const val: string = this.getRawValue() as string;
    const icon = this.icons && (this.icons[val] as any);
    if (icon && !icon?.size) {
      icon.size = this.size;
    }
    return icon;
  }
}
