<p-dialog
  modal="true"
  position="right"
  [draggable]="false"
  [focusOnShow]="true"
  [closeOnEscape]="false"
  header="Review Document"
  [style]="{ width: '100%', height: '100vw' }"
  [closable]="true"
  [(visible)]="show"
  (onHide)="onCancel()"
>
  <div class="w-full h-full grid">
    <div class="preview-container col-5">
      <app-document-preview-widget
        [file]="file$ | async"
      ></app-document-preview-widget>
    </div>
    <div class="col-7">
      <ng-container *ngIf="request">
        <app-document-request-details
          [showActions]="showActions"
          [document]="document"
          [request]="request"
          (approve)="accept.emit(request)"
          (decline)="decline.emit(request)"
        ></app-document-request-details>
      </ng-container>
      <ng-container *ngIf="document && !request">
        <app-document-details
          [showActions]="document.source === sources.Company"
          [document]="document"
        ></app-document-details>
      </ng-container>

      <ng-container *ngIf="document">
        <app-document-share-details
          [canBeShared]="document.canBeShared"
          [document]="document"
          (share)="share.emit()"
        ></app-document-share-details>
      </ng-container>
    </div>
  </div>
</p-dialog>
