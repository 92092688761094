export enum DataType {
  Date = 'date',
  String = 'string',
  Number = 'integer',
  Float = 'float',
  Boolean = 'boolean',
  Currency = 'currency',
  Image = 'image',
  Select = 'select',
  MultiSelect = 'multi-select',
}
