import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseFormDirective } from '@ui/forms/base-form.component';
import { Validators } from '@angular/forms';
import { CustomValidators } from '@common/shared/utils/validation/custom-validators';

@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailFormComponent extends BaseFormDirective<{ email: string }> {
  override initForm(model: { email: string }) {
    this.form = this.fb.group({
      email: this.fb.control(model?.email, [
        Validators.required,
        Validators.email,
      ]),
      password: this.fb.control<string>('', [Validators.required]),
    });
  }
}
