import { NgModule } from '@angular/core';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { PasswordModule } from 'primeng/password';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';
import { SpinnerModule } from 'primeng/spinner';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { CardModule } from 'primeng/card';
import { SharedModule } from 'primeng/api';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PanelMenuModule } from 'primeng/panelmenu';
import { FileUploadModule } from 'primeng/fileupload';
import { ChipsModule } from 'primeng/chips';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { DataViewModule } from 'primeng/dataview';
import { InplaceModule } from 'primeng/inplace';
import { MegaMenuModule } from 'primeng/megamenu';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TreeTableModule } from 'primeng/treetable';
import { ImageModule } from 'primeng/image';
import { TagModule } from 'primeng/tag';
import { AccordionModule } from 'primeng/accordion';
import { Chip, ChipModule } from 'primeng/chip';
import { ListboxModule } from 'primeng/listbox';
import { TreeModule } from 'primeng/tree';
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
  declarations: [],
  imports: [],
  exports: [
    MenuModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    RippleModule,
    BreadcrumbModule,
    PasswordModule,
    PanelModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    SpinnerModule,
    TooltipModule,
    TableModule,
    ToolbarModule,
    CardModule,
    SharedModule,
    ContextMenuModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    EditorModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    InputSwitchModule,
    PanelMenuModule,
    FileUploadModule,
    ChipsModule,
    SelectButtonModule,
    MultiSelectModule,
    AutoCompleteModule,
    OverlayPanelModule,
    TabViewModule,
    ChartModule,
    DropdownModule,
    DataViewModule,
    InplaceModule,
    MegaMenuModule,
    BadgeModule,
    AvatarModule,
    ScrollPanelModule,
    CarouselModule,
    GalleriaModule,
    CalendarModule,
    RadioButtonModule,
    DialogModule,
    CheckboxModule,
    InputTextareaModule,
    TreeTableModule,
    ImageModule,
    TagModule,
    AccordionModule,
    OverlayPanelModule,
    ChipsModule,
    ChipModule,
    ListboxModule,
    TreeModule,
    PaginatorModule,
  ],
})
export class PrimengUiModule {}
