<ng-container *ngFor="let el of elements; let i = index">
  <div class="field mb-3 p-field">
    <label *ngIf="showLabels"
      >{{ el.appFormFieldLabel || ''
      }}<span
        class="required"
        *ngIf="!!el.appFormFieldLabel && hasRequiredValidator(el.appFormField)"
        >*</span
      ></label
    >
    <ng-container
      *ngTemplateOutlet="
        el.templateRef;
        context: { $implicit: control(el.appFormField) }
      "
    ></ng-container>
    <small
      *ngIf="hasErrors(el.appFormField)"
      class="p-error ui-message ui-message-error"
      [innerHTML]="errorMessage(el.appFormField) | safeHtml"
    ></small>
  </div>
</ng-container>
