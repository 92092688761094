export enum FileType {
  TXT = 'TXT',
  DOC = 'DOC',
  DOCX = 'DOCX',
  XLS = 'XLS',
  XLSX = 'XLSX',
  ODS = 'ODS',
  JPG = 'JPG',
  JPEG = 'JPEG',
  PNG = 'PNG',
  GIF = 'GIF',
  PDF = 'PDF',
  PSD = 'PSD',
  CSV = 'CSV',
  OTHER = 'OTHER',
}
