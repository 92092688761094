import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  breadcrumbs: MenuItem[] = [
    {
      label: 'Home',
      icon: 'pi pi-home pr-1',
      routerLink: '/',
    },
  ];
  private changed$: BehaviorSubject<MenuItem[]> = new BehaviorSubject(
    [] as MenuItem[],
  );

  constructor() {}

  clear(): void {
    this.breadcrumbs = [
      {
        label: 'Home',
        icon: 'pi pi-home pr-1',
        routerLink: '/',
      },
    ];
    this.changed$.next([...this.breadcrumbs]);
  }

  push(...items: MenuItem[]): void {
    this.breadcrumbs.push(...items);
    this.changed$.next([...this.breadcrumbs]);
  }

  pop(): void {
    if (this.breadcrumbs.length > 1) {
      this.breadcrumbs.pop();
    }
    this.changed$.next([...this.breadcrumbs]);
  }

  values(): Observable<MenuItem[]> {
    return this.changed$.asObservable();
  }
}
