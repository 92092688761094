import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthStoreModule } from '@store/auth/auth-store.module';
import { AdminStoreModule } from '@store/admin/admin-store.module';
import { ProfileStoreModule } from '@store/profile/profile-store.module';
import { AccountStoreModule } from '@store/account/account-store.module';
import { CompaniesStoreModule } from '@store/companies/companies-store.module';
import { RequestsStoreModule } from '@store/requests/requests-store.module';
import { CategoriesStoreModule } from '@store/categories/categories-store.module';
import { ContactsStoreModule } from '@store/contacts/contacts-store.module';
import { DocumentsStoreModule } from '@store/documents/documents-store.module';
import { DashboardStoreModule } from '@store/dashboard/dashboard-store.module';
import { SharedDocumentsModule } from '@store/shared-documents/shared-documents.module';
import { CoreModule } from '@core/core.module';
import { NgxsModule } from '@ngxs/store';
import { SharedDocumentsState } from '@store/shared-documents/shared-documents.state';
import { SharedDocumentsStoreService } from '@store/shared-documents/shared-documents-store.service';
import { ContactGroupsStoreModule } from '@store/contact-groups/contact-groups-store.module';
import { SharingProgressStoreModule } from '@store/sharing-progress/sharing-progress-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreModule,
    NgxsModule.forFeature([SharedDocumentsState]),
  ],
  exports: [
    AuthStoreModule,
    AdminStoreModule,
    ProfileStoreModule,
    AccountStoreModule,
    CompaniesStoreModule,
    RequestsStoreModule,
    CategoriesStoreModule,
    ContactsStoreModule,
    DocumentsStoreModule,
    DashboardStoreModule,
    SharedDocumentsModule,
    ContactGroupsStoreModule,
    SharingProgressStoreModule,
  ],
  providers: [SharedDocumentsState, SharedDocumentsStoreService],
})
export class SharedStoreModule {}
