import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { BaseFormDirective } from '@ui/forms/base-form.component';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactGroup } from '@core/models';
import { take, takeUntil } from 'rxjs/operators';
import { CreateContact } from '@core/models/create-contact.model';
import { Contact } from '@core/models/contact.model';
import { Nullable } from '@core/interfaces/nullable';
import { NGXLogger } from 'ngx-logger';
import { FormHelper } from '@common/shared/helpers/form-helper';
import { ContactsStoreService } from '@store/contacts/contacts-store.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-add-contact-form',
  templateUrl: './add-contact-form.component.html',
  styleUrls: ['./add-contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddContactFormComponent extends BaseFormDirective<
  CreateContact & { id?: string }
> {
  @Input() groups!: ContactGroup[];

  selectedGroups!: ContactGroup[];
  search$ = new BehaviorSubject<boolean>(false);
  searchComplete$ = new BehaviorSubject<boolean>(false);

  contact: Nullable<Contact> = null;
  foundContact$: Observable<Nullable<Contact>> =
    this.contactsService.selectFyoExternalContact();

  constructor(
    private contactsService: ContactsStoreService,
    fb: FormBuilder,
    logger: NGXLogger,
    cdr: ChangeDetectorRef,
    formHelper: FormHelper,
  ) {
    super(fb, logger, cdr, formHelper);
  }

  override initForm(model?: CreateContact & { id?: string }): void {
    this.form = this.fb.group({
      id: this.fb.control(model?.id),
      firstName: this.fb.control(model?.firstName, [Validators.required]),
      lastName: this.fb.control(model?.lastName, [Validators.required]),
      email: this.fb.control(model?.email, [
        Validators.required,
        Validators.pattern(/[0-9a-zA-Z\\.-]@[a-zA-Z\\.-]+\.[a-zA-Z]{2,4}$/),
      ]),
      avatar: this.fb.control(model?.avatar),
      groups: this.fb.control(model?.groups || []),
    });
    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => this.onChanged(value));
  }

  clear(): void {
    this.searchComplete$.next(false);
    this.contactsService.clearExternalContactsSearch();
    this.formHelper.reset(this.form);
  }

  onSearch(email: string): void {
    this.search$.next(true);
    this.searchComplete$.next(false);
    this.contactsService
      .searchFyioExternalContact(email)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          if (data) {
            this.form.patchValue({
              firstName: data?.firstName || '',
              lastName: data?.lastName || '',
              avatar: data?.avatar || null,
            });
          }
        },
        complete: () => {
          this.search$.next(false);
          this.searchComplete$.next(true);
          this.cdr.markForCheck();
        },
      });
  }

  onChanged(value: {
    groups: string[];
    email: string;
    firstName: string;
    lastName: string;
  }): void {
    this.contact = {
      email: value.email,
      firstName: value.firstName,
      lastName: value.lastName,
      name: `${value.firstName ? value.firstName.trim() : ''} ${
        value.lastName ? value.lastName.trim() : ''
      }`,
    } as Contact;
    this.selectedGroups = (value?.groups || [])
      .map((id) => this.groups?.find((c) => c.id === id))
      .filter((s) => !!s) as ContactGroup[];

    this.cdr.detectChanges();
  }

  onDeselect(id: string): void {
    this.form.patchValue({
      groups: this.form.get('groups')?.value?.filter((v: string) => v !== id),
    });
  }
}
