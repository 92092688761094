import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseFormDirective } from '@ui/forms/base-form.component';
import { Validators } from '@angular/forms';
import { User, UserProfile } from '@core/models';

@Component({
  selector: 'app-profile-details-form',
  templateUrl: './profile-details-form.component.html',
  styleUrls: ['./profile-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileDetailsFormComponent extends BaseFormDirective<
  Partial<UserProfile>
> {
  override initForm(model?: Partial<UserProfile>) {
    this.form = this.fb.group({
      id: this.fb.control(model?.id),
      firstName: this.fb.control(model?.firstName, [Validators.required]),
      lastName: this.fb.control(model?.lastName, [Validators.required]),
      avatar: model?.avatar,
    });
  }
}
