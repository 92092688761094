<form *ngIf="form" [formGroup]="form" class="create-document-form-content">
  <div class="p-2 w-full">
    <app-form-fields-wrapper [showLabels]="true">
      <input
        *appFormField="'name'; label: 'Document Name'"
        [autofocus]="true"
        [readonly]="isDisabled('name')"
        formControlName="name"
        type="text"
        placeholder="Enter name"
        pInputText
        class="w-full mb-1"
      />

      <ng-container *ngIf="categories">
        <app-categories-dropdown
          *appFormField="'category'; label: 'Category'"
          [disabled]="disabled"
          [placeholder]="'Select category'"
          [categories]="categories"
          formControlName="category"
        ></app-categories-dropdown>
      </ng-container>
    </app-form-fields-wrapper>
  </div>
</form>
