import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SocketsService } from '@common/ws-notifications/sockets.service';
import { MessageService } from 'primeng/api';
import { SharingProgressStoreService } from '@store/sharing-progress/sharing-progress-store.service';
import { filter, map, take } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { ProgressStatus } from '@common/shared/types/progress-status.enum';

@UntilDestroy()
@Component({
  selector: 'app-sharing-progress-widget',
  templateUrl: './sharing-progress-widget.component.html',
  styleUrls: ['./sharing-progress-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharingProgressWidgetComponent implements OnInit {
  collapsed = true;
  closed!: boolean;

  progress$ = this.sharingProgressService
    .selectActiveTaskProgress()
    .pipe(filter((d) => !!d));

  constructor(
    private ws: SocketsService,
    private sharingProgressService: SharingProgressStoreService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnInit() {
    this.checkProgressStatus();
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.checkProgressStatus();
      });
    this.ws
      .events()
      .pipe(untilDestroyed(this))
      .pipe(filter(({ event }) => event === 'document:share:progress'))
      .subscribe(({ payload }) => {
        this.sharingProgressService.saveProgress(payload.taskId, payload);
        if (payload.status === ProgressStatus.Completed) {
          this.sharingProgressService.progressComplete(payload.taskId);
        }
        this.cdr.markForCheck();
      });
    this.ws
      .events()
      .pipe(untilDestroyed(this))
      .pipe(filter(({ event }) => event === 'document:share:completed'))
      .subscribe(({ payload }) => {
        this.sharingProgressService.progressComplete(payload.taskId);
        this.cdr.markForCheck();
      });
    this.ws
      .events()
      .pipe(untilDestroyed(this))
      .pipe(filter(({ event }) => event === 'document:share:started'))
      .subscribe(({ payload }) => {
        this.collapsed = false;
        this.sharingProgressService.saveProgress(payload.taskId, payload);
        this.cdr.markForCheck();
      });
    this.ws
      .notifications()
      .pipe(untilDestroyed(this))
      .subscribe(({ message, severity, title }) => {
        this.messageService.add({
          detail: message,
          summary: title,
          severity: severity,
          life: 10000,
        });
      });
  }

  onClosed() {
    this.closed = true;
  }

  onRemove(taskId: string) {
    this.sharingProgressService.progressComplete(taskId);
  }

  private checkProgressStatus() {
    this.ws
      .onConnected()
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        this.sharingProgressService
          .checkTasksProgress()
          .pipe(take(1))
          .subscribe();
      });
  }
}
