import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { PERMISSIONS } from '@core/constants/permissions.constants';
import { map, switchMap, take } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class HomePageGuard implements CanActivate, CanActivateChild {
  constructor(
    private permissionsService: NgxPermissionsService,
    private router: Router,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const hasPermission = fromPromise<boolean>(
      this.permissionsService.hasPermission(
        PERMISSIONS.SYSTEM_ADMINISTRATION_VIEW,
      ),
    );
    return hasPermission.pipe(
      take<boolean>(1),
      map((hasPerm) => {
        if (state.url === '/dashboard') {
          if (hasPerm) {
            this.router.navigate(['admin/dashboard']);
            return false;
          }
        } else if (state.url === '/admin/dashboard') {
          if (!hasPerm) {
            this.router.navigate(['dashboard']);
            return false;
          }
        }
        return true;
      }),
    ) as Observable<boolean>;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(childRoute, state);
  }
}
