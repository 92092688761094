export const environment = {
  production: false,
  API_DOMAIN: process.env.NG_APP_API_URL || 'https://api-dev.fyiopro.co.uk',
  WS_URL:
    process.env.NG_APP_WS_URL ||
    'wss://ws-dev.fyiopro.co.uk/connection/websocket',
  LOG_LEVEL: 1,
  firebase: {
    apiKey: 'AIzaSyDBn7TQFVLlhfjBSf2-jBzyV675_wS0KBY',
    authDomain: 'fyio-pro-dev.firebaseapp.com',
    projectId: 'fyio-pro-dev',
    storageBucket: 'fyio-pro-dev.appspot.com',
    messagingSenderId: '1084043000863',
    appId: '1:1084043000863:web:45cb849c64622d1531a92a',
  },
};
