import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_ISO_DATE_FORMAT,
  DEFAULT_ISO_DATETIME_FORMAT,
} from '@core/constants';
import { Nullable } from '@core/interfaces/nullable';

@Injectable()
export class DateTimeService {
  constructor() {}

  format(
    date: Date | string | number | null,
    format: string = DEFAULT_DATE_FORMAT,
  ): string {
    if (!date) {
      return '';
    }
    return dayjs(date).format(format);
  }

  parse(
    raw: number | string | null,
    format: string = DEFAULT_ISO_DATE_FORMAT,
    tz?: string,
  ): Date | null {
    if (!raw) {
      return null;
    }
    if (!tz) {
      tz = this.getCurrentTimeZoneName();
    }
    return dayjs.tz(raw, format, tz).toDate();
  }

  toDate(dateStr: string, format: string = DEFAULT_ISO_DATE_FORMAT): Date {
    return dayjs(dateStr, format).toDate();
  }

  convertTz(
    date: Date | string,
    tzFrom: string,
    tzTo: string,
    format: string = DEFAULT_ISO_DATETIME_FORMAT,
  ): string {
    const dateStr = this.format(date, DEFAULT_ISO_DATETIME_FORMAT);
    const d = dayjs.tz(dateStr, tzFrom).tz(tzTo);
    return d.format(format);
  }

  getCurrentTimeZoneOffset(): string {
    return dayjs().format('Z');
  }

  getCurrentTimeZoneName(): string {
    return dayjs.tz.guess();
  }

  diff(
    from: Nullable<Date | string>,
    to: Nullable<Date | string>,
    unit: 'hour' | 'day' | 'minute' | 'week' | 'month' | 'quarter' = 'day',
  ): number {
    const fromDate = dayjs(from);
    const toDate = dayjs(to);
    return toDate.diff(fromDate, unit);
  }

  add(
    date: Date,
    n: number,
    unit: 'hour' | 'day' | 'minute' | 'week' | 'month' = 'day',
  ): Date {
    return dayjs(date).clone().add(n, unit).toDate();
  }
}
