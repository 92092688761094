import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from '@common/auth/services/authentication.service';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { delay, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [MessageService],
})
export class ForgotPasswordComponent {
  processing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthenticationService,
    private logger: NGXLogger,
    private router: Router,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
  ) {}

  onRestore(event: any): void {
    this.processing$.next(true);
    this.cdr.detectChanges();
    this.authService
      .sendRestorePassword(event.email)
      .pipe(
        take(1),
        tap(() => {
          this.messageService.addAll([
            {
              severity: 'success',
              detail: 'Check your email for further instructions',
            },
          ]);
          this.processing$.next(false);
        }),
        delay(5000),
      )
      .subscribe({
        next: () => {
          this.router.navigate([this.authService.getInterruptedUrl()]);
          this.processing$.next(false);
        },
        error: (e) => {
          const msg = e.error?.message || 'Invalid email';
          this.processing$.next(false);
          this.messageService.addAll([
            {
              severity: 'error',
              detail: msg,
            },
          ]);
          this.logger.error('ForgotPasswordComponent.onRestore error', e);
        },
      });
  }
}
