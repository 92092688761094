import { Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TreeLike } from '@common/shared/types/data-node';
import { Nullable } from '@core/interfaces/nullable';

const isExpanded = (
  node: TreeLike,
  addedCategoryId: Nullable<string>,
): boolean => {
  if (addedCategoryId === null) {
    return false;
  }
  if (node.id === addedCategoryId) {
    return true;
  }
  if (node.children) {
    return node.children.some((ch) => isExpanded(ch, addedCategoryId));
  }
  return false;
};

const convertToTreeNode = (
  item: TreeLike,
  addedCategoryId: Nullable<string>,
  parent?: TreeNode,
): TreeNode => {
  const image =
    item['logoUrl'] || item['avatarUrl'] || item['avatar'] || item['logo'];
  return {
    key: item['id'],
    data: item,
    expanded: isExpanded(item, addedCategoryId),
    label: item['label'] || item['title'] || item['name'],
    parent: parent,
    leaf: !item.children,
    icon: !image ? 'pi pi-folder' : undefined,
    children: item.children
      ? item.children.map((ch) => convertToTreeNode(ch, addedCategoryId, item))
      : undefined,
  };
};

@Pipe({
  name: 'treeNodes',
})
export class TreeNodesPipe implements PipeTransform {
  transform(value: TreeLike[], addedCategoryId: Nullable<string>): TreeNode[] {
    if (!value) {
      return [];
    }
    return value.map((v) => convertToTreeNode(v, addedCategoryId));
  }
}
