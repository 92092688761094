import { Component, forwardRef } from '@angular/core';
import { AddDocumentRequestComponent } from '@ui/forms/company/components/add-document-request/add-document-request.component';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ContactGroup } from '@core/models';

@Component({
  selector: 'app-share-document-form',
  templateUrl: './share-document-form.component.html',
  styleUrls: ['./share-document-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ShareDocumentFormComponent),
      multi: true,
    },
  ],
})
export class ShareDocumentFormComponent
  extends AddDocumentRequestComponent
  implements ControlValueAccessor
{
  override initForm() {
    this.form = this.fb.group({
      category: this.fb.control('', [Validators.required]),
      message: this.fb.control(''),
      contacts: this.fb.control([], [Validators.required]),
      contactFirstName: this.fb.control(''),
      contactLastName: this.fb.control(''),
      lifetime: this.fb.control('1', [Validators.required]),
      lifetimeCustomPeriod: this.fb.control(new Date()),
    });
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (
        value.lifetimeCustomPeriod &&
        value.lifetimeCustomPeriod instanceof Date &&
        this.periodRangePicker?.overlayVisible
      ) {
        this.periodRangePicker.hideOverlay();
      }
    });
  }
}
