import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { NgxsModule } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { DashboardState } from '@store/dashboard/dashboard.state';
import { DashboardStoreService } from '@store/dashboard/dashboard-store.service';

@NgModule({
  imports: [CoreModule, CommonModule, NgxsModule.forFeature([DashboardState])],
  exports: [],
  providers: [DashboardStoreService, DashboardState],
})
export class DashboardStoreModule {}
