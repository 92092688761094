import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CreateContact } from '@core/models/create-contact.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Contact } from '@core/models/contact.model';
import { take, takeUntil } from 'rxjs/operators';
import { ContactsStoreService } from '@store/contacts/contacts-store.service';
import { MessageService } from 'primeng/api';
import { Form } from '@ui/forms/form.service';
import { ContactGroup } from '@core/models';
import { UpdateContact } from '@store/contacts/contacts.actions';
import { Nullable } from '@core/interfaces/nullable';
import { AddContactFormComponent } from '@ui/forms/company/components/add-contact-form/add-contact-form.component';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddClientComponent implements OnInit, OnDestroy {
  @ViewChild('contactForm', { read: AddContactFormComponent })
  contactFormCmp!: AddContactFormComponent;
  @Input() show!: boolean;

  @Input() set contact(contact: Contact) {
    if (contact) {
      this.contactModel = {
        ...contact,
        groups: contact?.groups?.map((g) => g.id),
      } as CreateContact;
      if (contact.id && contact.email) {
        this.contactFormCmp.onSearch(contact.email);
      }
    } else {
      this.contactModel = null;
    }
  }

  @Input() groups!: ContactGroup[];

  @Output() closed = new EventEmitter<void>();
  @Output() completed = new EventEmitter<void>();

  private destroy$ = new Subject<void>();
  processing$ = new BehaviorSubject<boolean>(false);
  formValid!: boolean;
  contactForm!: Form;
  contactModel!: Nullable<CreateContact & { id?: string }>;

  constructor(
    private cdr: ChangeDetectorRef,
    private contactsService: ContactsStoreService,
    private messageService: MessageService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.closed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.contactForm.reset();
    });

    this.contactsService
      .error()
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => {
        this.messageService.clear();
        this.messageService.add({
          severity: 'error',
          detail: error.error?.message || 'Something goes wrong',
        });
        this.processing$.next(false);
      });
  }

  onFormInit(form: Form): void {
    this.contactForm = form;
  }

  onSubmit(): void {
    this.contactForm.validate();
    this.cdr.detectChanges();
    if (this.formValid) {
      this.processing$.next(true);
      const payload = {
        firstName: this.contactModel?.firstName,
        lastName: this.contactModel?.lastName,
        email: this.contactModel?.email,
        avatar: this.contactModel?.avatar,
        groups: this.contactModel?.groups,
      } as CreateContact;
      const next$: Observable<Contact> = this.contactModel?.id
        ? this.contactsService.updateContact(this.contactModel.id, payload)
        : this.contactsService.createContact(payload);

      next$.pipe(take(1), takeUntil(this.destroy$)).subscribe((contact) => {
        this.processing$.next(false);
        this.completed.emit();
        this.messageService.add({
          severity: 'success',
          detail:
            "Contact '" +
            contact.name +
            "' has been successfully " +
            (this.contactModel ? 'updated' : 'created'),
        });
        this.contactFormCmp.clear();
        this.closed.emit();
      });
    }
  }
}
