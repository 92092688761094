import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray',
})
export class FilterArrayPipe implements PipeTransform {
  transform(arr: any[], field: string, value?: any): unknown {
    if (!arr) {
      return [];
    }
    if (arr.length === 0) {
      return arr;
    }
    if (typeof arr[0] === 'object') {
      return arr.filter((item) =>
        typeof value !== 'undefined' ? item[field] === value : !!item[field],
      );
    } else {
      return arr.filter((item) =>
        typeof value !== 'undefined' ? item === value : !!item,
      );
    }
  }
}
