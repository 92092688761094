import { DataNode, TreeNode } from '@common/shared/types/data-node';
import { CreateDocumentRequest } from '@core/models/create-document-request.model';
import {
  CreateDocument,
  CreateDocumentShare,
  Document,
  DocumentShareRequestFormModel,
  SharedDocument,
  UploadProgress,
} from '@core/models';
import { Nullable } from '@core/interfaces/nullable';

export class GetDocumentsByContact {
  static readonly type = '[DOCUMENTS] Get Documents By Contact';

  constructor(
    public companyId: string,
    public contactId: string,
    public page: number,
    public pageSize: number,
    public where?: Nullable<Record<string, any>>,
  ) {}
}

export class GetDocumentsStatsByContact {
  static readonly type = '[DOCUMENTS] Get Documents Stats By Contact';
  constructor(public companyId: string) {}
}

export class GetDocumentsSuccess {
  static readonly type = '[DOCUMENTS] Get Documents Success';

  constructor(public originalAction: any) {}
}

export class GetDocumentsByCategory {
  static readonly type = '[DOCUMENTS] Get Documents By Category';

  constructor(
    public payload: {
      companyId: string;
      categoryId: Nullable<string>;
      page: number;
      pageSize: number;
      where?: Nullable<Record<string, string>>;
    },
  ) {}
}

export class GetPlainDocuments {
  static readonly type = '[DOCUMENTS] Get Plain Documents';

  constructor(
    public payload: {
      companyId: string;
      page: number;
      pageSize: number;
      sortBy?: string;
      order?: string;
      where?: Nullable<Record<string, string>>;
    },
  ) {}
}

export class GetDocumentsCountersByCategory {
  static readonly type = '[DOCUMENTS] Get Counters By Category';

  constructor(
    public payload: {
      companyId: string;
      where?: Nullable<Record<string, string>>;
    },
  ) {}
}

export class SetActiveDocumentCategoryAction {
  static readonly type = '[DOCUMENTS] Set Active Category';

  constructor(public categoryId: string) {}
}

export class CategoriesLoaded {
  static readonly type = '[DOCUMENTS] Documents Loaded';

  constructor(public payload: Array<TreeNode<DataNode>>) {}
}

export class UploadDocument {
  static readonly type = '[DOCUMENTS] Upload Document';

  constructor(public companyId: string, public payload: CreateDocument) {}
}

export class BulkUploadDocuments {
  static readonly type = '[DOCUMENTS] Bulk Upload Documents';

  constructor(public companyId: string, public payload: CreateDocument[]) {}
}

export class BulkUploadProgress {
  static readonly type = '[DOCUMENTS] Bulk Upload Documents Progress';

  constructor(public companyId: string, public progress: UploadProgress) {}
}

export class DocumentCreated {
  static readonly type = '[DOCUMENTS] Document Created';

  constructor(
    public companyId: string,
    public payload: Document,
    public index: number,
    public total: number,
  ) {}
}

export class BulkDocumentsCompleted {
  static readonly type = '[DOCUMENTS] Bulk Documents Completed';

  constructor(public companyId: string, public payload: any) {}
}

export class DeleteDocument {
  static readonly type = '[DOCUMENTS] Delete Document';

  constructor(
    public id: string,
    public categoryId: string,
    public companyId: string,
  ) {}
}

export class ClearDocumentsErrors {
  static readonly type = '[DOCUMENTS] Clear Document Errors';

  constructor() {}
}
