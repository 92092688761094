import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from './auth-layout.component';
import { UiModule } from '@ui/ui.module';

@NgModule({
  declarations: [AuthLayoutComponent],
  exports: [AuthLayoutComponent],
  imports: [CommonModule, UiModule],
})
export class AuthLayoutModule {}
