import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { NgxsModule } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { ContactGroupsState } from '@store/contact-groups/contact-groups.state';
import { ContactGroupsStoreService } from '@store/contact-groups/contact-groups-store.service';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    NgxsModule.forFeature([ContactGroupsState]),
  ],
  exports: [],
  providers: [ContactGroupsStoreService, ContactGroupsState],
})
export class ContactGroupsStoreModule {}
