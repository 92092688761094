import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '@app/app-routing.module';
import { PagesModule } from '@app/pages/pages.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedStoreModule } from '@store/shared-store.module';
import { NgxsModule } from '@ngxs/store';
import { environment } from '@env/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AppCommonModule } from '@common/app-common.module';
import { WsNotificationsModule } from '@common/ws-notifications/ws-notifications.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PagesModule,
    NgxPermissionsModule.forRoot(),
    ReactiveFormsModule,
    SharedStoreModule,
    AppCommonModule,
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['auth', 'companies', 'sharing_progress'],
    }),
    WsNotificationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
