import { NgModule } from '@angular/core';
import { FormHelper } from '@common/shared/helpers/form-helper';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HomePageGuard } from '@common/shared/guards/home-page.guard';
import { UnsavedChangesGuard } from '@common/shared/guards/unsaved-changes.guard';
import {
  BreadcrumbsService,
  DateTimeService,
  MenuService,
} from '@common/shared/services';
import { ForwarderGuard } from '@common/shared/guards/forwarder.guard';
import { ActiveCompanyGuard } from '@common/shared/guards/active-company.guard';
import { FormApplyService } from '@common/shared/services/form-apply.service';
import { TreeNodesPipe } from './pipeps/tree-nodes.pipe';
import { TreePathPipe } from './pipeps/tree-path.pipe';
import { SearchFilterPipe } from './pipeps/search-filter.pipe';
import { FilterArrayPipe } from './pipeps/filter-array.pipe';
import { DefaultImageDirective } from './directives/default-image.directive';

@NgModule({
  declarations: [
    TreeNodesPipe,
    TreePathPipe,
    SearchFilterPipe,
    FilterArrayPipe,
    DefaultImageDirective,
  ],
  providers: [
    FormHelper,
    HomePageGuard,
    ForwarderGuard,
    UnsavedChangesGuard,
    MenuService,
    BreadcrumbsService,
    DateTimeService,
    ActiveCompanyGuard,
    FormApplyService,
    TreeNodesPipe,
    TreePathPipe,
  ],
  imports: [],
  exports: [
    NgxPermissionsModule,
    TreeNodesPipe,
    TreePathPipe,
    SearchFilterPipe,
    FilterArrayPipe,
    DefaultImageDirective,
  ],
})
export class SharedCommonModule {}
