import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  CellActionsOption,
  TableRowData,
} from '@ui/components/data-table/interfaces';
import { BaseCellRendererComponent } from '@ui/components/data-table/components/renderers/base-cell-renderer.component';

@Component({
  selector: 'app-editable-cell-renderer',
  templateUrl: './editable-cell-renderer.component.html',
  styleUrls: ['./editable-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<string, R>
  implements OnInit
{
  action!: CellActionsOption<R>;
  icon!: string;

  ngOnInit(): void {
    if (this.getOptions()['action']) {
      this.action = this.getOptions()['action'] as CellActionsOption<R>;
    }
    if (this.getOptions()['icon']) {
      this.icon = this.getOptions()['icon'] as string;
    }
  }

  override render(): string {
    return this.getRawValue() || '';
  }

  onAction(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();
    if (this.action && typeof this.action.action === 'function') {
      this.action.action.call(this, this.getRow());
    }
  }
}
