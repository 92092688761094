import {
  Component,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { FormControlName, Validators } from '@angular/forms';
import { FormHelper } from '@common/shared/helpers/form-helper';
import { FormFieldDirective } from '@ui/forms/elements/form-fields-wrapper/form-field.directive';
import { Nullable } from '@core/interfaces/nullable';

@Component({
  selector: 'app-form-fields-wrapper',
  templateUrl: './form-fields-wrapper.component.html',
  styleUrls: ['./form-fields-wrapper.component.scss'],
})
export class FormFieldsWrapperComponent {
  @ContentChildren(FormFieldDirective, { read: FormFieldDirective })
  elements!: QueryList<FormFieldDirective>;

  @ContentChildren(FormControlName, { read: FormControlName })
  controls!: QueryList<FormControlName>;

  @Input() showLabels!: boolean;

  constructor(private formHelper: FormHelper) {}

  errorMessage(name: string): string {
    const control: Nullable<FormControlName> = this.control(name);
    if (control) {
      const name: Nullable<string | number> = control.name;
      return `<ul class="ui-errors">${this.formHelper
        .getErrors(name as string, control.control)
        .map((line) => `<li>${line}</li>`)
        .join()}</ul>`;
    } else {
      return '';
    }
  }

  hasErrors(name: string): boolean {
    const control: Nullable<FormControlName> = this.controls.find(
      (c) => c.name === name,
    );
    if (!control) {
      return false;
    }
    return this.formHelper.hasError(control.control);
  }

  hasRequiredValidator(name: string): boolean {
    const control: Nullable<FormControlName> = this.control(name);
    return !!control?.control?.hasValidator(Validators.required);
  }

  control(name: string): Nullable<FormControlName> {
    return this.controls.find((c) => c.name === name);
  }
}
