import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Nullable } from '@core/interfaces/nullable';
import { Document, SharedDocument } from '@core/models';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from '@core/constants';
import {
  SHARED_BY_DOCUMENT_DATA_SOURCE,
  SharedByDocumentDataSource,
} from '@pages/protected/documents/services/shared-by-document-data-source';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentSource } from '@core/enums/document-source.enum';

@UntilDestroy()
@Component({
  selector: 'app-document-share-details',
  templateUrl: './document-share-details.component.html',
  styleUrls: ['./document-share-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentShareDetailsComponent implements OnInit {
  @Input() canBeShared!: boolean;

  @Input() set document(doc: Partial<Document>) {
    if (doc) {
      this.documentValue = doc;
      this.sharedDataSource.setDocumentId(doc.id as string);
      this.sharedDataSource
        .load(
          1,
          30,
          {
            'doc.id': doc.id as string,
          },
          'sharedAt',
          'desc',
        )
        .pipe(take(1), untilDestroyed(this))
        .subscribe();
    }
  }
  get document(): Partial<Document> {
    return this.documentValue;
  }

  @Output() share = new EventEmitter<void>();

  get sharedDocument(): Nullable<SharedDocument> {
    return this.shareValue;
  }

  private shareValue!: Nullable<SharedDocument>;
  documentValue!: Partial<Document>;
  dateFormat = DEFAULT_DATE_FORMAT;

  constructor(
    @Inject(SHARED_BY_DOCUMENT_DATA_SOURCE)
    private sharedDataSource: SharedByDocumentDataSource,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.sharedDataSource
      .onLoad()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (this.document?.source === DocumentSource.Clients) {
          this.shareValue = data.items?.length > 0 ? data.items[0] : null;
          this.cdr.markForCheck();
        }
      });
  }
}
