import { AbstractControl } from '@angular/forms';

export function requiredValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `${name} is a required field`;
}

export function emailValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `${name} is incorrect`;
}

export function incorrectValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `${name} is incorrect`;
}

export function hasNumberValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `${name} must contain at least one number`;
}

export function hasCapitalCaseValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `${name} must contain at least one character in upper case`;
}

export function hasSmallCaseValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `${name} must contain at least one character in lower case`;
}

export function hasSpecialCharacterValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `${name} must contain at least one special character`;
}

export function passwordMatchValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `Passwords must match`;
}

export function minlengthValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
  minLength: number,
): string {
  return `${name} must contain at least ${err.requiredLength} characters`;
}

export function lessThanDateValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `${name} contains invalid value`;
}

export function greaterThanDateValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `${name} contains invalid value`;
}

export function contactNameValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `${name} contains invalid value, it must be presented in the format 'Firstname Lastname &ltemail&gt'`;
}

export function maxlengthValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `${name} must contain less than ${err.requiredLength} characters`;
}

export function whitespaceValidationMessage(
  err: any,
  name: string,
  field: AbstractControl,
): string {
  return `${name} must not contain whitespace`;
}
