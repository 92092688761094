import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, withLatestFrom } from 'rxjs';
import {
  SharingProgress,
  SharingProgressState,
} from '@store/sharing-progress/sharing-progress.state';
import { Nullable } from '@core/interfaces/nullable';
import {
  CheckSharingProgressAction,
  SaveSharingProgressAction,
  SaveSharingProgressCompleteAction,
} from '@store/sharing-progress/sharing-progress.actions';
import { take, switchMap, tap, filter } from 'rxjs/operators';
import { CompaniesState } from '@store/companies/companies.state';

@Injectable()
export class SharingProgressStoreService {
  constructor(private store: Store) {}

  selectActiveTaskProgress(): Observable<Nullable<SharingProgress[]>> {
    return this.store.select(SharingProgressState.getSharingProgress);
  }

  saveProgress(taskId: string, payload: SharingProgress) {
    this.store.dispatch(new SaveSharingProgressAction(taskId, payload));
  }

  progressComplete(taskId: string) {
    this.store.dispatch(new SaveSharingProgressCompleteAction(taskId));
  }

  checkTasksProgress(): Observable<any> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      take(1),
      withLatestFrom(this.selectActiveTaskProgress()),
      tap(([company, progress]) => {
        if (company && progress && progress.length > 0) {
          this.store.dispatch(
            new CheckSharingProgressAction(
              company.id,
              progress.map((p) => p.taskId),
            ),
          );
        }
      }),
    );
  }
}
