import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AuthStoreService } from '@store/auth/auth-store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { delay, filter, take, tap } from 'rxjs/operators';
import { Nullable } from '@core/interfaces/nullable';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
  providers: [MessageService],
})
export class ConfirmEmailComponent implements OnInit, AfterViewInit {
  private viewReady$ = new ReplaySubject<boolean>(1);
  constructor(
    private route: ActivatedRoute,
    private authStoreService: AuthStoreService,
    private router: Router,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.viewReady$.next(true);
  }

  ngOnInit(): void {
    const token: Nullable<string> = this.route.snapshot.paramMap.get('token');
    if (!token) {
      this.router.navigate(['/auth/login']);
      return;
    }
    this.authStoreService
      .emailConfirmationStatus()
      .pipe(
        filter((s) => s !== 'pending'),
        take(1),
      )
      .subscribe((res) => {
        const msg =
          res === 'confirmed'
            ? 'Email has been successfully confirmed. Use your credentials to proceed'
            : 'Invalid link is provided';
        this.viewReady$
          .pipe(
            take(1),
            tap(() => {
              this.messageService.addAll([
                {
                  detail: msg,
                  severity: res === 'confirmed' ? 'success' : 'error',
                  life: 3000,
                },
              ]);
              this.cdr.detectChanges();
            }),
            delay(3000),
          )
          .subscribe(() => {
            this.router.navigate(['/auth/login']);
          });
      });

    this.authStoreService.confirmEmail(token);
  }
}
