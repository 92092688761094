<div
  *ngIf="file"
  class="file-container"
  [class.outline]="outline"
  [class.inline]="inline"
  [class.disabled]="disabled"
  [class.error]="progress?.error"
>
  <div class="file-container__main-info">
    <div class="file-container__avatar">
      <div
        *ngIf="!isImage || !src"
        class="mr-2 file-type-icon type-{{ fileType }}"
      ></div>
      <div *ngIf="isImage && src" class="mr-2 avatar">
        <img
          *ngIf="src"
          [src]="src"
          [attr.data-src]="src"
          appDefaultImage
          [defaultImage]="defaultImage"
          size="small"
          shape="square"
        />
      </div>
    </div>
    <div class="file-container__name-wrapper">
      <div class="file-container__name">
        <span
          [pTooltip]="file.name"
          [tooltipOptions]="{ tooltipPosition: 'top' }"
          >{{ file.name }}</span
        >
      </div>
      <div *ngIf="!progress" class="file-container__size">
        {{ file.size | filesize }}
      </div>
      <div *ngIf="progress" class="file-container__progress">
        <ng-container *ngIf="!progress.error">
          <i *ngIf="!progress.completed" class="pi pi-spin pi-spinner"></i>
          <i *ngIf="progress.completed" class="pi pi-check-circle"></i>
          {{ progress.loaded | progress : progress.total || file?.size }}%
          uploaded
        </ng-container>
        <div *ngIf="progress.error" class="error">
          <i class="pi pi-exclamation-circle"></i>
          <span>{{ progress.error }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="closable" class="file-container__close">
      <button
        (click)="closed.emit()"
        [disabled]="disabled"
        pButton
        class="p-button-text p-button-icon p-button-sm"
        icon="pi pi-times"
      ></button>
    </div>
  </div>
  <div *ngIf="editable" class="file-container__splitter">
    <div class="border"></div>
  </div>
  <div *ngIf="editable">
    <div class="file-container__edit-wrapper">
      <div>Document name</div>
      <input
        pInputText
        [class.disabled]="disabled"
        [readonly]="disabled"
        type="text"
        [ngModel]="fileName"
        (ngModelChange)="onEdit($event)"
      />
    </div>
  </div>
</div>
