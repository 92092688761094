import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Nullable } from '@core/interfaces/nullable';
import { takeUntil } from 'rxjs/operators';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { FormHelper } from '@common/shared/helpers/form-helper';
import { Form } from '@ui/forms/form.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'app-base-form',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseFormDirective),
      multi: true,
    },
  ],
})
export abstract class BaseFormDirective<T extends object>
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() disabled!: boolean;
  @Input() disabledFields!: string[];

  @Input() set model(model: Nullable<T>) {
    this.modelValue = model;
    if (!this.form) {
      this.initForm(this.modelValue);
      this.formSet$.next(this.form);
    } else if (model) {
      this.form.patchValue({
        ...model,
      });
    } else {
      this.form.reset();
    }
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.modelChange.emit(value);
      this.validate.emit(this.form.valid);
      this.cdr.detectChanges();
    });
  }

  get model(): Nullable<T> {
    return this.modelValue;
  }

  @Output() modelChange: EventEmitter<Nullable<T>> = new EventEmitter();
  @Output() formInitialized = new EventEmitter<Form>();
  @Output() validate = new EventEmitter<boolean>();

  form!: FormGroup;

  private onChange = (value: any) => {};
  private onTouch = () => {};

  protected formService!: Form;
  protected destroy$ = new Subject<void>();
  protected formSet$ = new ReplaySubject<FormGroup>(1);
  private modelValue!: Nullable<T>;

  constructor(
    protected fb: FormBuilder,
    protected logger: NGXLogger,
    protected cdr: ChangeDetectorRef,
    protected formHelper: FormHelper,
  ) {}

  ngOnInit(): void {
    this.modelChange
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.onChange(value);
        this.onTouch();
        this.cdr.markForCheck();
      });

    this.formSet$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.formService = new Form(this.form, this.formHelper);
      this.formInitialized.emit(this.getFormService());
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isDisabled(field: string): boolean {
    return !!(
      this.disabled || this.disabledFields?.find((name) => field === name)
    );
  }

  getFormService(): Form {
    return this.formService;
  }

  abstract initForm(model?: Nullable<T>): void;

  // ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.model = obj;
  }
}
