import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { NgxsModule } from '@ngxs/store';
import { RequestsState } from '@store/requests/requests.state';
import { RequestsStoreService } from '@store/requests/requests-store.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, CoreModule, NgxsModule.forFeature([RequestsState])],
  providers: [RequestsState, RequestsStoreService],
})
export class RequestsStoreModule {}
