<div class="surface-card p-4 shadow-2 border-round w-full">
  <div class="text-center mb-5">
    <img src="/assets/images/logo.png" alt="Image" height="50" class="mb-3" />
    <div class="text-900 text-4xl font-bold mb-3">{{ title }}</div>
    <div class="mb-3">Enter your email to proceed</div>
  </div>
  <div>
    <form class="auth-form-content" [formGroup]="form" (ngSubmit)="onSubmit()">
      <app-form-fields-wrapper>
        <input
          *appFormField
          formControlName="email"
          type="text"
          placeholder="Email address"
          pInputText
          class="w-full mb-1"
        />
      </app-form-fields-wrapper>
      <div class="auth-form-button p-mb-4 p-mt-4">
        <p-button
          type="submit"
          pRipple
          iconPos="left"
          styleClass="ui-button"
          [icon]="processing ? 'pi pi-spin pi-spinner' : ''"
          [label]="buttonText | uppercase"
        ></p-button>
      </div>
    </form>
    <div class="mt-6 mb-3 flex align-items-center justify-content-center">
      Wait! I remembered my password
      <button pButton (click)="navigateToLogin()" class="p-button-link ml-3">
        Sign in
      </button>
    </div>
  </div>
</div>
