import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { PrimengUiModule } from '@ui/primeng/primeng-ui.module';
import { SharedCommonModule } from '@common/shared/shared-common.module';
import { FormElementsUiModule } from '@ui/forms/elements/form-elements-ui.module';
import { ProfileDetailsFormComponent } from '@ui/forms/profile/components/profile-details-form/profile-details-form.component';
import { EmailFormComponent } from './components/email-form/email-form.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    PrimengUiModule,
    SharedCommonModule,
    FormElementsUiModule,
  ],
  declarations: [
    ProfileDetailsFormComponent,
    EmailFormComponent,
    ChangePasswordFormComponent,
  ],
  exports: [
    ProfileDetailsFormComponent,
    EmailFormComponent,
    ChangePasswordFormComponent,
  ],
})
export class ProfileFormsModule {}
