<p-card
  *ngIf="document"
  class="document-info flex flex-column align-items-start"
>
  <h5><span *ngIf="document">Document</span> Details</h5>

  <div *ngIf="document" class="grid">
    <div class="col-12 flex flex-column">
      <div class="flex align-items-start mb-2 full-width">
        <app-file-info
          [closable]="false"
          [file]="document.file"
        ></app-file-info>
      </div>
    </div>
  </div>
  <div class="grid">
    <div
      *ngIf="document?.owner && document?.verification"
      class="col-6 flex flex-column"
    >
      <div class="flex align-items-start mb-2">
        <div class="pi pi-user"></div>
        <div class="ml-2 flex flex-column">
          <span class="text-500 font-normal mb-1">Uploaded By:</span>
          <span>
            <app-contact [contact]="document.owner"> </app-contact>
          </span>
        </div>
      </div>
      <div class="flex align-items-start mb-2">
        <div class="pi pi-id-card"></div>
        <div class="ml-2 flex flex-column">
          <span class="text-500 font-normal mb-1">Verification:</span>
          <span class="font-medium">{{
            document.verification | humanize
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-6 flex flex-column">
      <div class="flex align-items-start mb-2">
        <div class="pi pi-clock"></div>
        <div class="ml-2 flex flex-column">
          <span class="text-500 font-normal mb-1">Uploaded At</span>
          <span class="font-medium">{{
            document?.createdAt | dateFormat : dateFormat
          }}</span>
        </div>
      </div>
      <div class="flex align-items-start mb-2"></div>
    </div>
  </div>
  <div *ngIf="documentValue.note" class="grid">
    <div class="col-12 flex flex-column">
      <div class="flex align-items-start mb-2">
        <div class="pi pi-comments"></div>
        <div class="ml-2 flex flex-column">
          <span class="text-500 font-normal mb-1">Document Comment:</span>
          <span class="font-medium comment-container">{{
            documentValue.note
          }}</span>
        </div>
      </div>
    </div>
  </div>
</p-card>
