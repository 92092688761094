import { Injectable } from '@angular/core';
import { FilesUploadService } from '@core/services/api';
import { Observable } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import { UploadedFile } from '@core/models/uploaded-file.model';

@Injectable({
  providedIn: 'root',
})
export class PublicAssetsUploadService {
  constructor(private filesUploadService: FilesUploadService) {}

  upload(file: File): Observable<UploadedFile> {
    return this.filesUploadService.uploadPublicFiles([file]).pipe(
      filter((event) => event.type === HttpEventType.Response),
      map((event: { type: HttpEventType; response: any }) => {
        return event?.response && event.response[0];
      }),
    );
  }
}
