import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Contact } from '@core/models/contact.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactComponent {
  @Input() contact!: Contact;

  get name(): string {
    if (this.contact.firstName && this.contact.lastName) {
      return `${this.contact.firstName} ${this.contact.lastName}`;
    }

    if (this.contact?.name) {
      return this.contact.name;
    }
    return '';
  }
}
