import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UiFormsModule } from '@ui/forms';
import { PrimengUiModule } from '@ui/primeng/primeng-ui.module';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiFormsModule,
    PrimengUiModule,
    NgxPermissionsModule.forChild(),
  ],
  exports: [],
})
export class DialogsModule {}
