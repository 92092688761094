import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { LogoutComponent } from './logout/logout.component';
import { CoreModule } from '@app/core/core.module';
import { UiModule } from '@ui/ui.module';
import { FormsModule } from '@angular/forms';
import { AuthLayoutModule } from '@ui/layouts/auth-layout/auth-layout.module';
import { SharedCommonModule } from '@common/shared/shared-common.module';
import { RegisterComponent } from './register/register.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { RestorePasswordComponent } from './restore-password/restore-password.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    AuthComponent,
    LogoutComponent,
    RegisterComponent,
    ConfirmEmailComponent,
    RestorePasswordComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    AuthRoutingModule,
    AuthLayoutModule,
    SharedCommonModule,
    UiModule,
    FormsModule,
  ],
})
export class AuthModule {}
