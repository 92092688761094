import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalLetters',
})
export class CapitalLettersPipe implements PipeTransform {
  transform(value: string, limit = 2): string {
    if (typeof value !== 'string') {
      return value;
    }
    return value
      .split(' ')
      .filter((v) => v.trim().length > 0)
      .slice(0, limit)
      .map((part) => part.substring(0, 1))
      .join('');
  }
}
