export class GetUsers {
  public static readonly type = '[Admin Users] Get App Users';

  constructor() {}
}

export class GetUserDetails {
  public static readonly type = '[Admin Users] Get App User Details';

  constructor(public id: number) {}
}

export class ClearUserError {
  public static readonly type = '[Admin Users] Clear last error';

  constructor() {}
}

export class UpdateUser {
  public static readonly type = '[Admin Users] Update App User';

  constructor(public id: number, public payload: Partial<any>) {}
}

export class UpdateUserStatus {
  public static readonly type = '[Admin Users] Update App User Status';

  constructor(public id: number, public payload: boolean) {}
}
