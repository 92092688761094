import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Contact } from '@core/models/contact.model';
import { ContactGroup } from '@core/models';
import { SelectionModel } from '@angular/cdk/collections';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { ContactsStoreService } from '@store/contacts/contacts-store.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-contacts-dropdown',
  templateUrl: './contacts-dropdown.component.html',
  styleUrls: ['./contacts-dropdown.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ContactsDropdownComponent),
    },
  ],
})
export class ContactsDropdownComponent
  implements OnDestroy, ControlValueAccessor
{
  @Input() set clients(clients: Contact[]) {
    if (clients) {
      this._clients = clients
        ? clients.map((c) => ({ ...c, name: `${c.firstName} ${c.lastName}` }))
        : clients;
      if (this.searchTerm) {
        this.onSearch('client', this.searchTerm);
      } else {
        this.filteredClients = [...clients];
      }
      this.clientsReady$.next(this._clients);
    }
  }

  get clients(): Contact[] {
    return this.filteredClients;
  }

  @Input() set groups(groups: ContactGroup[]) {
    this._groups = groups;
    this.filteredGroups = [...groups];
    if (groups) {
      this.groupsReady$.next(groups);
    }
  }

  get groups(): ContactGroup[] {
    return this.filteredGroups;
  }

  @Input() set value(value: string[]) {
    this._value = value;
    if (value) {
      this.valueReady$.next(value);
    } else {
      this.activeTabIndex = 0;
      this.selectedClients.clear();
      this.selectedGroups.clear();
    }
  }

  get value(): string[] {
    return this._value;
  }

  searchTerm!: string;

  @Output() createNew = new EventEmitter<'contact' | 'group'>();

  isOpened!: boolean;
  isDisabled!: boolean;
  activeTabIndex = 0;
  selectedGroups = new SelectionModel<string>(true);
  selectedClients = new SelectionModel<string>(true);
  filteredClients!: Contact[];
  filteredGroups!: ContactGroup[];

  protected onChange: any;
  protected onTouch: any;
  protected _value!: string[];
  protected _clients!: Contact[];
  protected _groups!: ContactGroup[];
  protected clientsReady$ = new ReplaySubject<Contact[]>(1);
  protected valueReady$ = new ReplaySubject<string[]>(1);
  protected groupsReady$ = new ReplaySubject<ContactGroup[]>(1);
  protected destroy$ = new Subject<void>();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected contactsService: ContactsStoreService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get selectedCount(): number {
    return this.selectedClients.selected.length;
  }

  get selectedText(): string {
    if (!this.value?.length) {
      return 'Select contact of group of contacts';
    }
    return `Selected (${this.value?.length}) contacts`;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: string[]): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  onSearch(type: 'group' | 'client', term: string): void {
    this.searchTerm = term;
    if (term === undefined) {
      return;
    }
    if (type === 'group') {
      this.filteredGroups = this._groups.filter(
        (g) =>
          !term?.trim() ||
          g.name.toLowerCase().includes(term?.trim()?.toLowerCase()),
      );
    } else {
      this.filteredClients = this._clients.filter(
        (c) =>
          !term?.trim() ||
          c.name.toLowerCase().includes(term?.trim()?.toLowerCase()) ||
          c.email.toLowerCase().includes(term?.trim()?.toLowerCase()),
      );
    }
    this.cdr.markForCheck();
  }

  onClientSelected(id: string[]): void {
    this.selectedClients.toggle(id[0]);
    this.cdr.markForCheck();
  }

  onGroupSelected(event: string[]): void {
    const id = event[0];
    this.selectedGroups.toggle(id);
    this.contactsService
      .loadContactsByGroup(id)
      .pipe(untilDestroyed(this), take(1))
      .subscribe((clients) => {
        let group = this.groups.find((g) => g.id === id);
        if (group) {
          group = {
            ...group,
            clients,
          };
          this.groups = [
            ...this.groups.filter((g) => g.id !== group?.id),
            group,
          ].sort((a, b) => a.name.localeCompare(b.name));
        }
        if (this.selectedGroups.isSelected(id)) {
          this.selectedClients.select(...clients.map((c) => c.id));
        } else {
          clients.forEach((client) => {
            if (!this.isClientInAnyGroup(client.id)) {
              this.selectedClients.deselect(client.id);
            }
          });
        }
        this.cdr.markForCheck();
      });
  }

  onApplySelected(event: MouseEvent): void {
    if (this.selectedCount === 0) {
      this.createNew.emit(this.activeTabIndex === 0 ? 'contact' : 'group');
      return;
    }
    let value: string[] = [];
    if (this.activeTabIndex === 0) {
      value = [...this.selectedClients.selected];
      this.writeValue(value);
      this.onChange(value);
      this.onTouch();
    } else {
      const groupsIds = [...this.selectedGroups.selected];
      combineLatest(
        groupsIds.map((id) =>
          this.contactsService.selectContactsByGroup(id).pipe(take(1)),
        ),
      )
        .pipe(untilDestroyed(this), take(1))
        .subscribe((data) => {
          value = [
            ...new Set(
              data
                .map((clients) => clients.map((c) => c.id))
                .reduce<string[]>((acc, cur) => [...acc, ...cur], []),
            ),
          ];
          this.writeValue(value);
          this.onChange(value);
          this.onTouch();
        });
    }
  }

  trackByIndex(i: number, item: any): any {
    return i;
  }
  trackById(i: number, item: any): any {
    return item.id;
  }

  protected isClientInAnyGroup(clientId: string): boolean {
    // TODO: need to re think this logic using async lazy loading
    return this.groups
      .filter((g) => this.selectedGroups.selected.includes(g.id))
      .reduce(
        (acc, cur) => acc || cur.clients.some((c) => c.id === clientId),
        false,
      );
  }
}
