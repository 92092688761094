<p-dialog
  modal="true"
  position="right"
  [draggable]="false"
  [focusOnShow]="true"
  [closeOnEscape]="false"
  [header]="groupModel?.id ? 'Update Group' : 'Create Group'"
  [style]="{ width: '450px', height: '100vw' }"
  [closable]="true"
  [(visible)]="show"
  (onHide)="closed.emit()"
>
  <div class="w-full h-full grid">
    <app-add-contact-group-form
      #groupForm
      [clients]="clients"
      [(model)]="groupModel"
      (validate)="formValid = $event"
      (formInitialized)="onFormInit($event)"
    ></app-add-contact-group-form>
  </div>
  <ng-template pTemplate="footer">
    <button
      (click)="closed.emit()"
      pButton
      pRipple
      class="p-button-outlined"
      label="Cancel"
    ></button>
    <button
      [disabled]="processing$ | async"
      pButton
      pRipple
      class=""
      iconPos="left"
      [icon]="
        (processing$ | async)
          ? 'pi pi-spin pi-spinner'
          : groupModel?.id
          ? 'pi pi-save'
          : 'pi pi-plus'
      "
      [label]="groupModel?.id ? 'Update' : 'Create'"
      (click)="onSubmit()"
    ></button>
  </ng-template>
</p-dialog>
