import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { NgxsModule } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { SharingProgressState } from '@store/sharing-progress/sharing-progress.state';
import { SharingProgressStoreService } from '@store/sharing-progress/sharing-progress-store.service';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    NgxsModule.forFeature([SharingProgressState]),
  ],
  exports: [],
  providers: [SharingProgressStoreService, SharingProgressState],
})
export class SharingProgressStoreModule {}
