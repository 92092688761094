<div
  *ngIf="!closed && (progress$ | async)?.length > 0"
  class="sharing-progress"
  [class.sharing-progress--collapsed]="collapsed"
  [pTooltip]="
    (progress$ | async)?.length +
    ' sharing ' +
    ('task' | pluralize : (progress$ | async)?.length) +
    ' in progress'
  "
  [tooltipDisabled]="!collapsed"
>
  <div class="sharing-progress__wrapper">
    <i
      *ngIf="!collapsed"
      class="pi pi-chevron-up sharing-progress__collapse-icon"
      (click)="collapsed = true"
    ></i>
    <i
      *ngIf="!collapsed"
      class="pi pi-times sharing-progress__close-icon"
      (click)="onClosed()"
    ></i>
    <i
      *ngIf="collapsed"
      class="pi pi-spin pi-spinner sharing-progress__expand-icon"
      (click)="collapsed = false"
    ></i>
    <div *ngIf="!collapsed" class="sharing-progress__title">
      {{
        (progress$ | async)?.length +
          ' sharing ' +
          ('task' | pluralize : (progress$ | async)?.length) +
          ' in progress'
      }}
    </div>
    <div *ngFor="let item of progress$ | async" class="pb-2">
      <app-sharing-progress-item
        [item]="item"
        (remove)="onRemove($event)"
      ></app-sharing-progress-item>
    </div>
  </div>
</div>
