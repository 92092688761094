import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nullable } from '@core/interfaces/nullable';
import { Document } from '@core/models';
import { DEFAULT_DATE_FORMAT } from '@core/constants';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentDetailsComponent {
  @Input() showActions!: boolean;

  @Input() set document(doc: Nullable<Document>) {
    if (doc) {
      this.documentValue = doc;
    }
  }
  get document(): Document {
    return this.documentValue;
  }

  documentValue!: Document;
  dateFormat = DEFAULT_DATE_FORMAT;
}
