import { Directive, TemplateRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[tplName]',
})
export class TemplateNameDirective {
  tplName!: string;
  constructor(public template?: TemplateRef<any>) {}
}
