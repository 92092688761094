import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TableRowData } from '../../../interfaces';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@Component({
  selector: 'app-humanize-status-cell-renderer',
  templateUrl: '../base-cell-renderer.component.html',
  styleUrls: ['./humanize-status-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HumanizeStatusCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<any, R>
  implements OnInit
{
  mapping: { [key: string]: string } | undefined;

  ngOnInit(): void {
    if (this.getOptions()['mapping']) {
      this.mapping = this.getOptions()['mapping'] as { [key: string]: string };
    }
  }

  override render(): string {
    return this.mapping &&
      this.getRawValue() !== undefined &&
      this.mapping[this.getRawValue()] !== undefined
      ? this.mapping[this.getRawValue()]
      : this.getRawValue();
  }
}
