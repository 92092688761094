export class SaveSharingProgressAction {
  static readonly type = '[SHARING PROGRESS] Save progress';

  constructor(public taskId: string, public payload: any) {}
}

export class SaveSharingProgressCompleteAction {
  static readonly type = '[SHARING PROGRESS] Progress Complete';

  constructor(public taskId: string) {}
}

export class CheckSharingProgressAction {
  static readonly type = '[SHARING PROGRESS] Check Progress';

  constructor(public companyId: string, public tasks: string[]) {}
}
