import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { TableRowData } from '../../../interfaces';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@Component({
  selector: 'app-checkbox-cell-renderer',
  templateUrl: './checkbox-cell-renderer.component.html',
  styleUrls: ['./checkbox-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<boolean, R>
  implements OnInit
{
  uid: string | undefined;
  label: string | null = null;

  ngOnInit(): void {
    this.uid = uuid().toString();
    if (this.getOptions()['label']) {
      const label = this.getOptions()['label'] as string;
      this.label = this.interpolate(label);
    }
  }

  override render(): string {
    return '';
  }

  get uniqId(): string {
    return this.uid as string;
  }
}
