import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { NEVER, Observable, withLatestFrom } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CompaniesState } from '@store/companies/companies.state';
import { Company } from '@core/models/company.model';
import { DocumentsState } from '@store/documents/documents.state';
import {
  BulkUploadDocuments,
  ClearDocumentsErrors,
  DeleteDocument,
  GetDocumentsByCategory,
  GetDocumentsByContact,
  GetDocumentsCountersByCategory,
  GetDocumentsStatsByContact,
  GetPlainDocuments,
  SetActiveDocumentCategoryAction,
} from '@store/documents/documents.actions';
import {
  CreateDocument,
  Document,
  PaginatedResponse,
  SharedDocument,
  UploadProgress,
} from '@core/models';
import { Nullable } from '@core/interfaces/nullable';

@Injectable()
export class DocumentsStoreService {
  constructor(private store: Store) {}

  selectDocumentsByContact(
    contactId: string,
    where?: Nullable<Record<string, string>>,
  ): Observable<SharedDocument[]> {
    return this.store
      .select(DocumentsState.getDocumentsByContact(contactId, where))
      .pipe(filter((data) => !!data)) as Observable<SharedDocument[]>;
  }

  selectLoadedPlainDocuments(): Observable<Document[]> {
    return this.store
      .select(DocumentsState.getPlainDocuments())
      .pipe(filter((data) => !!data)) as Observable<Document[]>;
  }

  selectLoadedDocumentsByCategory(
    categoryId: string,
    where?: Record<string, string>,
  ): Observable<Document[]> {
    return this.store
      .select(DocumentsState.getDocumentsByCategory(categoryId, where))
      .pipe(
        filter((data) => !!data),
        map((data: PaginatedResponse<Document>) => data.items),
      ) as Observable<Document[]>;
  }

  selectDocumentsCountByCategory(
    where?: Nullable<Record<string, string>>,
  ): Observable<Record<string, number>> {
    return this.store
      .select(DocumentsState.getCountByCategory(where))
      .pipe(filter((data) => !!data)) as Observable<Record<string, number>>;
  }

  selectDocumentsStatsByContact(): Observable<Record<string, number>> {
    return this.store
      .select(DocumentsState.getStatsByContact)
      .pipe(filter((data) => !!data)) as Observable<Record<string, number>>;
  }

  loadDocumentsByContact(
    contactId: string,
    where?: Nullable<Record<string, string>>,
  ): Observable<SharedDocument[]> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      switchMap((company: Company) =>
        this.store.dispatch(
          new GetDocumentsByContact(company.id, contactId, 1, 1000, where),
        ),
      ),
      switchMap(() => this.selectDocumentsByContact(contactId, where)),
    );
  }

  loadDocumentsStatsByContact(): Observable<Record<string, number>> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      switchMap((company: Company) =>
        this.store.dispatch(new GetDocumentsStatsByContact(company.id)),
      ),
      switchMap(() => this.selectDocumentsStatsByContact()),
    );
  }

  loadPlainDocuments(
    where?: Record<string, string>,
    page = 1,
    pageSize = 1000,
  ): Observable<Document[]> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      switchMap((company: Company) =>
        this.store.dispatch(
          new GetPlainDocuments({
            companyId: company.id,
            page,
            pageSize,
            where,
            order: 'asc',
            sortBy: 'name',
          }),
        ),
      ),
      switchMap(() => this.selectLoadedPlainDocuments()),
    );
  }

  loadDocumentsByCategory(
    categoryId: string,
    page = 1,
    pageSize = 1000,
    where?: Record<string, any>,
  ): Observable<Document[]> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      withLatestFrom(
        this.store.select(DocumentsState.isCategoryLoading(categoryId)),
      ),
      switchMap(([company, isLoading]) => {
        if (!isLoading) {
          return this.store.dispatch(
            new GetDocumentsByCategory({
              companyId: company?.id as string,
              categoryId,
              page,
              pageSize,
              where,
            }),
          );
        } else {
          return NEVER;
        }
      }),
      switchMap(() => this.selectLoadedDocumentsByCategory(categoryId, where)),
    );
  }

  loadDocumentsCountByCategory(
    where?: Record<string, any>,
  ): Observable<Record<string, number>> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      switchMap((company: Company) =>
        this.store.dispatch(
          new GetDocumentsCountersByCategory({
            companyId: company.id,
            where,
          }),
        ),
      ),
      switchMap(() => this.selectDocumentsCountByCategory(where)),
    );
  }

  error(): Observable<any> {
    return this.store.select(DocumentsState.getError).pipe(
      filter((data) => !!data),
      tap(() => {
        this.store.dispatch(new ClearDocumentsErrors());
      }),
    ) as Observable<any>;
  }

  bulkDocumentsAdd(payload: CreateDocument[]): Observable<boolean> {
    this.store
      .select(CompaniesState.getActive)
      .pipe(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filter((d) => !!d),
        switchMap((company: Company) =>
          this.store.dispatch(new BulkUploadDocuments(company.id, payload)),
        ),
        take(1),
      )
      .subscribe();
    return this.store
      .select(DocumentsState.isBulkUploadCompleted)
      .pipe(filter((data) => !!data)) as Observable<boolean>;
  }

  selectBulkUploadProgress(): Observable<Array<UploadProgress>> {
    return this.store
      .select(DocumentsState.getBulkUploadProgress)
      .pipe(filter((data) => !!data)) as Observable<Array<UploadProgress>>;
  }

  selectActiveCategory(): Observable<string> {
    return this.store
      .select(DocumentsState.getActiveCategory)
      .pipe(filter((data) => !!data)) as Observable<string>;
  }

  selectBulkUploadCompleted(): Observable<boolean> {
    return this.store
      .select(DocumentsState.isBulkUploadCompleted)
      .pipe(filter((data) => !!data)) as Observable<boolean>;
  }

  selectBulkUploadCompletedWithErrors(): Observable<boolean> {
    return this.store.select(DocumentsState.isBulkUploadCompleted).pipe(
      withLatestFrom(
        this.store.select(DocumentsState.isBulkUploadCompletedWithErrors),
      ),
      tap(([isCompleted]) => {
        if (isCompleted) {
          this.store.dispatch(new ClearDocumentsErrors());
        }
      }) as any,
      filter(([isCompleted]) => !!isCompleted) as any,
      map(([, withErrors]) => withErrors),
    ) as Observable<boolean>;
  }

  deleteDocument(id: string, categoryId: string): Observable<void> {
    return this.store.select(CompaniesState.getActive).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter((d) => !!d),
      switchMap((company: Company) =>
        this.store.dispatch(new DeleteDocument(id, categoryId, company.id)),
      ),
    );
  }

  setActiveCategory(categoryId: string): Observable<void> {
    return this.store.dispatch(new SetActiveDocumentCategoryAction(categoryId));
  }
}
