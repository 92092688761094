import { Component, OnInit } from '@angular/core';
import { TableRowData } from '../../../interfaces';
import { BaseCellRendererComponent } from '../base-cell-renderer.component';

@Component({
  selector: 'app-router-link-cell-renderer',
  templateUrl: './router-link-cell-renderer.component.html',
  styleUrls: ['./router-link-cell-renderer.component.css'],
})
export class RouterLinkCellRendererComponent<R extends TableRowData>
  extends BaseCellRendererComponent<string, R>
  implements OnInit
{
  link!: string;

  ngOnInit(): void {
    if (this.getOptions()['routerLink']) {
      this.link = this.interpolate(this.getOptions()['routerLink'] as string);
    }
  }

  override render(): string {
    return this.getRawValue() || '';
  }
}
