import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { SharedModule } from 'primeng/api';
import { AuthModule } from '@pages/public/auth/auth.module';
import { AuthenticationService } from '@common/auth/services/authentication.service';
import { SocketsService } from '@common/ws-notifications/sockets.service';
import { switchMap, withLatestFrom } from 'rxjs';

@NgModule({
  declarations: [],
  providers: [SocketsService],
  imports: [CoreModule, SharedModule, AuthModule],
})
export class WsNotificationsModule {
  constructor(
    private authService: AuthenticationService,
    private socketsService: SocketsService,
  ) {
    this.authService
      .getAccessToken()
      .pipe(withLatestFrom(this.authService.getIdentity()))
      .subscribe(([token, user]) => {
        if (token) {
          this.socketsService.connect(token, user?.id as string).subscribe();
        }
      });

    this.authService
      .onLogin()
      .pipe(withLatestFrom(this.authService.getAccessToken()))
      .subscribe(([user, token]) => {
        if (token) {
          this.socketsService.connect(token, user?.id as string).subscribe();
        }
      });

    this.authService.onLogout().subscribe(() => {
      this.socketsService.disconnect();
    });
  }
}
