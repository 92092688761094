import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { NgxsModule } from '@ngxs/store';
import { UserDetailsState } from '@store/admin/states/user-details.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CoreModule,
    NgxsModule.forFeature([UserDetailsState]),
  ],
  providers: [],
})
export class AdminStoreModule {}
