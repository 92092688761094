import { CellInfoProvider } from '../interfaces/cell-info-provider';
import { CellRenderOptions } from '../interfaces';
import * as jq from 'jsonpath';
// eslint-disable-next-line @typescript-eslint/ban-types
export class CellInfoProviderService<V, R extends object>
  implements CellInfoProvider<V, R>
{
  constructor(
    private fieldId: string,
    private row: R,
    private options: CellRenderOptions = {},
  ) {}

  getOptions(): CellRenderOptions {
    return this.options;
  }

  getRawValue(): any | null {
    return this.row && this.fieldId
      ? jq.value(this.row, '$.' + this.fieldId)
      : null;
  }

  getRow(): any | null {
    return this.row;
  }

  getFieldId(): string {
    return this.fieldId;
  }
}
