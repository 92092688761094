import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UsersService } from '@core/services/api';
import { GetUserDetails } from '@store/admin/actions/users.actions';

export interface UserDetailsStateModel {
  details: Map<number, any>;
}

@State<UserDetailsStateModel>({
  name: 'appUsersDetails',
  defaults: {
    details: new Map(),
  },
})
@Injectable()
export class UserDetailsState {
  constructor(private userService: UsersService) {}

  @Selector()
  public static getUserDetails(
    state: UserDetailsStateModel,
  ): (id: number) => any | null {
    return (id: number) => state.details.get(id) || null;
  }

  @Action(GetUserDetails)
  public getUserDetails(
    ctx: StateContext<UserDetailsStateModel>,
    action: GetUserDetails,
  ): void {}
}
